/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';
import { USER_ADD } from '../../../redux/actions/admin';

import {
  ButtonWrapper,
  ModalBody,
  ModalGlobalContainer,
  ModalTitle,
  ModalWrapper
} from '../Modal.style';

import {
  FormContainer,
  SelectWrapperRole,
  SelectWrapper,
  AddPublisherWrapper,
  BtnAddPublisher,
  PublishersList,
  PublisherRow,
  NamePublisher,
  RolePublisher,
  ActionPublisher,
  BtnCancel,
  EditRoleWrapper,
  EditRoleTitle,
  DeletePublisherAction,
  BtnDelete,
  BtnSave,
  DefaultLangWrapper,
  RowRoleLang
} from './style';
import { MODAL_CLOSE, SET_SELECTED_LABELS } from '../../../redux/actions';
import {
  Button, CustomSelect, FormInput, Icon
} from '../../../atoms';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import theme from '../../../ui/theme';
import Roboto from '../../../ui/typography/roboto';
import flagit from '../../../ui/assets/img/flag_italy.svg';
import flagen from '../../../ui/assets/img/flag_uk.svg';
import storage from '../../../utils/storage';

import {
  customStylesSelectPrimary,
  defaultUserRoleSelect,
  defaultUserTypeSelectSuperAdmin,
  defaultUserTypeSelectAdmin,
  getLabelValue,
  customStylesSelectLang,
  customStylesSelectLangModal
} from '../../../utils/common';

const modalId = 'AddUserModal';

const flags = {
  it: { label: 'Italian', image: flagit },
  en: { label: 'English', image: flagen }
};
const selectedLabelStorage = 'selectedLabel';

const renderFlag = (language) => (
  <div style={{ width: '20px', height: '20px' }}>
    <img style={{ width: '100%', height: 'auto' }} src={flags[language].image} alt="" />
  </div>
);

const initialForm = newForm([
  newFormField({ field: 'surname', required: true, type: RegexpType.STRING }),
  newFormField({ field: 'name', required: true, type: RegexpType.STRING }),
  newFormField({ field: 'username', required: false, type: RegexpType.STRING }),
  newFormField({
    field: 'password',
    required: false,
    type: RegexpType.PASSWORD
  }),
  newFormField({
    field: 'repeat_password',
    required: false,
    type: RegexpType.PASSWORD
  }),
  newFormField({
    field: 'email',
    required: false,
    type: RegexpType.EMAIL
  })
]);

const AddUserModal = ({
  user,
  addUser,
  onClose,
  userType,
  availablePublisherByAccountList,
  publishersList,
  labels,
  labelsList
}) => {
  const [newUserForm, setNewUserForm] = useState(initialForm);
  // eslint-disable-next-line no-unused-vars
  const [confirmed, setConfirm] = useState(false);

  const [publisherToAddSelected, setPublisherToAddSelected] = useState([]);
  const [publisherRoleToAddSelected, setPublisherRoleToAddSelected] = useState(
    []
  );

  const [userTypeSelected, setUserTypeSelected] = useState('');

  const [listPublishers, setListPublishers] = useState([]);
  const [allPublishersFiltered, setAllPublishersFiltered] = useState([]);
  const [newListPublishers, setNewListPublishers] = useState([]);

  const [deletePublisherVisible, setDeletePublisherVisible] = useState(false);
  const [newPublisherToDelete, setNewPublisherToDelete] = useState([]);

  const [listLabels, setListLabels] = useState([]);
  const [langSelected, setLangSelected] = useState();

  useEffect(() => {
    if (newListPublishers.length > 0) {
      const addedPublishers = newListPublishers.map(item => item.name);
      const publishersFiltered = (publishersList || [])
        .reduce(
          (acc, pub) => [...acc, { label: pub.name, value: pub.name }],
          []
        )
        .filter(item => !addedPublishers.includes(item.value));
      setAllPublishersFiltered(publishersFiltered);
    } else {
      const publishersFiltered = (publishersList || []).reduce(
        (acc, pub) => [...acc, { label: pub.name, value: pub.name }],
        []
      );
      setAllPublishersFiltered(publishersFiltered);
    }
  }, [newListPublishers, publishersList]);

  useEffect(() => {
    const listLab = Object.keys(labelsList).reduce((acc, item) => [
      ...acc, { label: renderFlag(item), value: item }
    ], []);
    const selectedFlag = storage.read(selectedLabelStorage)?.value || 'en';
    const selected = listLab.find(item => item.value === selectedFlag);
    setLangSelected(selected || '');
    setListLabels(listLab);
  }, [labelsList]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newUserForm[field];

    setNewUserForm({
      ...newUserForm,
      [field]: {
        ...newUserForm[field],
        value: newValue,
        valid:
          validator(type, newValue, required)
          && (field === 'repeat_password'
            ? newUserForm.password.value === newValue
            : true)
      }
    });
  };


  const handleOnchangeLang = (value) => {
    const selectedLang = listLabels.filter(el => el.value === value.value)[0];
    setLangSelected(selectedLang);
  };

  const checkSubmitDisabled = () => {
    let checkValue = false;
    if (!!userTypeSelected) {
      Object.keys(newUserForm).forEach(key => {
        if (key === 'email' || key === 'mobile_number') {
          if (newUserForm[key]?.value) checkValue = true;
          if (checkValue) return checkValue;
        }
      });
    }

    return !(isValidForm(newUserForm) && checkValue);
  };

  const handleOnClose = () => {
    setNewUserForm(initialForm);
    setAllPublishersFiltered([]);
    setPublisherToAddSelected([]);
    setPublisherRoleToAddSelected([]);
    setUserTypeSelected([]);
    setNewListPublishers([]);
    setDeletePublisherVisible(false);
    setNewPublisherToDelete([]);
    onClose();
  };

  const handleaddUser = () => {
    setConfirm(false);
    const body = {
      username: newUserForm.username.value,
      name: newUserForm.name.value,
      surname: newUserForm.surname.value,
      mail: newUserForm.email.value,
      password: newUserForm.password.value,
      user_type: userTypeSelected.value,
      publisher: newListPublishers,
      defaultLanguage: langSelected.value
    };

    addUser(body);
    handleOnClose();
  };

  const handleOnChangeUserType = value => {
    setUserTypeSelected(value);
  };

  const handleOnChangePublisherToAddSelected = value => {
    setPublisherToAddSelected(value);
  };

  const handleOnChangePublisherRoleToAdd = value => {
    setPublisherRoleToAddSelected(value);
  };

  const handleOnAddPublisher = () => {
    if (
      Object.keys(publisherToAddSelected).length > 0
      && Object.keys(publisherRoleToAddSelected).length > 0
    ) {
      setNewListPublishers([
        ...newListPublishers,
        {
          name: publisherToAddSelected.value,
          role: publisherRoleToAddSelected.value
        }
      ]);
      setPublisherToAddSelected([]);
      setPublisherRoleToAddSelected([]);
    }
  };

  const handleOnDeleteNewPublisher = publisher => {
    setDeletePublisherVisible(true);
    setNewPublisherToDelete(publisher);
  };

  const handleOnDelete = () => {
    const newList = newListPublishers.filter(
      el => el.name !== newPublisherToDelete.name
    );
    setNewListPublishers(newList);
    setDeletePublisherVisible(false);
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            height="90%"
          >
            <ModalTitle>
              <Roboto htmlAttribute="span" type="modalTitle">
                {getLabelValue('modal_add_user', labels)}
              </Roboto>
              <Icon
                type="icon-close"
                color={theme.colors.custom.darkText}
                size={20}
                onClick={handleOnClose}
              />
            </ModalTitle>

            <ModalBody>
              <FormContainer>
                <Container>
                  <Row>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_username_label', labels)}
                        onChange={newValue => handleOnChange(newUserForm.username.field, newValue)
                        }
                        value={newUserForm.username.value}
                        valid={newUserForm.username.valid}
                        type="modal-input"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_name_label', labels)}
                        onChange={newValue => handleOnChange(newUserForm.name.field, newValue)
                        }
                        value={newUserForm.name.value}
                        valid={newUserForm.name.valid}
                        type="modal-input"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_surname_label', labels)}
                        onChange={newValue => handleOnChange(newUserForm.surname.field, newValue)
                        }
                        value={newUserForm.surname.value}
                        valid={newUserForm.surname.valid}
                        type="modal-input"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_email_label', labels)}
                        onChange={newValue => handleOnChange(newUserForm.email.field, newValue)
                        }
                        value={newUserForm.email.value}
                        valid={newUserForm.email.valid}
                        type="modal-input"
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_password_label', labels)}
                        onChange={newValue => handleOnChange(newUserForm.password.field, newValue)
                        }
                        value={newUserForm.password.value}
                        valid={newUserForm.password.valid}
                        type="form-password"
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_repeat_password_label', labels)}
                        onChange={newValue => handleOnChange(
                          newUserForm.repeat_password.field,
                          newValue
                        )
                        }
                        value={newUserForm.repeat_password.value}
                        valid={newUserForm.repeat_password.valid}
                        type="form-password"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <RowRoleLang>
                        <SelectWrapperRole>
                          <Roboto>{getLabelValue('select_user_type_label', labels)}</Roboto>
                          <CustomSelect
                            styles={customStylesSelectPrimary}
                            options={
                              userType === 'super_admin'
                                ? defaultUserTypeSelectSuperAdmin
                                : defaultUserTypeSelectAdmin
                            }
                            value={userTypeSelected}
                            onChange={handleOnChangeUserType}
                          />
                        </SelectWrapperRole>
                        <DefaultLangWrapper>
                          <Roboto>{getLabelValue('select_user_language_label', labels)}</Roboto>
                          <CustomSelect
                            styles={customStylesSelectLangModal}
                            options={listLabels}
                            value={langSelected}
                            onChange={handleOnchangeLang}
                          />
                        </DefaultLangWrapper>
                      </RowRoleLang>
                    </Col>
                    {userTypeSelected?.value !== 'admin' && (
                      <Col sm={12} md={12} lg={12} className="pl-0">
                        <Roboto>{getLabelValue('select_add_publisher_label', labels)}</Roboto>
                        <AddPublisherWrapper>
                          <SelectWrapper>
                            <CustomSelect
                              styles={customStylesSelectPrimary}
                              options={allPublishersFiltered}
                              value={publisherToAddSelected}
                              onChange={handleOnChangePublisherToAddSelected}
                            />
                            <CustomSelect
                              styles={customStylesSelectPrimary}
                              options={defaultUserRoleSelect}
                              value={publisherRoleToAddSelected}
                              onChange={handleOnChangePublisherRoleToAdd}
                            />
                          </SelectWrapper>
                          <BtnAddPublisher>
                            <BtnSave
                              disabled={
                                !(
                                  Object.keys(publisherToAddSelected).length > 0
                                )
                                || !(
                                  Object.keys(publisherRoleToAddSelected)
                                    .length > 0
                                )
                              }
                              onClick={() => handleOnAddPublisher()}
                            >
                              {getLabelValue('btn_add', labels)}
                            </BtnSave>
                          </BtnAddPublisher>
                        </AddPublisherWrapper>
                      </Col>
                    )}
                  </Row>
                </Container>
              </FormContainer>
              {newListPublishers?.length > 0 && (
                <>
                  <Roboto type="h2">{getLabelValue('modal_user_publisher_list', labels)}</Roboto>
                  <PublishersList>
                    {newListPublishers?.map(publisher => (
                      <PublisherRow>
                        <NamePublisher>{publisher.name}</NamePublisher>
                        <RolePublisher>{publisher.role}</RolePublisher>
                        {userType === 'super_admin' && (
                          <ActionPublisher>
                            <Icon
                              onClick={() => handleOnDeleteNewPublisher(publisher)
                              }
                              type="icon-delete"
                              color={theme.colors.primary.red}
                              size="20"
                            />
                          </ActionPublisher>
                        )}
                      </PublisherRow>
                    ))}
                  </PublishersList>
                </>
              )}

              {deletePublisherVisible && (
                <EditRoleWrapper>
                  <Roboto type="h2">
                    {getLabelValue('message_delete_user_publisher', labels)}{' '}
                    <EditRoleTitle>{newPublisherToDelete.label}?</EditRoleTitle>{' '}
                  </Roboto>
                  <DeletePublisherAction>
                    <BtnCancel onClick={() => setDeletePublisherVisible(false)}>
                      {getLabelValue('btn_cancel', labels)}
                    </BtnCancel>
                    <BtnDelete onClick={() => handleOnDelete()}>
                      {getLabelValue('btn_confirmation', labels)}
                    </BtnDelete>
                  </DeletePublisherAction>
                </EditRoleWrapper>
              )}
              <ButtonWrapper>
                <Button
                  btnText={getLabelValue('btn_save', labels)}
                  width="30%"
                  btnType={Button.TYPE.PRIMARY}
                  disabled={checkSubmitDisabled()}
                  onClick={handleaddUser}
                />
              </ButtonWrapper>
            </ModalBody>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

AddUserModal.propTypes = {
  // HOC (connect, dispatch)
  addUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

AddUserModal.id = modalId;

export default connect(
  state => ({
    userList: state.admin.userList,
    userType: state.auth.user_data.type,
    publishersList: state.admin.publishersList,
    availablePublisherByAccountList: state.admin.availablePublisherByAccount,
    labels: state.app.selectedLabel,
    labelsList: state.app.labels
  }),
  dispatch => ({
    addUser: body => dispatch({ type: USER_ADD._REQUEST, body }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(AddUserModal);
