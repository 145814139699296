import styled, { css } from 'styled-components';
import theme from '../../ui/theme';

const FormInputContainer = styled.div`
  text-align: left;
  margin-bottom: ${props =>
    props.type === 'modal-input' || props.type === 'form-password'
      ? '32px'
      : '0'};

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 32px;
  }
`;

const Label = styled.div`
  margin-bottom: ${props => (props.type === 'modal-input' ? '10px' : '5px')};

  ${props =>
    props.isRequired &&
    css`
      & div::after {
        content: ' *';
        color: ${theme.colors.primary.red};
        font-weight: 600;
      }
    `} //white-space: nowrap;
`;

const Error = styled.div`
  position: absolute;
  margin-top: 5px;
`;

export { FormInputContainer, Label, Error };
