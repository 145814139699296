import styled from 'styled-components';
import { NavBarHeight } from '../../const';

const HeaderContainer = styled.div`
  height: ${NavBarHeight}px;
  color: ${props => props.theme.colors.primary.white};
  padding: 0 30px;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
`;

const WrapperContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${props => props.theme.colors.primary.base};
  z-index: ${props => props.theme.zIndex.headerFooter};
  top: 0;
  left: 0;
`;

const Logo = styled.span`
  color: ${props => props.theme.colors.primary.white};
  font-size: 28px;
`;

export {
  HeaderContainer, WrapperContainer, Logo
};
