import {
  getDefaultTemplateFeedMock, getHtmlFeedYTMock, getHtmlFeedDMMock
} from './mock/index';
import { apiCall, basePrivate } from './config';
import { parseQueryParams } from '../utils/queryParams';
import { shouldUseMock } from '../utils/common';

export default {
  // USER
  getDefaultTemplateFeed: params => {
    if (shouldUseMock()) {
      return getDefaultTemplateFeedMock();
    }
    return apiCall(`publisher/default/feedtemplate${parseQueryParams(params)}`, 'GET');
  },
  getHtmlFeedYT: (params) => {
    if (shouldUseMock()) {
      return getHtmlFeedYTMock();
    }
    return apiCall(`yt/video/feed/script${parseQueryParams(params)}`, 'GET');
  },
  getHtmlFeedDM: (params) => {
    if (shouldUseMock()) {
      return getHtmlFeedDMMock();
    }
    return apiCall(`dm/video/feed/script${parseQueryParams(params)}`, 'GET');
  }
};
