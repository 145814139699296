import styled from 'styled-components';
import theme from '../../ui/theme';

export const SearchVideoContainer = styled.div`
  height: inherit;
  
  @media ${props => props.theme.device.tablet} {
    height: 100%;
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 16px; */
  margin-bottom: 30px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

export const LeftContainer = styled.div`
  .btn-create-feed {
    margin-top: 10px;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    height: 160px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const FilterContainer = styled.div`
  margin-bottom: 50px;

  .max-results {
    > div {
      > div {
        width: 90px;
      }
    }
  }

  .switch {
    max-width: 50px;
  }

  .buttonSearch {
    @media ${props => props.theme.device.tabletL} {
      max-width: 110px;
    }
  }

  .btn-create-feed {
    padding: 12px 10px;
  }
`;

export const ButtonContainer = styled.div`
  width: 183px;
  position: relative;
  min-height: 70px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.dm {
    > button {
        img {
          width: 79%;
          @media ${props => props.theme.device.tabletL} {
            width: 50%;
          }

          @media ${props => props.theme.device.mobileL} {
            width: 170px;
          }
        }
    }
  }

  &.dm-upload {
    > button {
        img {
          width: 79%;
          @media ${props => props.theme.device.tabletL} {
            width: 50%;
          }

          @media ${props => props.theme.device.mobileL} {
            width: 170px;
          }
        }
    }
  }

  @media ${props => props.theme.device.tablet} {
    width: 50%;
    position: relative;
    min-height: 70px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    width: 80%;
    position: relative;
    min-height: 70px;
    margin-right: 0;
    
    &:not(:last-child) {
      margin-right: 0px;
    }
  }
`;

export const SearchBar = styled.div`
  width: 506px;
  //height: 50px;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
`;
export const ResultsContainer = styled.div`
  min-height: calc(100vh - 70px - 140px);
  border-left: ${props => (props.color === 'youtube'
    ? `3px solid ${props.theme.colors.custom.youtube}`
    : `3px solid ${props.theme.colors.custom.dailymotion}`)};

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: ${props => props.theme.colors.primary.gray};

  @media ${theme.device.mobileL} {
    justify-content: center;
  }
`;

export const NoResultWrapper = styled.div`
  height: 550px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const NoResultMessage = styled.span`
  font-size: 32px;
  color: ${theme.colors.primary.gray};

  @media ${theme.device.mobileL} {
    font-size: 22px;
  } 
`;

export const NoResultImageWrapper = styled.div`
  width: 300px;
  height: auto;
  margin-bottom: 50px;

  @media ${theme.device.mobileL} {
    width: 180px;
  } 
`;

export const NoResultImage = styled.img`
  width: 100%;
  height: auto;
`;


export const CalendarWrapper = styled.div`
  //display: flex;
  span.icon-calendar {
    position: absolute;
    right: 30px;
    z-index: 1;
    top: 29px;

    @media ${theme.device.tablet} {
      top: 26px;
    } 
    @media ${theme.device.mobileL} {
      top: 27px;
    } 
  }

  span.icon-close {
    position: absolute;
    right: 63px;
    z-index: 3;
    top: 34px;

    @media ${theme.device.tablet} {
      top: 31px;
    } 
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${theme.colors.custom.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
