import React, { useState, useEffect } from 'react';

import { Container } from '../../ui/gridSystem';
import {
  FormInput
} from '../../atoms';

import { Julieta, Foirello } from './style';


const Sample = () => {
  const [text, setText] = useState('Ho scritto qualcosa');

  useEffect(() => {
  }, [text]);

  return (
    <Container>
      {text.length > 0 ? <Julieta backgroundColor="blue" /> : <Foirello backgroundColor="red" />}
      <FormInput label="Sample input" placeholder="Inserisci il testo" value={text} onChange={(newValue) => setText(newValue)} />
      <div>this is a sample page</div>
      <div
        style={{
          fontFamily: 'Arial',
          fontWeight: 'normal',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Arial',
          fontWeight: 'normal',
          fontStyle: 'italic',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Arial',
          fontWeight: 'bold',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Arial',
          fontWeight: 'bold',
          fontStyle: 'italic',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Gotham',
          fontWeight: 'normal',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Gotham',
          fontWeight: '300',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Gotham',
          fontWeight: '500',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
      <div
        style={{
          fontFamily: 'Gotham',
          fontWeight: 'bold',
          fontStyle: 'normal',
          marginBottom: '10px'
        }}
      >
        Questa è una frase di prova 123456789
      </div>
    </Container>
  );
};

export default Sample;
