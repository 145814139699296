import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import { AdminCta, AdminUserDetail } from '../../atoms';
import { AdminCardContainer, UserDetail, ButtonsWrapper } from './style';
import { getLabelValue } from '../../utils/common';
import { platforms } from '../../utils/formFieldsEditPublisher';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { SET_DEFAULT_PLATFORM } from '../../redux/actions/admin';

const PlatformIcon = platform => (
  <div
    style={{
      padding: platform === 'YT' ? '6px 0px 6px 8px' : '6px 4px',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px'
    }}
  >
    {platform}
  </div>
);

const AdminCardPublisher = ({
  publisher,
  onClickEditUser,
  onClickDeletePublisher,
  labels,
  userType,
  mediaIsLaptop,
  mediaIsDesktop,
  availablePlatformList,
  setDefaultPlatform,
  publishersList
}) => {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [defaultPlt, setDefaultPlt] = useState('');

  useEffect(() => {
    const platformsPublisher = availablePlatformList?.filter(
      pub => pub.name === publisher.name
    )[0];
    if (platformsPublisher) {
      const avPlt = Object.keys(platformsPublisher).filter(
        k => platformsPublisher[k]?.platform_enabled
      );
      setAvailablePlatforms(avPlt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePlatformList]);

  useEffect(() => {
    const defaultPlatform = publishersList.find(
      pub => pub?.name === publisher.name
    )?.defaultPlatform;
    if (defaultPlatform) setDefaultPlt(defaultPlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishersList]);

  useEffect(() => {
    setVisibleDelete(userType === 'super_admin');
  }, [userType]);

  const toggleDefault = value => {
    const body = {
      name: publisher?.name,
      defaultPlatform: value ? 'youtube' : 'dailymotion'
    };
    setDefaultPlatform(body);
  };

  const getPlatformToShow = () => {
    // if need to show YT return true, if need to show Dm return false
    if (availablePlatforms?.length === 1)
      return availablePlatforms[0] === 'youtube';
    return defaultPlt === 'youtube';
  };

  return (
    <AdminCardContainer>
      <>
        <UserDetail>
          <AdminUserDetail
            label={getLabelValue('info_publisher_name', labels)}
            value={publisher.name}
            isPublisher
          />
          <AdminUserDetail
            label={getLabelValue('info_youtube_id', labels)}
            value={publisher.youtube.channelId}
            isPublisher
            isClickable
            type="youtubeLabel"
            onClick={() => onClickEditUser(platforms.YT)}
          />
          <AdminUserDetail
            label={getLabelValue('info_dailymotion_adv_code', labels)}
            value={publisher.dailymotion.adv_code}
            isPublisher
            isClickable
            type="dailymotionLabel"
            onClick={() => onClickEditUser(platforms.DM)}
          />
        </UserDetail>
        <ButtonsWrapper>
          <div
            style={{
              visibility: availablePlatforms?.length > 0 ? 'visible' : 'hidden',
              display: 'flex',
              gap: '20px',
              alignItems: 'center'
            }}
          >
            {mediaIsDesktop || mediaIsLaptop ? (
              <ReactTooltip id="defaultTip" place="top" effect="solid">
                {getLabelValue('info_default_platform', labels)}
              </ReactTooltip>
            ) : (
              <p>{getLabelValue('info_default_platform', labels)}</p>
            )}
            <div data-tip data-for="defaultTip">
              <Switch
                className="platform-enable--switch"
                onChange={toggleDefault}
                onColor="#f00"
                offColor="#4f9eff"
                checkedIcon={PlatformIcon('YT')}
                uncheckedIcon={PlatformIcon('DM')}
                checked={getPlatformToShow()}
                disabled={availablePlatforms?.length < 2}
              />
            </div>
          </div>
          {/* <AdminCta btnType="edit" width="50px" onClick={onClickEditUser} /> */}
          {visibleDelete && (
            <>
              <AdminCta
                btnType="delete"
                width="50px"
                onClick={onClickDeletePublisher}
              />
            </>
          )}
        </ButtonsWrapper>
      </>
    </AdminCardContainer>
  );
};

AdminCardPublisher.propTypes = {
  mediaIsLaptop: PropTypes.bool,
  mediaIsDesktop: PropTypes.bool,

  publisher: PropTypes.array,
  onClickEditUser: PropTypes.func,
  onClickDeletePublisher: PropTypes.func,
  availablePlatformList: PropTypes.array,
  // HOC (connect, state)
  userType: PropTypes.string,
  publishersList: PropTypes.array,
  // HOC (connect, dispatch)
  setDefaultPlatform: PropTypes.func
};

export default connect(
  state => ({
    userType: state.auth.user_data.type,
    labels: state.app.selectedLabel,
    availablePlatformList: state.app.availablePlatformList,
    publishersList: state.app.publishersList
  }),
  dispatch => ({
    setDefaultPlatform: payload =>
      dispatch({ type: SET_DEFAULT_PLATFORM._REQUEST, payload })
  })
)(withMediaQueries(AdminCardPublisher));
