import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  AdminContainer,
  BtnContainer,
  ButtonWrapper,
  PlatformTabsWrapper
} from './style';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import Tabs from '../../atoms/Tabs';
import { Button } from '../../atoms';
import {
  GET_AVAILABLE_PUBLISHER_BY_ACCOUNT,
  GET_DEFAULT_TEMPLATE_BY_PUBLISHER,
  GET_PUBLISHERS_BY_ACCOUNT,
  GET_PUBLISHERS_LIST,
  GET_SFTP_OPTIONS_BY_PUBLISHER,
  GET_USERS_LIST
} from '../../redux/actions/admin';
import { GET_DEFAULT_TEMPLATE_FEED } from '../../redux/actions/feed';
import {
  AddPublisherModal,
  AdminCardPublisher,
  AdminCardUser
} from '../../components';
import { MODAL_OPEN } from '../../redux/actions';
import PublishersUserModal from '../../components/Modals/PublishersUserModal';
import AddUserModal from '../../components/Modals/AddUserModal';
import DeleteConfirmModal from '../../components/Modals/DeleteConfirmModal';
import EditUserModal from '../../components/Modals/EditUserModal';
import EditPublisherModal from '../../components/Modals/EditPublisherModal';
import SwitchUserTypeModal from '../../components/Modals/SwitchUserTypeModal';
import { getLabelValue } from '../../utils/common';
import { platforms } from '../../utils/formFieldsEditPublisher';

const Admin = ({
  getUserList,
  userList,
  publishersUserModal,
  openAddUserModal,
  editUserModal,
  editPublisherModal,
  openDeleteConfirmModal,
  getPublishersByAccount,
  listPublisherByAccount,
  switchUserTypeModal,
  getPublishersList,
  listPublisher,
  openAddPublisherModal,
  getAvailablePublisherByAccount,
  getSftpOptionsByPublisher,
  getDefaultTemplateByPublisher,
  getDefaultTemplateFeed,
  labels
}) => {
  const tabs = [
    { label: getLabelValue('btn_publishers', labels), value: 'publishers' },
    { label: getLabelValue('btn_users', labels), value: 'users' }
  ];

  const tabsPlatform = [
    { label: 'Youtube', value: 'youtube' },
    { label: 'Dailymotion', value: 'dailymotion' }
  ];

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('activeTab')
      ? {
          label: localStorage.getItem('activeTab'),
          value: localStorage.getItem('activeTab')
        }
      : tabs[0]
  );
  const [activeTabPlatform, setActiveTabPlatform] = useState(
    localStorage.getItem('activeTabPlatform')
      ? {
          label: localStorage.getItem('activeTabPlatform'),
          value: localStorage.getItem('activeTabPlatform')
        }
      : tabsPlatform[0]
  );
  const [usersList, setUsersList] = useState([]);
  const [publishersList, setPublishersList] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [publisherSelected, setPublisherSelected] = useState([]);
  const [publishersByAccount, setPublishersByAccount] = useState([]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.value);
    localStorage.setItem('activeTabPlatform', activeTabPlatform.value);
  }, [activeTab, activeTabPlatform]);

  useEffect(() => {
    if (!Array.isArray(userList)) {
      getUserList();
    }

    setUsersList(Array.isArray(userList) ? userList : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  useEffect(() => {
    if (!Array.isArray(listPublisher)) {
      getPublishersList();
    }
    setPublishersList(Array.isArray(listPublisher) ? listPublisher : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPublisher]);

  useEffect(() => {
    setPublishersByAccount(listPublisherByAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPublisherByAccount]);

  const onChangeTab = tab => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab.value);
  };

  const onChangeTabPlatform = tab => {
    setActiveTabPlatform(tab);
    localStorage.setItem('activeTabPlatform', tab.value);
  };

  const handleOpenPublishersUserModal = user => {
    publishersUserModal(user);
    getAvailablePublisherByAccount(user.username);
    getPublishersByAccount(user.username);
    setUserSelected(user);
  };

  const handleOnClickSwitchUserType = user => {
    switchUserTypeModal();
    setUserSelected(user);
  };

  const handleOnClickDeleteUser = user => {
    openDeleteConfirmModal(user);
  };

  const handleOnClickEditUser = user => {
    editUserModal(user);
    setUserSelected(user);
  };
  const handleOnClickEditPublisher = (publisher, platform) => {
    editPublisherModal({ publisher, selectedPlatform: platform });
    setPublisherSelected(publisher);
    getSftpOptionsByPublisher(publisher.name);
    getDefaultTemplateByPublisher(publisher.name);
    getDefaultTemplateFeed(publisher.name);
  };

  const handleOnClickDeletePublisher = publisher => {
    openDeleteConfirmModal(publisher);
  };

  return (
    <>
      <AdminContainer>
        {/* <Roboto type="medium">Admin</Roboto> */}
        <BtnContainer>
          <Tabs
            tabs={[...tabs]}
            onChange={onChangeTab}
            selected={activeTab}
            tabType="primary"
          />
          <ButtonWrapper>
            {localStorage.getItem('activeTab') === 'publishers' ? (
              <Button
                btnText={`${getLabelValue('btn_add', labels)} ${
                  activeTab.label
                }`}
                width="220px"
                onClick={() => openAddPublisherModal()}
              />
            ) : (
              <Button
                btnText={`${getLabelValue('btn_add', labels)} ${
                  activeTab.label
                }`}
                width="220px"
                onClick={() => openAddUserModal()}
              />
            )}
          </ButtonWrapper>
        </BtnContainer>
        {/* {activeTab.value === 'publishers' && (
          <PlatformTabsWrapper>
            <Tabs
              tabs={[...tabsPlatform]}
              onChange={onChangeTabPlatform}
              selected={activeTabPlatform}
              tabType="secondary"
            />
          </PlatformTabsWrapper>
        )} */}
        {localStorage.getItem('activeTab') === 'publishers' ? (
          <>
            {publishersList.map((publisher, i) => (
              <AdminCardPublisher
                key={`publisher-${i}`}
                publisher={publisher}
                onClickEditPublisherUser={() =>
                  handleOpenPublishersUserModal(publisher)
                }
                onClickDeletePublisher={() =>
                  handleOnClickDeletePublisher(publisher)
                }
                onClickEditUser={platform =>
                  handleOnClickEditPublisher(publisher, platform)
                }
                onClickSwitchUserType={() =>
                  handleOnClickSwitchUserType(publisher)
                }
              />
            ))}
          </>
        ) : (
          <>
            {usersList.map((user, i) => (
              <AdminCardUser
                key={`user-${i}`}
                userList={user}
                onClickEditPublisherUser={() =>
                  handleOpenPublishersUserModal(user)
                }
                onClickDeleteUser={() => handleOnClickDeleteUser(user)}
                onClickEditUser={() => handleOnClickEditUser(user)}
                onClickSwitchUserType={() => handleOnClickSwitchUserType(user)}
              />
            ))}
          </>
        )}

        {/* MODALS */}
      </AdminContainer>
      <AddUserModal />
      <EditUserModal />
      <PublishersUserModal user={userSelected} />
      <SwitchUserTypeModal user={userSelected} />
      <AddPublisherModal />
      <EditPublisherModal />
      <DeleteConfirmModal type={activeTab.label.toLowerCase()} />
    </>
  );
};

Admin.propTypes = {
  getUserList: PropTypes.array,
  userList: PropTypes.array,
  publishersUserModal: PropTypes.func,
  openAddUserModal: PropTypes.func,
  editUserModal: PropTypes.func,
  openDeleteConfirmModal: PropTypes.func,
  getPublishersByAccount: PropTypes.func,
  switchUserTypeModal: PropTypes.func,
  listPublisherByAccount: PropTypes.array
};

export default connect(
  state => ({
    userList: state.admin.userList,
    listPublisher: state.admin.publishersList,
    listPublisherByAccount: state.admin.selectedUserPublisher,
    availablePublisherByAccountList: state.admin.availablePublisherByAccount,
    labels: state.app.selectedLabel
  }),
  dispatch => ({
    getUserList: () => dispatch({ type: GET_USERS_LIST._REQUEST }),
    getPublishersList: () => dispatch({ type: GET_PUBLISHERS_LIST._REQUEST }),
    publishersUserModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'PublishersUserModal' }),
    openAddUserModal: () => dispatch({ type: MODAL_OPEN, id: 'AddUserModal' }),
    openAddPublisherModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'AddPublisherModal' }),
    editUserModal: payload =>
      dispatch({ type: MODAL_OPEN, id: 'EditUserModal', payload }),
    editPublisherModal: payload =>
      dispatch({ type: MODAL_OPEN, id: 'EditPublisherModal', payload }),
    switchUserTypeModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'SwitchUserTypeModal' }),
    openDeleteConfirmModal: payload =>
      dispatch({ type: MODAL_OPEN, id: 'DeleteConfirmModal', payload }),
    getPublishersByAccount: username =>
      dispatch({ type: GET_PUBLISHERS_BY_ACCOUNT._REQUEST, username }),
    getAvailablePublisherByAccount: username =>
      dispatch({ type: GET_AVAILABLE_PUBLISHER_BY_ACCOUNT._REQUEST, username }),
    getSftpOptionsByPublisher: namePublisher =>
      dispatch({ type: GET_SFTP_OPTIONS_BY_PUBLISHER._REQUEST, namePublisher }),
    getDefaultTemplateByPublisher: publisher =>
      dispatch({ type: GET_DEFAULT_TEMPLATE_BY_PUBLISHER._REQUEST, publisher }),
    getDefaultTemplateFeed: publisher =>
      dispatch({ type: GET_DEFAULT_TEMPLATE_FEED._REQUEST, publisher })
  })
)(withMediaQueries(Admin));
