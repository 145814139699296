import styled from 'styled-components';
import theme from '../../ui/theme';

export const UploadVideoContainer = styled.div`
  height: inherit;
  overflow-y: overlay;
  overflow-x: hidden;

  @media ${props => props.theme.device.tablet} {
    height: 100%;
  }
`;

export const FormContainer = styled.div`
  border-left: ${props =>
    props.platform === 'youtube'
      ? `3px solid ${props.theme.colors.custom.youtube}`
      : props.platform === 'dailymotion'
      ? `3px solid ${props.theme.colors.custom.dailymotion}`
      : '3px solid #c3c3c3'};
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${props => props.theme.device.tablet} {
    align-items: flex-start;
  }
`;

export const FormItem = styled.div`
  width: 90%;
  margin-bottom: 25px;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }
`;

export const KeywordsContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
`;

export const KeywordLabel = styled.div`
  background-color: ${theme.colors.custom.blue};
  border-radius: 30px;
  padding: 3px 6px;
  color: #f2f2f2;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 8px;
  }
`;

export const CalendarWrapper = styled.div`
  position: relative;

  > span {
    position: absolute;
    right: 10px;
    //z-index: 1;
    top: 9px;

    @media ${theme.device.tablet} {
      top: 8px;
    }
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
