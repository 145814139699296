import styled, { css } from 'styled-components';
import {
  NavBarHeight,
  NavBarHeightMobile
} from '../../const';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 100vh;
  //height: ${({ vh }) => `calc(${vh} * 100)`};
  overflow-x: hidden;

  @media ${props => props.theme.device.tabletL} {
    margin-left: 0;
    width: 100vw;
  }
`;

export const PageContent = styled.div`
  background-color: ${props => props.theme.colors.primary.white};
  min-height: calc(100vh - ${NavBarHeight}px);
  margin-top: ${NavBarHeight}px;
  padding: 60px 80px;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  width: 100%;

  transition: all 250ms ease-in-out;

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - ${NavBarHeightMobile}px);
    margin-top: ${NavBarHeightMobile}px;
    padding: 30px 20px;
  }
`;
