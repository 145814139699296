import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { SketchPicker } from 'react-color';
import Switch from 'react-switch';
import Roboto from '../../ui/typography/roboto';
import {
  ButtonFinishWrapper,
  ButtonPreviewWrapper,
  ButtonsWrapper,
  ChosenColor,
  ColorOption,
  FormContainer,
  RowSwitchOption,
  SingleSwitchWrapper,
  SwitchOptionsWrapper,
  VideoContainer,
  CreateFeedContainer,
  MaskPreview,
  CloseColorPicker,
  ColorTitle,
  HeightWidthContainer,
  InputsContainer,
  FeedHeightWidthInputsContainer,
  PreviewWrapper
} from './style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import Button from '../../atoms/Button';
import { FormInput, GoBack, Icon } from '../../atoms';
import {
  GET_HTML_BUILD_DM,
  GET_HTML_BUILD_YT,
  GET_YOUTUBE_SEARCH_RESULTS
} from '../../redux/actions/search';
import { GET_DEFAULT_TEMPLATE_BY_PUBLISHER } from '../../redux/actions/admin';
import { Col, Container, Row } from '../../ui/gridSystem';
import { isValidForm, newForm, newFormField } from '../../utils/form';
import { RegexpType, validator } from '../../utils/validator';
import { Label } from '../../atoms/FormInput/style';
import { MODAL_OPEN } from '../../redux/actions';
import HtmlBuildModal from '../../components/Modals/HtmlBuildModal';
import {
  getLabelValue,
  pipCustLabelSwitch,
  pipCustomizationDesktopOptions,
  pipCustomizationMobileOptions
} from '../../utils/common';
import theme from '../../ui/theme';
import { PipCustContainer } from '../../components/Modals/EditPublisherModal/style';
import { NoResultMessage, NoResultWrapper } from '../SearchVideo/style';
import { CLEAR_HTML_FEEDS, GET_DEFAULT_TEMPLATE_FEED, GET_HTML_FEED_DM, GET_HTML_FEED_YT } from '../../redux/actions/feed';
import HtmlFeedModal from '../../components/Modals/HtmlFeedModal';

const initialForm = newForm([
  newFormField({
    field: 'feed_width',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_height',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_total_video',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_background_color',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_font',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_font_color',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_width_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_height_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_total_video_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_background_color_DM',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_font_DM',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_font_color_DM',
    required: false,
    type: RegexpType.STRING
  }),
]);

const error = message => (
  <span style={{ background: 'red', color: 'white', padding: '5px 10px' }}>
    {message}
  </span>
);

const CreateFeed = ({
  videoId,
  ybListResults,
  getDefaultTemplateByPublisher,
  getDefaultTemplateFeed,
  selectedPublisher,
  defaultTemplateByPublisher,
  defaultTemplateFeed,
  dmListResults,
  platform,
  getHtmlBuildDM,
  openHtmlFeedModal,
  getHtmlBuildYT,
  htmlBuildYT,
  htmlBuildDM,
  labels,
  getHtmlFeedYT,
  getHtmlFeedDM,
  htmlFeedYT,
  htmlFeedDM,
  clearHtmlFeeds,
  showPreview
}) => {
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [newSettingsForm, setNewSettingsForm] = useState(initialForm);
  // eslint-disable-next-line no-undef
  const [themeColor, setThemeColor] = useState(
    platform === 'yt'
      ? theme.colors.custom.youtube
      : theme.colors.custom.dailymotion
  );
  const [displayThemeColorPicker, setDisplayThemeColorPicker] = useState(false);
  const [videoSelected, setVideoSelected] = useState({});

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  // Color pickers
  const [displayBGColorPickerYT, setDisplayBGColorPickerYT] = useState(false);
  const [displayBGColorPickerDM, setDisplayBGColorPickerDM] = useState(false);
  const [displayFontColorPickerYT, setDisplayFontColorPickerYT] = useState(false);
  const [displayFontColorPickerDM, setDisplayFontColorPickerDM] = useState(false);

  // Colors
  const [backgroundColor, setBackgroundColor] = useState();
  const [fontColor, setFontColor] = useState();

  // keyword
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    return () => clearHtmlFeeds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    const list = platform === 'yt' ? ybListResults : dmListResults;
    const video = list.filter(vid => vid.videoId === videoId)[0];
    if (video) {
      localStorage.setItem('videoSelected', JSON.stringify(video));
      setVideoSelected(JSON.parse(localStorage.getItem('videoSelected')));
    } else {
      setVideoSelected(JSON.parse(localStorage.getItem('videoSelected')));
    }
  }, [videoId, ybListResults, dmListResults]);

  useEffect(() => {
    setDefaultTemplate(defaultTemplateFeed);
  }, [defaultTemplateFeed, selectedPublisher]);

  useEffect(() => {
    if (selectedPublisher.name)
      getDefaultTemplateByPublisher(selectedPublisher.name);

  }, [getDefaultTemplateByPublisher, selectedPublisher.name]);

  useEffect(() => {
    if (selectedPublisher.name)
      getDefaultTemplateFeed(selectedPublisher.name);
  }, [getDefaultTemplateFeed, selectedPublisher.name]);

  useEffect(() => {
    setNewSettingsForm(
      newForm([
        newFormField({
          field: 'feed_width',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.youtube?.width,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.width,
            true)
        }),
        newFormField({
          field: 'feed_height',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.youtube?.height,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.height,
            true)
        }),
        newFormField({
          field: 'feed_total_video',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.youtube?.maxVideos,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.maxVideos,
            true)
        }),
        newFormField({
          field: 'feed_background_color',
          required: false,
          type: RegexpType.STRING,
          value: defaultTemplate?.youtube?.backgroundColor,
          valid: validator(
            RegexpType.STRING,
            defaultTemplate?.youtube?.backgroundColor,
            true)
        }),
        newFormField({
          field: 'feed_font_color',
          required: false,
          type: RegexpType.STRING,
          value: defaultTemplate?.youtube?.fontColor,
          valid: validator(
            RegexpType.STRING,
            defaultTemplate?.youtube?.fontColor,
            true)
        }),
        newFormField({
          field: 'feed_width_DM',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.dailymotion?.width,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.dailymotion?.width,
            true)
        }),
        newFormField({
          field: 'feed_height_DM',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.dailymotion?.height,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.dailymotion?.height,
            true)
        }),
        newFormField({
          field: 'feed_total_video_DM',
          required: false,
          type: RegexpType.NUMBER,
          value: defaultTemplate?.dailymotion?.maxVideos,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.dailymotion?.maxVideos,
            true)
        }),
        newFormField({
          field: 'feed_background_color_DM',
          required: false,
          type: RegexpType.STRING,
          value: defaultTemplate?.dailymotion?.backgroundColor,
          valid: validator(
            RegexpType.STRING,
            defaultTemplate?.dailymotion?.backgroundColor,
            true)
        }),
        newFormField({
          field: 'feed_font_color_DM',
          required: false,
          type: RegexpType.STRING,
          value: defaultTemplate?.dailymotion?.fontColor,
          valid: validator(
            RegexpType.STRING,
            defaultTemplate?.dailymotion?.fontColor,
            true)
        })
      ])
    );

    if (platform === 'youtube') {
      setBackgroundColor(defaultTemplate?.youtube?.backgroundColor);
      setFontColor(defaultTemplate?.youtube?.fontColor);      
    } else {
      setBackgroundColor(defaultTemplate?.dailymotion?.backgroundColor);
      setFontColor(defaultTemplate?.dailymotion?.fontColor);    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTemplate]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newSettingsForm[field];

    setNewSettingsForm({
      ...newSettingsForm,
      [field]: {
        ...newSettingsForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  const handleClickFinish = () => {
    const publisher = selectedPublisher.name
    const maxVideos = platform === 'youtube' ? newSettingsForm.feed_total_video.value : newSettingsForm.feed_total_video_DM.value
    const height = platform === 'youtube' ? newSettingsForm.feed_height.value : newSettingsForm.feed_height_DM.value;
    const width = platform === 'youtube' ? newSettingsForm.feed_width.value : newSettingsForm.feed_width_DM.value;

    if (platform === 'youtube') {
      getHtmlFeedYT({ publisher, nVideo: maxVideos, height, width, backgroundColor, fontColor, search: keyword });
    } else {
      getHtmlFeedDM({ publisher, nVideo: maxVideos, height, width, backgroundColor, fontColor, search: keyword });
    }
    openHtmlFeedModal();
  };

  const handleOpenPreview = () => {
    const publisher = selectedPublisher.name
    const maxVideos = platform === 'youtube' ? newSettingsForm.feed_total_video.value : newSettingsForm.feed_total_video_DM.value
    const height = platform === 'youtube' ? newSettingsForm.feed_height.value : newSettingsForm.feed_height_DM.value;
    const width = platform === 'youtube' ? newSettingsForm.feed_width.value : newSettingsForm.feed_width_DM.value;


    if (platform === 'youtube') {
      getHtmlFeedYT({ publisher, nVideo: maxVideos, height, width, backgroundColor, fontColor, search: keyword }, true);
    } else {
      getHtmlFeedDM({ publisher, nVideo: maxVideos, height, width, backgroundColor, fontColor, search: keyword }, true);
    }
  };

  const setInnerHTML = (ele, html) => {
    const frag = document.createRange().createContextualFragment(html);
    ele.innerHTML = '';
    ele.appendChild(frag);
  };

  useEffect(() => {
    const wrapper = document.querySelector('.feed-wrapper');

    if (wrapper) {
      const htmlBuild = platform === 'youtube' ? htmlFeedYT : htmlFeedDM;
  
      if (showPreview && Boolean(htmlBuild)) {
        setInnerHTML(wrapper, htmlBuild)
      } else if (showPreview) {
        wrapper.innerHTML = 'Anteprima non disponibile';
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreview]);

  useEffect(() => {
    let isDisabled = false;
    if (platform === 'youtube') {
        if (!Boolean(newSettingsForm.feed_height.value)) isDisabled = true;
        if (!Boolean(newSettingsForm.feed_width.value)) isDisabled = true;
        if (!Boolean(newSettingsForm.feed_total_video.value)) isDisabled = true;
      } else {
        if (!Boolean(newSettingsForm.feed_height_DM.value)) isDisabled = true;
        if (!Boolean(newSettingsForm.feed_width_DM.value)) isDisabled = true;
        if (!Boolean(newSettingsForm.feed_total_video_DM.value)) isDisabled = true;
      }

      setButtonsDisabled(isDisabled);
  }, [newSettingsForm]);

  const handleOnCloseColorPicker = (e) => {
    e.preventDefault();
    if (platform === 'youtube') {
      setDisplayBGColorPickerYT(false);
      setDisplayFontColorPickerYT(false);
    } else {
      setDisplayBGColorPickerDM(false);
      setDisplayFontColorPickerDM(false);
    }
  };

  return (
    <>
      {selectedPublisher 
      && selectedPublisher[platform]?.platform_enabled 
      && selectedPublisher[platform]?.feed_enabled 
      ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <CreateFeedContainer>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={12} className="pl-0">
                  <GoBack text={labels.feed_modal_title_section} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col sm={12} md={12} lg={7} className="pl-0 pr-0">
                  <FormContainer>
                    <>
                      {platform === 'dailymotion' && (
                        <HeightWidthContainer>
                          <FeedHeightWidthInputsContainer>
                            <FormInput
                              label={getLabelValue(
                                'feed_modal_input_height',
                                labels
                              )}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_height_DM.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_height_DM.value}
                              valid={newSettingsForm.feed_height_DM.valid}
                              required={newSettingsForm.feed_height_DM.required}
                              type="modal-input"
                            />
                            <FormInput
                              label={getLabelValue('feed_modal_input_width', labels)}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_width_DM.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_width_DM.value}
                              valid={newSettingsForm.feed_width_DM.valid}
                              required={newSettingsForm.feed_width_DM.required}
                              type="modal-input"
                            />
                            <FormInput
                              label={getLabelValue('feed_modal_input_total_video', labels)}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_total_video_DM.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_total_video_DM.value}
                              valid={newSettingsForm.feed_total_video_DM.valid}
                              required={newSettingsForm.feed_total_video_DM.required}
                              type="modal-input"
                            />
                          </FeedHeightWidthInputsContainer>
                        </HeightWidthContainer>
                      )}
                      {platform === 'youtube' && (
                        <HeightWidthContainer>
                          <FeedHeightWidthInputsContainer>
                            <FormInput
                              label={getLabelValue(
                                'feed_modal_input_height',
                                labels
                              )}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_height.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_height.value}
                              valid={newSettingsForm.feed_height.valid}
                              required={newSettingsForm.feed_height_required}
                              type="modal-input"
                            />
                            <FormInput
                              label={getLabelValue('feed_modal_input_width', labels)}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_width.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_width.value}
                              valid={newSettingsForm.feed_width.valid}
                              required={newSettingsForm.feed_width.required}
                              type="modal-input"
                            />
                            <FormInput
                              label={getLabelValue('feed_modal_input_total_video', labels)}
                              onChange={newValue =>
                                handleOnChange(
                                  newSettingsForm.feed_total_video.field,
                                  newValue
                                )
                              }
                              value={newSettingsForm.feed_total_video.value}
                              valid={newSettingsForm.feed_total_video_valid}
                              required={newSettingsForm.feed_total_video.required}
                              type="modal-input"
                            />
                          </FeedHeightWidthInputsContainer>
                        </HeightWidthContainer>
                      )}
                    </>
                  </FormContainer>
                </Col>
                <Col sm={12} md={12} lg={7} className="pl-0 pr-0">
                  <FormContainer>
                    <>
                      {platform === 'dailymotion' && (
                        <HeightWidthContainer>
                          <FeedHeightWidthInputsContainer>
                            <FormInput
                              label={getLabelValue('create_feed_keyword', labels)}
                              onChange={newValue =>
                                setKeyword(newValue)
                              }
                              value={keyword}
                              type="modal-input"
                            />
                            <ColorOption>
                              <ColorTitle>
                                <ChosenColor
                                  onClick={() => {
                                    setDisplayBGColorPickerDM(!displayBGColorPickerDM);
                                    setDisplayFontColorPickerDM(false);
                                  }}
                                  playerColor={backgroundColor}
                                >
                                  <Roboto>
                                    {getLabelValue('feed_modal_background_color', labels)}
                                  </Roboto>
                                </ChosenColor>
                                {displayBGColorPickerDM && (
                                  <CloseColorPicker>
                                    <Icon
                                      type="icon-close"
                                      size={13}
                                      onClick={e =>
                                        handleOnCloseColorPicker(e)
                                      }
                                      color="#4f9eff"
                                    />
                                  </CloseColorPicker>
                                )}
                              </ColorTitle>
                              {displayBGColorPickerDM && (
                                <div style={{position:'absolute', top: '30px'}}>
                                  <SketchPicker
                                    color={backgroundColor}
                                    onChangeComplete={color =>
                                      setBackgroundColor(color.hex)
                                    }
                                    presetColors={[]}
                                    disableAlpha
                                  />
                                </div>
                              )}
                            </ColorOption>
                            <ColorOption>
                              <ColorTitle>
                                <ChosenColor
                                  onClick={() => {
                                    setDisplayFontColorPickerDM(!displayFontColorPickerDM);
                                    setDisplayBGColorPickerDM(false);
                                  }}
                                  playerColor={fontColor}
                                >
                                  <Roboto>
                                    {getLabelValue('feed_modal_font_color', labels)}
                                  </Roboto>
                                </ChosenColor>
                                {displayFontColorPickerDM && (
                                  <CloseColorPicker>
                                    <Icon
                                      type="icon-close"
                                      size={13}
                                      onClick={e =>
                                        handleOnCloseColorPicker(e)
                                      }
                                      color="#4f9eff"
                                    />
                                  </CloseColorPicker>
                                )}
                              </ColorTitle>
                              {displayFontColorPickerDM && (
                                <div style={{position:'absolute', top: '30px'}}>
                                  <SketchPicker
                                    color={fontColor}
                                    onChangeComplete={color =>
                                      setFontColor(color.hex)
                                    }
                                    presetColors={[]}
                                    disableAlpha
                                  />
                                </div>
                              )}
                            </ColorOption>
                          </FeedHeightWidthInputsContainer>
                        </HeightWidthContainer>
                      )}
                      {platform === 'youtube' && (
                        <HeightWidthContainer>
                          <FeedHeightWidthInputsContainer>
                            <FormInput
                              label={getLabelValue('create_feed_keyword', labels)}
                              onChange={newValue =>
                                setKeyword(newValue)
                              }
                              value={keyword}
                              type="modal-input"
                            />
                            <ColorOption>
                              <ColorTitle>
                                <ChosenColor
                                  onClick={() => {
                                    setDisplayBGColorPickerYT(!displayBGColorPickerYT);
                                    setDisplayFontColorPickerYT(false);
                                  }}
                                  playerColor={backgroundColor}
                                >
                                  <Roboto>
                                    {getLabelValue('feed_modal_background_color', labels)}
                                  </Roboto>
                                </ChosenColor>
                                {displayBGColorPickerYT && (
                                  <CloseColorPicker>
                                    <Icon
                                      type="icon-close"
                                      size={13}
                                      onClick={e =>
                                        handleOnCloseColorPicker(e)
                                      }
                                      color="#4f9eff"
                                    />
                                  </CloseColorPicker>
                                )}
                              </ColorTitle>
                              {displayBGColorPickerYT && (
                                <div style={{position:'absolute', top: '30px'}}>
                                  <SketchPicker
                                    color={backgroundColor}
                                    onChangeComplete={color =>
                                      setBackgroundColor(color.hex)
                                    }
                                    presetColors={[]}
                                    disableAlpha
                                  />
                                </div>
                              )}
                            </ColorOption>
                            <ColorOption>
                              <ColorTitle>
                                <ChosenColor
                                  onClick={() => {
                                    setDisplayFontColorPickerYT(!displayFontColorPickerYT);
                                    setDisplayBGColorPickerYT(false);
                                  }}
                                  playerColor={fontColor}
                                >
                                  <Roboto>
                                    {getLabelValue('feed_modal_font_color', labels)}
                                  </Roboto>
                                </ChosenColor>
                                {displayFontColorPickerYT && (
                                  <CloseColorPicker>
                                    <Icon
                                      type="icon-close"
                                      size={13}
                                      onClick={e =>
                                        handleOnCloseColorPicker(e)
                                      }
                                      color="#4f9eff"
                                    />
                                  </CloseColorPicker>
                                )}
                              </ColorTitle>
                              {displayFontColorPickerYT && (
                                <div style={{position:'absolute', top: '30px'}}>
                                  <SketchPicker
                                    color={fontColor}
                                    onChangeComplete={color =>
                                      setFontColor(color.hex)
                                    }
                                    presetColors={[]}
                                    disableAlpha
                                  />
                                </div>
                              )}
                            </ColorOption>
                          </FeedHeightWidthInputsContainer>
                        </HeightWidthContainer>
                      )}
                    </>
                  </FormContainer>
                </Col>
                <Col sm={12} md={4} lg={4} className="pl-0 ml-auto pr-0">
                  <ButtonsWrapper>
                    <ButtonPreviewWrapper>
                      <Button
                        btnText={getLabelValue('btn_preview', labels)}
                        width="100%"
                        btnType={Button.TYPE.SECONDARY}
                        disabled={buttonsDisabled}
                        onClick={handleOpenPreview}
                      />
                    </ButtonPreviewWrapper>
                    <ButtonFinishWrapper>
                      <Button
                        btnText={getLabelValue('btn_create', labels)}
                        width="100%"
                        btnType={Button.TYPE.PRIMARY}
                        disabled={buttonsDisabled}
                        onClick={handleClickFinish}
                      />
                    </ButtonFinishWrapper>
                  </ButtonsWrapper>
                </Col>
              </Row>
              <Row className="mt-4">
                <PreviewWrapper>
                  {showPreview ? (
                    <div className='feed-wrapper'></div>
                  ) : ( 
                    <Roboto
                      className="no-preview-message"
                      configuration={{
                        fontWeight: 500,
                        fontSize: 32,
                        lineHeight: 1.2
                      }}
                    >
                      {getLabelValue(
                        'create_feed_message_click_preview',
                        labels
                        )}
                    </Roboto>
                  )}
                </PreviewWrapper>
              </Row>
            </Container>
          </CreateFeedContainer>
          <HtmlFeedModal platform={platform} />
        </>
      ) : (
        <NoResultWrapper>
          <NoResultMessage>
            {getLabelValue('message_platform_disabled', labels)}
          </NoResultMessage>
        </NoResultWrapper>
      )}
    </>
  );
};

CreateFeed.propTypes = {
  language: PropTypes.string,
  clearHtmlFeeds: PropTypes.func,
  showPreview: PropTypes.number
};

export default connect(
  state => ({
    ybListResults: state.search.youtubeSearchResults,
    dmListResults: state.search.dailymotionSearchResults,
    selectedPublisher: state.app.selectedPublisher,
    htmlBuildYT: state.search.htmlBuildYT,
    htmlBuildDM: state.search.htmlBuildDM,
    htmlFeedYT: state.feed.htmlFeedYT,
    htmlFeedDM: state.feed.htmlFeedDM,
    defaultTemplateByPublisher: state.admin.defaultTemplateByPublisher,
    defaultTemplateFeed: state.feed.defaultTemplateFeed,
    labels: state.app.selectedLabel,
    language: state.app.selectedLanguage,
    showPreview: state.feed.showPreview
  }),
  dispatch => ({
    getDefaultTemplateByPublisher: publisher =>
      dispatch({ type: GET_DEFAULT_TEMPLATE_BY_PUBLISHER._REQUEST, publisher }),
    getDefaultTemplateFeed: publisher =>
      dispatch({ type: GET_DEFAULT_TEMPLATE_FEED._REQUEST, publisher }),
    openHtmlFeedModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'HtmlFeedModal' }),
    getHtmlBuildYT: payload =>
      dispatch({ type: GET_HTML_BUILD_YT._REQUEST, payload }),
    getHtmlBuildDM: payload =>
      dispatch({ type: GET_HTML_BUILD_DM._REQUEST, payload }),
    getHtmlFeedYT: (payload, updatePreview) =>
      dispatch({ type: GET_HTML_FEED_YT._REQUEST, payload, updatePreview }),
    getHtmlFeedDM: (payload, updatePreview) =>
      dispatch({ type: GET_HTML_FEED_DM._REQUEST, payload, updatePreview }),
    clearHtmlFeeds: () => dispatch({type: CLEAR_HTML_FEEDS})
  })
)(withMediaQueries(CreateFeed));
