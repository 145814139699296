import styled from 'styled-components';
import theme from '../../../ui/theme';

const FormContainer = styled.div`
  .react-switch-bg {
    margin-bottom: 20px !important;
  }
`;

const InformationContainer = styled.div`
  margin-bottom: 20px;
`;

const Data = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.span`
  margin-right: 3px;
`;

const Value = styled.span``;
const Note = styled.div`
  margin-bottom: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 13px;
  }
`;

const AddPublisherWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    flex-direction: column;
  }
`;

const TitleSection = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const BtnSave = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: #f2f2f2;
  background-color: ${theme.colors.custom.blue};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

const BtnAddPublisher = styled.div`
  width: 101px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const PublishersList = styled.div``;

export const PublisherRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  @media ${theme.device.mobileL} {
    margin-top: 16px;
  }
`;

export const NamePublisher = styled.div`
  width: 34%;
`;
export const RolePublisher = styled.div`
  width: 33%;
  color: ${theme.colors.custom.blue};
  display: flex;
  justify-content: center;
`;

export const EditRoleWrapper = styled.div`
  margin-top: 32px;
`;

export const EditRoleTitle = styled.span`
  font-style: italic;
  color: ${theme.colors.custom.blue};
`;

export const ActionPublisher = styled.div`
  width: 33%;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    justify-content: flex-end;
  }
`;

export const BtnCancel = styled.div`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.custom.darkGray};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const BtnDelete = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.primary.red};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const DeletePublisherAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const TitleColumn = styled.div`
  height: 57px;
  font-size: 18px;
  font-weight: 500;
`;

export const LogoWrapper = styled.div`
  @media ${theme.device.tabletL} {
    margin-right: 10px;
  }

  &.yt {
    width: 30%;
  }

  &.dm {
    width: 40%;
  }
`;

export const PipCustContainer = styled.div`
  margin-bottom: 25px;
`;

export const FeedContainer = styled.div``;

export const EnableFeedWrapper = styled.div`
  position: relative;

  .platform-enable {
    &--label {
      margin-bottom: 0;
      margin-right: 10px;
      color: ${props =>
          props.checked
            ? `${theme.colors.custom.blue}!important`
            : 'rgb(136, 136, 136)!important'
      };
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    &--switch {
      height: 28px;

      > .react-switch-bg {
        background: ${props =>
          props.checked
            ? `${theme.colors.custom.blue}!important`
            : 'rgb(136, 136, 136)!important'
        };
      }
    }
  }
`;

export const EnableFeedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const EnableFeedSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FeedHeightWidthInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: calc(33.33% - 10px);
    margin-bottom: 0px;
  }
`;

export const OriginsContainer = styled.div``;

export const OriginsListContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
`;

export const OriginLabel = styled.div`
  background-color: ${theme.colors.custom.blue};
  border-radius: 30px;
  padding: 3px 6px;
  color: #f2f2f2;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 8px;
  }
`;

export {
  FormContainer,
  InformationContainer,
  Data,
  Label,
  Value,
  Note,
  SelectWrapper,
  AddPublisherWrapper,
  BtnSave,
  BtnAddPublisher,
  TitleSection
};
