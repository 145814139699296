/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';
import { PUBLISHER_ADD } from '../../../redux/actions/admin';

import {
  ButtonWrapper,
  ModalBody,
  ModalGlobalContainer,
  ModalTitle,
  ModalWrapper
} from '../Modal.style';

import { FormContainer } from './style';
import { MODAL_CLOSE } from '../../../redux/actions';
import { Button, FormInput, Icon } from '../../../atoms';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import theme from '../../../ui/theme';
import Roboto from '../../../ui/typography/roboto';
import { getLabelValue } from '../../../utils/common';

const modalId = 'AddPublisherModal';

const initialForm = newForm([
  newFormField({ field: 'name', required: true, type: RegexpType.STRING })
]);

const AddPublisherModal = ({ addPublisher, onClose, labels }) => {
  const [newUserForm, setNewUserForm] = useState(initialForm);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newUserForm[field];

    setNewUserForm({
      ...newUserForm,
      [field]: {
        ...newUserForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  const checkSubmitDisabled = () => {
    let checkValue = false;
    Object.keys(newUserForm).forEach(key => {
      if (newUserForm[key]?.value) {
        checkValue = true;
      }
    });
    return !(isValidForm(newUserForm) && checkValue);
  };

  const handleOnClose = () => {
    setNewUserForm(initialForm);
    onClose();
  };

  const handleaddPublisher = () => {
    const body = {
      name: newUserForm.name.value
    };
    addPublisher(body);
    handleOnClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            width="60%"
          >
            <ModalTitle>
              <Roboto htmlAttribute="span" type="modalTitle">
                {getLabelValue('modal_add_publisher', labels)}
              </Roboto>
              <Icon
                type="icon-close"
                color={theme.colors.custom.darkText}
                size={20}
                onClick={handleOnClose}
              />
            </ModalTitle>

            <ModalBody>
              <FormContainer>
                <Container>
                  <Row>
                    {/* NAME PUBLISHER */}
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue(
                          'input_publisher_name_label',
                          labels
                        )}
                        onChange={newValue =>
                          handleOnChange(newUserForm.name.field, newValue)
                        }
                        value={newUserForm.name.value}
                        valid={newUserForm.name.valid}
                        type="modal-input"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0 mt-2">
                      <ButtonWrapper>
                        <Button
                          btnText={getLabelValue('btn_add', labels)}
                          width="50%"
                          btnType={Button.TYPE.PRIMARY}
                          disabled={checkSubmitDisabled()}
                          onClick={handleaddPublisher}
                        />
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Container>
              </FormContainer>
            </ModalBody>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

AddPublisherModal.propTypes = {
  // HOC (connect, dispatch)
  addPublisher: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  labels: PropTypes.object
};

AddPublisherModal.id = modalId;

export default connect(
  state => ({
    userType: state.auth.user_data.type,
    selectedPublisher: state.app.selectedPublisher.name,
    labels: state.app.selectedLabel
  }),
  dispatch => ({
    addPublisher: body => dispatch({ type: PUBLISHER_ADD._REQUEST, body }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(AddPublisherModal);
