const ENV_BASE_PATH = {
  DEV: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};
const APP_DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV
  ? process.env.REACT_APP_DEPLOY_ENV.trim()
  : process.env.REACT_APP_DEPLOY_ENV;
const basePath = ENV_BASE_PATH[APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const baseRoutes = {
  auth: {
    path: `${basePath}auth`,
    title: 'auth'
  },
  sample: {
    path: `${basePath}sample`,
    title: 'Sample'
  },
  searchVideo: {
    path: `${basePath}search`,
    title: 'Cerca un Video'
  },
  uploadVideo: {
    path: `${basePath}upload`,
    title: 'Carica un Video'
  },
  admin: {
    path: `${basePath}admin`,
    title: 'Admin'
  },
  videoSettings: {
    path: `${basePath}video-settings`,
    title: 'Video Settings'
  },
  createFeed: {
    path: `${basePath}create-feed`,
    title: `Create Feed`
  },
  noPublishersFound: {
    path: `${basePath}no-publishers-found`,
    title: 'Nessun publishers found'
  }
};

const routes = {
  ...baseRoutes
};

export default routes;
