import styled from 'styled-components';

export const Julieta = styled.div`
   width: 20px;
   height: 20px;
   background-color: ${props => props.backgroundColor};
`;

export const Foirello = styled.div`
   width: 20px;
   height: 20px;
   background-color: ${props => props.backgroundColor};
`;
