import produce from 'immer';
import { CLEAR_HTML_FEEDS, GET_DEFAULT_TEMPLATE_FEED, GET_HTML_FEED_DM, GET_HTML_FEED_YT, UPDATE_PREVIEW } from '../actions/feed';

export const ClientsFiltersStorage = 'ClientsFiltersStorage';
export const ClientsFiltersStorageType = 'ClientsFiltersStorageType';

const initialState = {
  defaultTemplateFeed: undefined,
  htmlFeedYT: '',
  htmlFeedDM: '',
  showPreview: 0
};

const feedReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  
    case GET_DEFAULT_TEMPLATE_FEED._SUCCESS: {
      const list = action.result;
      draft.defaultTemplateFeed = list;
      break;
    }

    case GET_HTML_FEED_YT._SUCCESS: {
      const html = action.data;
      draft.htmlFeedYT = html;
      break;
    }

    case GET_HTML_FEED_DM._SUCCESS: {
      const html = action.data;
      draft.htmlFeedDM = html;
      break;
    }

    case CLEAR_HTML_FEEDS: {
      draft.htmlFeedYT = '';
      draft.htmlFeedDM = '';
      draft.showPreview = 0;
      break;
    }

    case UPDATE_PREVIEW: {
      draft.showPreview += 1;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default feedReducer;
