import styled from 'styled-components';
import theme from '../../ui/theme';

export const Container = styled.div`
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;
  }
  
  > div:first-child {
    margin-bottom: 5px;
  }
`;

export const Name = styled.span`
  color: ${theme.colors.custom.blue};
`;
