import { newFormField } from './form';
import { RegexpType } from './validator';

export const platforms = {
  YT: 'youtube',
  DM: 'dailymotion'
};

const YTForm = [
  newFormField({
    field: 'upload_method',
    required: true,
    type: RegexpType.STRING,
    value: { label: 'FTP', value: 'FTP' },
    valid: true
  }),
  newFormField({
    field: 'youtube',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'adv_code_youtube',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'host',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'port',
    required: true,
    type: RegexpType.POSITIVE
  }),
  newFormField({
    field: 'username',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'password',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'virtual_remote_path',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'platform_enabled',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'enads',
    required: false,
    type: RegexpType.STRING,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'pip',
    required: false,
    type: RegexpType.STRING,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'height',
    required: true,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'width',
    required: true,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'controls',
    required: false,
    type: RegexpType.NUMBER,
    value: 1,
    valid: true
  }),
  newFormField({
    field: 'autoplay',
    required: false,
    type: RegexpType.NUMBER,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'mute',
    required: false,
    type: RegexpType.NUMBER,
    value: 0,
    valid: true
  }),
  newFormField({
    field: 'feed_enabled',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_width',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_height',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_total_video',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({ 
    field: 'origin', 
    required: false, 
    type: RegexpType.STRING 
  })
];

const DMForm = [
  newFormField({
    field: 'client_id_DM',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'client_secret_DM',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'username_DM',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'password_DM',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'adv_code_DM',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'dailymotion',
    required: true,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'platform_enabled_DM',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'enads_DM',
    required: false,
    type: RegexpType.STRING,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'pip_DM',
    required: false,
    type: RegexpType.STRING,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'pip_cust_desktop',
    required: true,
    type: RegexpType.STRING,
    value: { label: 'Default', value: 'default' },
    valid: true
  }),
  newFormField({
    field: 'pip_cust_mobile',
    required: true,
    type: RegexpType.STRING,
    value: { label: 'Default', value: 'default' },
    valid: true
  }),
  newFormField({
    field: 'height_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'width_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'controls_DM',
    required: false,
    type: RegexpType.NUMBER,
    value: 1,
    valid: true
  }),
  newFormField({
    field: 'autoplay_DM',
    required: false,
    type: RegexpType.NUMBER,
    value: true,
    valid: true
  }),
  newFormField({
    field: 'mute_DM',
    required: false,
    type: RegexpType.NUMBER,
    value: 0,
    valid: true
  }),
  newFormField({
    field: 'feed_enabled_DM',
    required: false,
    type: RegexpType.STRING
  }),
  newFormField({
    field: 'feed_width_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_height_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'feed_total_video_DM',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({ 
    field: 'origin_DM', 
    required: false, 
    type: RegexpType.STRING 
  })
];

export const getFormFieldsByPlatforms = (platform = '') => {
  switch (platform) {
    case platforms.YT:
      return YTForm;
    case platforms.DM:
      return DMForm;
    default:
      return [...YTForm, ...DMForm];
  }
};
