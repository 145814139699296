import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  AuthContainer, AuthWrapper, ButtonWrapper, FormInputWrapper, LogoWrapper
} from './style';
import { Button, FormInput, Logo } from '../../atoms';
import { isValidForm, newForm, newFormField } from '../../utils/form';
import { RegexpType, validator } from '../../utils/validator';
import { AUTH_LOGIN } from '../../redux/actions/auth';
import storage from '../../utils/storage';
import { getLabelValue } from '../../utils/common';

const initialForm = newForm([
  newFormField({ field: 'username', required: true, type: RegexpType.ALPHA_NUMERIC }),
  newFormField({ field: 'password', required: true, type: RegexpType.STRING })
]);

const Auth = ({
  submitAuth, labels
}) => {
  useEffect(() => {
    storage.clearAll();
  }, [])
  const [authForm, setAuthForm] = useState(initialForm);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  const handleOnSubmit = () => {
    submitAuth(authForm);
  };

  const onKeyPress = (e) => {
    if (isValidForm(authForm) && e.key === 'Enter') handleOnSubmit();
  };

  return (
    <AuthContainer>
      <AuthWrapper>
        <LogoWrapper>
          <Logo size={Logo.SIZE.MEDIUM} />
        </LogoWrapper>
        <FormInputWrapper>
          <FormInput
            label={getLabelValue('username', labels)}
            onChange={newValue => handleOnChange('username', newValue)}
            valid={authForm?.username?.valid}
            value={authForm?.username?.value}
            onKeyPress={onKeyPress}
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <FormInput
            type="password"
            label={getLabelValue('password', labels)}
            onChange={newValue => handleOnChange('password', newValue)}
            valid={authForm?.password?.valid}
            value={authForm?.password?.value}
            onKeyPress={onKeyPress}
          />
        </FormInputWrapper>
        <ButtonWrapper>
          <Button
            btnText="Login"
            disabled={!isValidForm(authForm)}
            onClick={handleOnSubmit}
            width="100px"
          />
        </ButtonWrapper>
      </AuthWrapper>
    </AuthContainer>
  );
};

Auth.propTypes = {
  // HOC (connect, dispatch)
  submitAuth: PropTypes.func.isRequired
};

export default connect(
  state => ({
    labels: state.app.selectedLabel
  }),
  dispatch => ({
    submitAuth: authForm => dispatch({ type: AUTH_LOGIN._REQUEST, authForm })
  })
)(Auth);
