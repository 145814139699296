import styled from 'styled-components';
import theme from '../../../ui/theme';

const FormContainer = styled.div``;

const InformationContainer = styled.div`
  margin-bottom: 20px;
`;

const Data = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.span`
  margin-right: 3px;
`;

const Value = styled.span``;
const Note = styled.div`
  margin-bottom: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;
  margin: 10px 0;

  > div:first-child {
    margin-right: 13px;
  }
`;

const AddPublisherWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    flex-direction: column;
  }
`;

const BtnSave = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: #f2f2f2;
  background-color: ${theme.colors.custom.blue};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

const BtnAddPublisher = styled.div`
  width: 101px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const PublishersList = styled.div``;

export const PublisherRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  @media ${theme.device.mobileL} {
    margin-top: 16px;
  }
`;

export const NamePublisher = styled.div`
  width: 34%;
`;
export const RolePublisher = styled.div`
  width: 33%;
  color: ${theme.colors.custom.blue};
  display: flex;
  justify-content: center;
`;

export const EditRoleWrapper = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 13px;
  }

  @media ${theme.device.mobileL} {
    margin-bottom: 10px;
    justify-content: space-between;
  }
`;

export const EditRoleTitle = styled.span`
  font-style: italic;
  color: ${theme.colors.custom.blue};
`;

export const ActionPublisher = styled.div`
  width: 33%;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    justify-content: flex-end;
  }
`;

export const BtnCancel = styled.div`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.custom.darkGray};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const BtnDelete = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.primary.red};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const DeletePublisherAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export {
  FormContainer,
  InformationContainer,
  Data,
  Label,
  Value,
  Note,
  SelectWrapper,
  AddPublisherWrapper,
  BtnSave,
  BtnAddPublisher
};
