/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';

import Switch from 'react-switch';
import { Container, Row, Col } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';
import { PUBLISHER_EDIT } from '../../../redux/actions/admin';
import youtube from '../../../ui/assets/img/youtube-logo.png';
import dailymotion from '../../../ui/assets/img/dailymotion-logo.svg';

import {
  ButtonWrapper,
  ModalBody,
  ModalGlobalContainer,
  ModalTitle,
  ModalWrapper
} from '../Modal.style';

import {
  EnableFeedHeader,
  EnableFeedSwitchWrapper,
  EnableFeedWrapper,
  FeedContainer,
  FeedHeightWidthInputsContainer,
  FormContainer,
  OriginLabel,
  OriginsContainer,
  LogoWrapper,
  PipCustContainer,
  TitleColumn,
  OriginsListContainer
} from './style';
import { MODAL_CLOSE } from '../../../redux/actions';
import { Button, FormInput, Icon } from '../../../atoms';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import theme from '../../../ui/theme';
import Roboto from '../../../ui/typography/roboto';

import {
  getLabelValue,
  pipCustLabelSwitch,
  pipCustomizationDesktopOptions,
  pipCustomizationMobileOptions
} from '../../../utils/common';
import {
  getFormFieldsByPlatforms,
  platforms
} from '../../../utils/formFieldsEditPublisher';
import {
  ChannelLogoDM,
  ChannelLogoYT,
  ChosenColor,
  CloseColorPicker,
  ColorOption,
  ColorTitle,
  EnablePlatformWrapper,
  ErrorMsg,
  HeightWidthContainer,
  InputsContainer,
  PlatformLogoWrapper,
  SketchPickerWrapper
} from '../AddPublisherModal/style';
import { Label } from '../../../atoms/FormInput/style';
import { FeedColorsInputsContainer } from '../../../pages/CreateFeed/style';

const modalId = 'EditPublisherModal';

const defaultOpt = {
  label: 'Default',
  value: 'default'
};

const EditPublisherModal = ({
  editPublisher,
  onClose,
  publisher = {},
  selectedPlatform,
  sftpOptions,
  defaultTemplateByPublisher,
  labels,
  language,
  defaultTemplateFeed,
  availablePlatformList
}) => {
  const initialForm = newForm(getFormFieldsByPlatforms(selectedPlatform));
  // const [channelTypeSelected, setChannelTypeSelected] = useState('');
  const [newUserForm, setNewUserForm] = useState(initialForm);
  // Color Theme
  const [displayThemeColorPickerYB, setDisplayThemeColorPickerYB] = useState(
    false
  );

  const [displayThemeColorPickerDM, setDisplayThemeColorPickerDM] = useState(
    false
  );
  const [themeColorYB, setThemeColorYB] = useState(
    `${theme.colors.custom.youtube}`
  );
  const [themeColorDM, setThemeColorDM] = useState(
    `${theme.colors.custom.dailymotion}`
  );
  // MISSING FIELDS
  const [missingFields, setMissingFields] = useState([]);
  const [displayError, setDisplayError] = useState(false);

  // ORIGIN
  const [originText, setOriginText] = useState('');
  const [originList, setOriginList] = useState([]);
  const [originListForm, setOriginListForm] = useState('');

  // Publisher Feed Enabled
  const [publisherFeedsEnabled, setPublisherFeedsEnabled] = useState({});
  const [feedSwitchDisabled, setFeedSwitchDisabled] = useState(false);

  // Color pickers
  const [displayBGColorPickerYT, setDisplayBGColorPickerYT] = useState(false);
  const [displayBGColorPickerDM, setDisplayBGColorPickerDM] = useState(false);
  const [displayFontColorPickerYT, setDisplayFontColorPickerYT] = useState(false);
  const [displayFontColorPickerDM, setDisplayFontColorPickerDM] = useState(false);

  // Colors
  const [backgroundColor, setBackgroundColor] = useState();
  const [fontColor, setFontColor] = useState();

  useEffect(() => {
    let isDisabled = false;
    if (selectedPlatform === platforms.YT) {
      if (!Boolean(newUserForm.feed_height.value)) isDisabled = true;
      if (!Boolean(newUserForm.feed_width.value)) isDisabled = true;
      if (!Boolean(newUserForm.feed_total_video.value)) isDisabled = true;
    } else {
      if (!Boolean(newUserForm.feed_height_DM.value)) isDisabled = true;
      if (!Boolean(newUserForm.feed_width_DM.value)) isDisabled = true;
      if (!Boolean(newUserForm.feed_total_video_DM.value)) isDisabled = true;
    }

    setFeedSwitchDisabled(isDisabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserForm, originList])

  const uploadMethodOptions = [
    { label: 'FTP', value: 'FTP' },
    { label: 'SFTP', value: 'SFTP' }
  ];

  useEffect(() => {
    if (Object.keys(publisher).length > 0) {
      const namePublisher = publisher?.name;
  
      setPublisherFeedsEnabled({
        youtube: availablePlatformList
        ?.filter(pub => pub.name === namePublisher)[0]
        ?.youtube?.feed_enabled,
        dailymotion: availablePlatformList
        ?.filter(pub => pub.name === namePublisher)[0]
        ?.dailymotion?.feed_enabled,
      });
  
      if (Boolean(defaultTemplateFeed)) {
        setOriginList(selectedPlatform === 'youtube' 
          ? defaultTemplateFeed?.youtube?.allowedOrigins 
          : defaultTemplateFeed?.dailymotion?.allowedOrigins)
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, defaultTemplateByPublisher, defaultTemplateFeed])
  
  useEffect(() => {

    if (Object.keys(publisher).length > 0) {
      const selectedUploadOptions = sftpOptions?.upload_method
        ? [...uploadMethodOptions].find(
            option => option.value === sftpOptions?.upload_method
          ) || { label: 'FTP', value: 'FTP' }
        : { label: 'FTP', value: 'FTP' };
      
      selectedPlatform === platforms.YT
        ? setNewUserForm(
            newForm([
              newFormField({
                field: 'upload_method',
                required: true,
                type: RegexpType.STRING,
                value: selectedUploadOptions,
                valid: validator(
                  RegexpType.STRING,
                  selectedUploadOptions?.value,
                  true
                )
              }),
              newFormField({
                field: 'youtube',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.youtube?.channelId,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.youtube?.channelId,
                  true
                )
              }),
              newFormField({
                field: 'adv_code_youtube',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.youtube?.adv_code,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.youtube?.adv_code,
                  true
                )
              }),

              newFormField({
                field: 'host',
                required: true,
                type: RegexpType.STRING,
                value: sftpOptions?.host,
                valid: validator(RegexpType.STRING, sftpOptions?.host, true)
              }),
              newFormField({
                field: 'port',
                required: true,
                type: RegexpType.POSITIVE,
                value: sftpOptions?.port || undefined,
                valid: validator(RegexpType.POSITIVE, sftpOptions?.port, true)
              }),
              newFormField({
                field: 'username',
                required: true,
                type: RegexpType.STRING,
                value: sftpOptions?.username,
                valid: validator(RegexpType.STRING, sftpOptions?.username, true)
              }),
              newFormField({
                field: 'password',
                required: true,
                type: RegexpType.STRING,
                value: sftpOptions?.password,
                valid: validator(RegexpType.STRING, sftpOptions?.password, true)
              }),
              newFormField({
                field: 'virtual_remote_path',
                required: true,
                type: RegexpType.STRING,
                value: sftpOptions?.virtual_remote_path,
                valid: validator(
                  RegexpType.STRING,
                  sftpOptions?.virtual_remote_path,
                  true
                )
              }),
              newFormField({
                field: 'height',
                required: true,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.height,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.height,
                  true
                )
              }),
              newFormField({
                field: 'width',
                required: true,
                type: RegexpType.NUMBER,
                value: defaultTemplateByPublisher?.youtube?.videoConfigs?.width,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.width,
                  true
                )
              }),
              newFormField({
                field: 'platform_enabled',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: publisher?.youtube?.platform_enabled
              }),
              newFormField({
                field: 'enads',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: defaultTemplateByPublisher?.youtube?.enads
              }),
              newFormField({
                field: 'pip',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: defaultTemplateByPublisher?.youtube?.pip
              }),
              newFormField({
                field: 'controls',
                required: false,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars
                    ?.controls,
                valid: true
              }),
              newFormField({
                field: 'autoplay',
                required: false,
                type: RegexpType.STRING,
                value:
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars
                    ?.autoplay,
                valid: true
              }),
              newFormField({
                field: 'mute',
                required: false,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars
                    ?.mute,
                valid: true
              }),
              newFormField({
                field: 'feed_enabled',
                required: false,
                type: RegexpType.STRING,
                value: publisherFeedsEnabled?.youtube,
                valid: true
              }),
              newFormField({
                field: 'feed_width',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.youtube?.width,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.youtube?.width,
                  true)
              }),
              newFormField({
                field: 'feed_height',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.youtube?.height,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.youtube?.height,
                  true)
              }),
              newFormField({
                field: 'feed_total_video',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.youtube?.maxVideos,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.youtube?.maxVideos,
                  true)
              }),
              newFormField({ 
                field: 'origin', 
                required: false, 
                type: RegexpType.STRING,
                value: defaultTemplateFeed?.youtube?.allowedOrigins,
                valid: true 
              }),
            ])
          )
        : setNewUserForm(
            newForm([
              newFormField({
                field: 'adv_code_DM',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.adv_code,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.adv_code,
                  true
                )
              }),
              newFormField({
                field: 'client_id_DM',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.client_id,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.client_id,
                  true
                )
              }),
              newFormField({
                field: 'client_secret_DM',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.client_secret,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.client_secret,
                  true
                )
              }),
              newFormField({
                field: 'username_DM',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.username,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.username,
                  true
                )
              }),
              newFormField({
                field: 'password_DM',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.password,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.password,
                  true
                )
              }),
              newFormField({
                field: 'dailymotion',
                required: true,
                type: RegexpType.STRING,
                value: publisher?.dailymotion?.channelId,
                valid: validator(
                  RegexpType.STRING,
                  publisher?.dailymotion?.channelId,
                  true
                )
              }),
              newFormField({
                field: 'height_DM',
                required: false,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.dailymotion?.height === 0
                    ? ''
                    : defaultTemplateByPublisher?.dailymotion?.height,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateByPublisher?.dailymotion?.height,
                  true
                )
              }),
              newFormField({
                field: 'width_DM',
                required: false,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.dailymotion?.width === 0
                    ? ''
                    : defaultTemplateByPublisher?.dailymotion?.width,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateByPublisher?.dailymotion?.width,
                  true
                )
              }),
              newFormField({
                field: 'platform_enabled_DM',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: publisher?.dailymotion?.platform_enabled
              }),
              newFormField({
                field: 'enads_DM',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: defaultTemplateByPublisher?.dailymotion?.enads
              }),
              newFormField({
                field: 'pip_DM',
                required: false,
                type: RegexpType.STRING,
                valid: true,
                value: defaultTemplateByPublisher?.dailymotion?.pip
              }),
              newFormField({
                field: 'pip_cust_desktop',
                required: true,
                type: RegexpType.STRING,
                value: {
                  label: pipCustLabelSwitch(
                    language,
                    'desktop',
                    defaultTemplateByPublisher?.dailymotion?.pip_customization
                      ?.desktop || 'Default'
                  ),
                  value:
                    defaultTemplateByPublisher?.dailymotion?.pip_customization
                      ?.desktop || 'default'
                },
                valid: true
              }),
              newFormField({
                field: 'pip_cust_mobile',
                required: true,
                type: RegexpType.STRING,
                value: {
                  label: pipCustLabelSwitch(
                    language,
                    'mobile',
                    defaultTemplateByPublisher?.dailymotion?.pip_customization
                      ?.mobile || 'Default'
                  ),
                  value:
                    defaultTemplateByPublisher?.dailymotion?.pip_customization
                      ?.mobile || 'default'
                },
                valid: true
              }),
              newFormField({
                field: 'controls_DM',
                required: false,
                type: RegexpType.NUMBER,
                value:
                  defaultTemplateByPublisher?.dailymotion?.params?.controls,
                valid: true
              }),
              newFormField({
                field: 'autoplay_DM',
                required: false,
                type: RegexpType.STRING,
                value:
                  defaultTemplateByPublisher?.dailymotion?.params?.autoplay,
                valid: true
              }),
              newFormField({
                field: 'mute_DM',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateByPublisher?.dailymotion?.params?.mute,
                valid: true
              }),
              newFormField({
                field: 'feed_enabled_DM',
                required: false,
                type: RegexpType.STRING,
                value: publisherFeedsEnabled?.dailymotion,
                valid: true
              }),
              newFormField({
                field: 'feed_width_DM',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.dailymotion?.width,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.dailymotion?.width,
                  true)
              }),
              newFormField({
                field: 'feed_height_DM',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.dailymotion?.height,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.dailymotion?.height,
                  true)
              }),
              newFormField({
                field: 'feed_total_video_DM',
                required: false,
                type: RegexpType.NUMBER,
                value: defaultTemplateFeed?.dailymotion?.maxVideos,
                valid: validator(
                  RegexpType.NUMBER,
                  defaultTemplateFeed?.dailymotion?.maxVideos,
                  true)
              }),
              newFormField({ 
                field: 'origin_DM', 
                required: false, 
                type: RegexpType.STRING,
                value: defaultTemplateFeed?.dailymotion?.allowedOrigins,
                valid: true 
              }),
            ])
          );

      setThemeColorYB(
        defaultTemplateByPublisher?.youtube?.videoConfigs?.embedConfig
          ?.primaryThemeColor
      );
      setThemeColorDM(
        `#${defaultTemplateByPublisher?.dailymotion?.params?.['ui-highlight']}`
      );

    if (selectedPlatform === 'youtube') {
      setBackgroundColor(defaultTemplateFeed?.youtube?.backgroundColor);
      setFontColor(defaultTemplateFeed?.youtube?.fontColor);      
    } else {
      setBackgroundColor(defaultTemplateFeed?.dailymotion?.backgroundColor);
      setFontColor(defaultTemplateFeed?.dailymotion?.fontColor);    
    }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, sftpOptions, defaultTemplateByPublisher, defaultTemplateFeed, selectedPlatform, publisherFeedsEnabled, originList]);

  const showMissingFields = () => {
    const missing = Object.values(newUserForm).filter(
      field => field.required && !field.valid
    );
    const missingFieldsLabels = missing.map(f => f.field);
    setMissingFields(missingFieldsLabels);
  };

  const isMissingField = field => {
    return !newUserForm?.[field]?.value && missingFields.includes(field);
  };

  useEffect(() => {
    //when modal unmounts, clear newUserForm
    return () => setNewUserForm(newForm(getFormFieldsByPlatforms()));
  }, [selectedPlatform]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newUserForm[field];

    setNewUserForm({
      ...newUserForm,
      [field]: {
        ...newUserForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  const checkSubmitDisabled = () => {
    let checkValue = false;
    Object.keys(newUserForm).forEach(key => {
      if (newUserForm[key]?.value) {
        checkValue = true;
      }
    });
    if (selectedPlatform === platforms.DM) {
      // if width or height is not defined -> not valid
      if (!newUserForm.width_DM.value || !newUserForm.height_DM.value) {
        checkValue = false;
      }

      // if both width AND height are not defined -> is valid ( = auto )
      if (!newUserForm.width_DM.value && !newUserForm.height_DM.value) {
        checkValue = true;
      }

      // if 0 < width or height < 150 -> not valid
      if (
        (Number(newUserForm.width_DM.value) < 150 &&
          Number(newUserForm.width_DM.value) !== 0) ||
        (Number(newUserForm.height_DM.value) < 150 &&
          Number(newUserForm.height_DM.value) !== 0)
      ) {
        checkValue = false;
      }
    } else if (selectedPlatform === platforms.YT) {
      if (
        Number(newUserForm.width.value) < 150 ||
        Number(newUserForm.height.value) < 150
      ) {
        checkValue = false;
      }
    }

    return !(
      (
        isValidForm(newUserForm) && checkValue
      ) /*&& Boolean(channelTypeSelected)*/
    );
  };

  // const handleOnChangeChannelType = value => {
  //   setChannelTypeSelected(value);
  // };

  const handleOnCloseColorPicker = (e, platform) => {
    e.preventDefault();
    platform === 'yb'
      ? setDisplayThemeColorPickerYB(false)
      : setDisplayThemeColorPickerDM(false);
    //window.location.reload(); // reload
  };

  const handleOnCloseFeedColorPicker = (e, platform) => {
    e.preventDefault();
    if (platform === 'youtube') {
      setDisplayBGColorPickerYT(false);
      setDisplayFontColorPickerYT(false);
    } else {
      setDisplayBGColorPickerDM(false);
      setDisplayFontColorPickerDM(false);
    }
  };

  const handleOnClose = () => {
    setNewUserForm(newForm(getFormFieldsByPlatforms()));
    setMissingFields([]);
    setDisplayError(false);
    // setChannelTypeSelected([]);
    onClose();
  };

  const handleEditPublisher = () => {
    const { name } = publisher;
    const body = { name };

    if (selectedPlatform === platforms.YT) {
      const host = newUserForm.host.value;
      const port = parseInt(newUserForm.port.value, 10);
      const username = newUserForm.username.value;
      const password = newUserForm.password.value;
      const virtual_remote_path = newUserForm.virtual_remote_path.value;
      const upload_method = newUserForm.upload_method.value.value;

      body.youtube = {
        channelId: newUserForm.youtube.value,
        adv_code: newUserForm.adv_code_youtube.value,
        platform_enabled: newUserForm.platform_enabled.value,
        defaultTemplate: {
          enads: newUserForm.enads.value,
          pip: newUserForm.pip.value,
          videoConfigs: {
            height: Number(newUserForm.height.value),
            width: Number(newUserForm.width.value),
            playerVars: {
              controls: Number(newUserForm.controls.value),
              autoplay: Boolean(newUserForm.autoplay.value),
              mute: Number(newUserForm.mute.value)
            },
            embedConfig: {
              primaryThemeColor: themeColorYB
            }
          }
        },
        feed_enabled: newUserForm.feed_enabled.value,
        defaultFeedTemplate: {
          height: Number(newUserForm.feed_height.value),
          width: Number(newUserForm.feed_width.value),
          maxVideos: Number(newUserForm.feed_total_video.value),
          allowedOrigins: newUserForm.origin.value,
          fontColor,
          backgroundColor
        }
      };
      body.upload_server_params = {
        host,
        port,
        username,
        password,
        virtual_remote_path,
        upload_method
      };
    }

    if (selectedPlatform === platforms.DM) {
      const themeColorDMreplace = themeColorDM.replace('#', '');
      body.dailymotion = {
        channelId: newUserForm.dailymotion.value,
        client_id: newUserForm.client_id_DM.value,
        client_secret: newUserForm.client_secret_DM.value,
        username: newUserForm.username_DM.value,
        password: newUserForm.password_DM.value,
        adv_code: newUserForm.adv_code_DM.value,
        platform_enabled: newUserForm.platform_enabled_DM.value,
        defaultTemplate: {
          enads: newUserForm.enads_DM.value,
          pip: newUserForm.pip_DM.value,
          pip_customization: {
            desktop: newUserForm.pip_cust_desktop.value.value,
            mobile: newUserForm.pip_cust_mobile.value.value
          },
          height: Number(newUserForm.height_DM.value),
          width: Number(newUserForm.width_DM.value),
          params: {
            autoplay: Boolean(newUserForm.autoplay_DM.value),
            mute: Boolean(newUserForm.mute_DM.value),
            controls: Number(newUserForm.controls_DM.value),
            'ui-highlight': themeColorDMreplace
          }
        },
        feed_enabled: newUserForm.feed_enabled_DM.value,
        defaultFeedTemplate: {
          height: Number(newUserForm.feed_height_DM.value),
          width: Number(newUserForm.feed_width_DM.value),
          maxVideos: Number(newUserForm.feed_total_video_DM.value),
          allowedOrigins: newUserForm.origin_DM.value,
          fontColor,
          backgroundColor
        }
      };
    }

    editPublisher(body);
    handleOnClose();
  };

  const [enads, setEnads] = useState(
    Boolean(defaultTemplateByPublisher?.youtube?.enads)
  );
  const onChangeEnads = newValue => {
    handleOnChange('enads', newValue);
    setEnads(newValue);
  };

  const [enadsDM, setEnadsDM] = useState(
    Boolean(defaultTemplateByPublisher?.dailymotion?.enads)
  );
  const onChangeEnadsDM = newValue => {
    handleOnChange('enads_DM', newValue);
    setEnadsDM(newValue);
  };

  const [platformEnabled, setPlatformEnabled] = useState(
    Boolean(publisher?.youtube?.platform_enabled)
  );

  const onChangePlatformEnabled = newValue => {
    const missing = Object.values(newUserForm).filter(
      field => field.required && !field.valid
    );
    if (missing.length === 0) {
      handleOnChange('platform_enabled', newValue);
      setPlatformEnabled(newValue);
      setDisplayError(false);
    } else {
      setDisplayError(true);
      showMissingFields();
    }
  };

  const [platformEnabledDM, setPlatformEnabledDM] = useState(
    Boolean(publisher?.dailymotion?.platform_enabled)
  );

  const onChangePlatformEnabledDM = newValue => {
    const missing = Object.values(newUserForm).filter(
      field => field.required && !field.valid
    );
    if (missing.length === 0) {
      handleOnChange('platform_enabled_DM', newValue);
      setPlatformEnabledDM(newValue);
      setDisplayError(false);
    } else {
      setDisplayError(true);
      showMissingFields();
    }
  };

  const [feedEnabled, setFeedEnabled] = useState(
    publisherFeedsEnabled?.youtube
  );

  const onChangeFeedEnabled = newValue => {
    handleOnChange('feed_enabled', newValue);
    setFeedEnabled(newValue);
  };

  const [feedEnabledDM, setFeedEnabledDM] = useState(
    publisherFeedsEnabled?.dailymotion
  );

  const onChangeFeedEnabledDM = newValue => {
      handleOnChange('feed_enabled_DM', newValue);
      setFeedEnabledDM(newValue);
  };

  useEffect(() => {
    if (missingFields?.length > 0) {
      setPlatformEnabled(false);
      setPlatformEnabledDM(false);
    }
  }, [missingFields]);

  const [pip, setPip] = useState(true);
  const onChangePip = newValue => {
    handleOnChange('pip', newValue);
    setPip(newValue);
  };

  const [pipDM, setPipDM] = useState(true);
  const onChangePipDM = newValue => {
    handleOnChange('pip_DM', newValue);
    setPipDM(newValue);
  };

  useEffect(() => {
    if (selectedPlatform === platforms.DM && !pipDM ) {
      setNewUserForm({
        ...newUserForm,
        pip_cust_desktop: {
          ...newUserForm.pip_cust_desktop,
          value: defaultOpt
        },
        pip_cust_mobile: {
          ...newUserForm.pip_cust_mobile,
          value: defaultOpt
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipDM]);

  const [controls, setControls] = useState(
    Boolean(
      defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.controls
    )
  );
  const onChangeControls = newValue => {
    handleOnChange('controls', newValue ? 1 : 0);
    setControls(newValue);
  };

  const [controlsDM, setControlsDM] = useState(
    Boolean(defaultTemplateByPublisher?.dailymotion?.params?.controls)
  );
  const onChangeControlsDM = newValue => {
    handleOnChange('controls_DM', newValue ? 1 : 0);
    setControlsDM(newValue);
  };

  const [mute, setMute] = useState(
    Boolean(defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.mute)
  );
  const onChangeMute = newValue => {
    handleOnChange('mute', newValue ? 1 : 0);
    setMute(newValue);
  };

  const [autoplay, setAutoplay] = useState(
    Boolean(
      defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.autoplay
    )
  );
  const onChangeAutoplay = newValue => {
    handleOnChange('autoplay', newValue);
    setAutoplay(newValue);
  };

  const [muteDM, setMuteDM] = useState(
    Boolean(defaultTemplateByPublisher?.dailymotion?.params?.mute)
  );
  const onChangeMuteDM = newValue => {
    handleOnChange('mute_DM', newValue ? 1 : 0);
    setMuteDM(newValue);
  };

  const [autoplayDM, setAutoplayDM] = useState(
    Boolean(defaultTemplateByPublisher?.dailymotion?.params?.autoplay)
  );
  const onChangeAutoplayDM = newValue => {
    handleOnChange('autoplay_DM', newValue);
    setAutoplayDM(newValue);
  };

  useEffect(() => {
    setEnads(Boolean(defaultTemplateByPublisher?.youtube?.enads));
    setEnadsDM(Boolean(defaultTemplateByPublisher?.dailymotion?.enads));
    setPip(Boolean(defaultTemplateByPublisher?.youtube?.pip));
    setPipDM(Boolean(defaultTemplateByPublisher?.dailymotion?.pip));
    setControls(
      Boolean(
        defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.controls
      )
    );
    setControlsDM(
      Boolean(defaultTemplateByPublisher?.dailymotion?.params?.controls)
    );
    setMute(
      Boolean(
        defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.mute
      )
    );
    setAutoplay(
      Boolean(
        defaultTemplateByPublisher?.youtube?.videoConfigs?.playerVars?.autoplay
      )
    );
    setMuteDM(Boolean(defaultTemplateByPublisher?.dailymotion?.params?.mute));
    setAutoplayDM(
      Boolean(defaultTemplateByPublisher?.dailymotion?.params?.autoplay)
    );
  }, [defaultTemplateByPublisher]);

  useEffect(() => {
    setPlatformEnabled(Boolean(publisher?.youtube?.platform_enabled));
    setPlatformEnabledDM(Boolean(publisher?.dailymotion?.platform_enabled));
  }, [publisher]);

  useEffect(() => {
    setPlatformEnabled(Boolean(publisher?.youtube?.platform_enabled));
    setPlatformEnabledDM(Boolean(publisher?.dailymotion?.platform_enabled));
  }, [publisher]);

  useEffect(() => {
    setFeedEnabled(publisherFeedsEnabled?.youtube);
    setFeedEnabledDM(publisherFeedsEnabled?.dailymotion);
  }, [publisherFeedsEnabled]);

  const handleOnChangeOrigins = value => {
    setOriginText(value);
  };

  const handleOnKeyPressOrigins = e => {
    if (
      originText.length &&
      (e.key === 'Enter' || e.key === ',') &&
      !originList.includes(originText)
    ) {
      const newList = [...originList, originText];
      setOriginList(newList);
      const list = newList.toString().replaceAll(',', '|');
      setOriginListForm(list);
      setOriginText('');
    }
  };
  
  const handleOnClickDeleteOrigin = kw => {
    const newList = originList.filter(el => el !== kw);
    setOriginList(newList);
    const list = newList.toString().replaceAll(',', '|');
    setOriginListForm(list);
  };

  // update allowed_origins form value
  useEffect(() => {
    selectedPlatform === platforms.YT
    ? handleOnChange(
      newUserForm?.origin?.field,
      originList
    )
    : handleOnChange(
      newUserForm?.origin_DM?.field,
      originList
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originList])
  
  return (
    <Modal id={modalId} onClose={handleOnClose}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            height="90%"
            width="100%"
          >
            <ModalTitle>
              <Roboto htmlAttribute="span" type="modalTitle">
                {getLabelValue('modal_edit_publisher', labels)}
              </Roboto>
              <Icon
                type="icon-close"
                color={theme.colors.custom.darkText}
                size={20}
                onClick={handleOnClose}
              />
            </ModalTitle>

            <ModalBody>
              <FormContainer>
                <Container>
                  <Row>
                    {/* NAME PUBLISHER */}
                    <Col sm={12} md={6} lg={6} className="pl-0 pb-3">
                      <FormInput
                        label={getLabelValue(
                          'input_publisher_name_label',
                          labels
                        )}
                        value={publisher.name}
                        readonly
                      />
                    </Col>

                    {/* PLATFORM LOGO */}
                    <Col sm={12} md={6} lg={6} className="pl-0 pb-3">
                      <PlatformLogoWrapper>
                        {selectedPlatform === platforms.YT && (
                          <>
                            <LogoWrapper className="yt">
                              <ChannelLogoYT src={youtube} alt="" />
                            </LogoWrapper>
                            <EnablePlatformWrapper checked={platformEnabled}>
                              <Label
                                className="platform-enable--label"
                                type="modal-input"
                              >
                                <Roboto
                                  configuration={{
                                    fontWeight: 500,
                                    fontSize: 12
                                  }}
                                >
                                  {getLabelValue(
                                    'toggle_enable_platform',
                                    labels
                                  )}
                                </Roboto>
                              </Label>
                              <Switch
                                className="platform-enable--switch"
                                onChange={onChangePlatformEnabled}
                                checked={platformEnabled}
                              />
                              {displayError && (
                                <ErrorMsg>
                                  <Roboto>
                                    {getLabelValue(
                                      'message_enable_platform',
                                      labels
                                    )}
                                  </Roboto>
                                </ErrorMsg>
                              )}
                            </EnablePlatformWrapper>
                          </>
                        )}
                        {selectedPlatform === platforms.DM && (
                          <>
                            <LogoWrapper className="dm">
                              <ChannelLogoDM src={dailymotion} alt="" />
                            </LogoWrapper>
                            <EnablePlatformWrapper checked={platformEnabledDM}>
                              <Label
                                className="platform-enable--label dm"
                                type="modal-input"
                              >
                                <Roboto
                                  configuration={{
                                    fontWeight: 500,
                                    fontSize: 12
                                  }}
                                >
                                  {getLabelValue(
                                    'toggle_enable_platform',
                                    labels
                                  )}
                                </Roboto>
                              </Label>
                              <Switch
                                className="platform-enable--switch"
                                onChange={onChangePlatformEnabledDM}
                                checked={platformEnabledDM}
                              />
                              {displayError && (
                                <ErrorMsg>
                                  <Roboto>
                                    {getLabelValue(
                                      'message_enable_platform',
                                      labels
                                    )}
                                  </Roboto>
                                </ErrorMsg>
                              )}
                            </EnablePlatformWrapper>
                          </>
                        )}
                      </PlatformLogoWrapper>
                    </Col>

                    {/* YOUTUBE */}
                    {selectedPlatform === platforms.YT && (
                      <Col sm={12} md={6} lg={6} className="pl-0 mb-3">
                        <FormInput
                          label={getLabelValue(
                            'input_publisher_channel_id_label',
                            labels
                          )}
                          onChange={newValue =>
                            handleOnChange(newUserForm.youtube.field, newValue)
                          }
                          value={newUserForm.youtube.value}
                          valid={newUserForm.youtube.valid}
                          required={newUserForm.youtube.required}
                          type="modal-input"
                          placeholder={publisher.youtubeChannelId}
                          missingField={isMissingField('youtube')}
                        />
                        <FormInput
                          label={getLabelValue(
                            'input_publisher_adv_code_label',
                            labels
                          )}
                          onChange={newValue =>
                            handleOnChange(
                              newUserForm.adv_code_youtube.field,
                              newValue
                            )
                          }
                          value={newUserForm.adv_code_youtube.value}
                          valid={newUserForm.adv_code_youtube.valid}
                          required={newUserForm.adv_code_youtube.required}
                          type="modal-input"
                          placeholder={publisher?.youtube?.channelId}
                          missingField={isMissingField('adv_code_youtube')}
                        />
                        <Row>
                          <Col sm={12} md={6} lg={6} className="mb-3 mt-2">
                            <Label type="modal-input">
                              <Roboto>
                                {getLabelValue(
                                  'toggle_enable_advertisment',
                                  labels
                                )}
                              </Roboto>
                            </Label>
                            <Switch onChange={onChangeEnads} checked={enads} />
                          </Col>
                          <Col sm={12} md={6} lg={6} className="mb-3 mt-2">
                            <Label type="modal-input">
                              <Roboto>
                                {getLabelValue('toggle_pip', labels)}
                              </Roboto>
                            </Label>
                            <Switch onChange={onChangePip} checked={pip} />
                          </Col>
                        </Row>
                        <HeightWidthContainer>
                          <InputsContainer>
                            <FormInput
                              label={getLabelValue(
                                'input_height_label',
                                labels
                              )}
                              onChange={newValue =>
                                handleOnChange(
                                  newUserForm.height.field,
                                  newValue
                                )
                              }
                              value={newUserForm.height.value}
                              valid={newUserForm.height.valid}
                              required={newUserForm.height.required}
                              type="modal-input"
                              widthHeightCheck={
                                newUserForm.height.value < 150 &&
                                newUserForm.height.value !== ''
                              }
                              missingField={isMissingField('height')}
                            />
                            <FormInput
                              label={getLabelValue('input_width_label', labels)}
                              onChange={newValue =>
                                handleOnChange(
                                  newUserForm.width.field,
                                  newValue
                                )
                              }
                              value={newUserForm.width.value}
                              valid={newUserForm.width.valid}
                              required={newUserForm.width.required}
                              type="modal-input"
                              widthHeightCheck={
                                newUserForm.width.value < 150 &&
                                newUserForm.width.value !== ''
                              }
                              missingField={isMissingField('width')}
                            />
                          </InputsContainer>
                        </HeightWidthContainer>
                        <Row>
                          <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                            <Label type="modal-input">
                              <Roboto>
                                {getLabelValue('toggle_controls', labels)}
                              </Roboto>
                            </Label>
                            <Switch
                              onChange={onChangeControls}
                              checked={controls}
                            />
                          </Col>
                          <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                            <Label type="modal-input">
                              <Roboto>
                                {getLabelValue('toggle_autoplay', labels)}
                              </Roboto>
                            </Label>
                            <Switch
                              onChange={onChangeAutoplay}
                              checked={autoplay}
                            />
                          </Col>
                          <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                            <Label type="modal-input">
                              <Roboto>
                                {getLabelValue('toggle_mute', labels)}
                              </Roboto>
                            </Label>
                            <Switch onChange={onChangeMute} checked={mute} />
                          </Col>
                        </Row>
                        <ColorOption>
                          <ColorTitle>
                            <ChosenColor
                              onClick={() =>
                                setDisplayThemeColorPickerYB(
                                  !displayThemeColorPickerYB
                                )
                              }
                              playerColor={themeColorYB}
                            >
                              <Roboto>
                                {getLabelValue('theme_color', labels)}
                              </Roboto>
                            </ChosenColor>
                            {displayThemeColorPickerYB && (
                              <CloseColorPicker>
                                <Icon
                                  type="icon-close"
                                  size={13}
                                  onClick={e =>
                                    handleOnCloseColorPicker(e, 'yb')
                                  }
                                  color="#4f9eff"
                                />
                              </CloseColorPicker>
                            )}
                          </ColorTitle>
                          {displayThemeColorPickerYB && (
                            <SketchPicker
                              color={themeColorYB}
                              onChangeComplete={color =>
                                setThemeColorYB(color.hex)
                              }
                              presetColors={[]}
                              disableAlpha
                            />
                          )}
                        </ColorOption>
                        <FeedContainer>
                          <EnableFeedWrapper 
                            checked={feedEnabled}
                            >
                            <EnableFeedHeader>
                              <Roboto>
                                {getLabelValue('feed_modal_title_section', labels)}
                              </Roboto>
                              <EnableFeedSwitchWrapper>
                                <Label
                                  className="platform-enable--label dm"
                                  type="modal-input"
                                  checked={feedEnabled}
                                >
                                  <Roboto
                                    configuration={{
                                      fontWeight: 500,
                                      fontSize: 12
                                    }}
                                  >
                                    {getLabelValue(
                                      'feed_modal_toggle',
                                      labels
                                    )}
                                  </Roboto>
                                </Label>
                                <Switch
                                  className="platform-enable--switch"
                                  onChange={onChangeFeedEnabled}
                                  checked={feedEnabled}
                                  disabled={feedSwitchDisabled}
                                />
                              </EnableFeedSwitchWrapper>
                            </EnableFeedHeader>
                            <HeightWidthContainer>
                              <FeedHeightWidthInputsContainer>
                                <FormInput
                                  label={getLabelValue(
                                    'feed_modal_input_height',
                                    labels
                                  )}
                                  onChange={newValue =>
                                    handleOnChange(
                                      newUserForm.feed_height.field,
                                      newValue
                                    )
                                  }
                                  value={newUserForm.feed_height.value}
                                  valid={newUserForm.feed_height.valid}
                                  required={newUserForm.feed_height.required}
                                  type="modal-input"
                                  missingField={isMissingField('feed_height')}
                                />
                                <FormInput
                                  label={getLabelValue('feed_modal_input_width', labels)}
                                  onChange={newValue =>
                                    handleOnChange(
                                      newUserForm.feed_width.field,
                                      newValue
                                    )
                                  }
                                  value={newUserForm.feed_width.value}
                                  valid={newUserForm.feed_width.valid}
                                  required={newUserForm.feed_width.required}
                                  type="modal-input"
                                  missingField={isMissingField('feed_width')}
                                />
                                <FormInput
                                  label={getLabelValue('feed_modal_input_total_video', labels)}
                                  onChange={newValue =>
                                    handleOnChange(
                                      newUserForm.feed_total_video.field,
                                      newValue
                                    )
                                  }
                                  value={newUserForm.feed_total_video.value}
                                  valid={newUserForm.feed_total_video.valid}
                                  required={newUserForm.feed_total_video.required}
                                  type="modal-input"
                                  missingField={isMissingField('feed_total_video')}
                                />
                              </FeedHeightWidthInputsContainer>
                            </HeightWidthContainer>
                            <HeightWidthContainer>
                              <FeedColorsInputsContainer>
                                <ColorOption>
                                  <ColorTitle>
                                    <ChosenColor
                                      onClick={() => {
                                        setDisplayBGColorPickerYT(!displayBGColorPickerYT);
                                        setDisplayFontColorPickerYT(false);
                                      }}
                                      playerColor={backgroundColor}
                                    >
                                      <Roboto>
                                        {getLabelValue('feed_modal_background_color', labels)}
                                      </Roboto>
                                    </ChosenColor>
                                    {displayBGColorPickerYT && (
                                      <CloseColorPicker>
                                        <Icon
                                          type="icon-close"
                                          size={13}
                                          onClick={e =>
                                            handleOnCloseFeedColorPicker(e, 'youtube')
                                          }
                                          color="#4f9eff"
                                        />
                                      </CloseColorPicker>
                                    )}
                                  </ColorTitle>
                                  {displayBGColorPickerYT && (
                                    <SketchPickerWrapper>
                                      <SketchPicker
                                        color={backgroundColor}
                                        onChangeComplete={color =>
                                          setBackgroundColor(color.hex)
                                        }
                                        presetColors={[]}
                                        disableAlpha
                                      />
                                    </SketchPickerWrapper>
                                  )}
                                </ColorOption>
                                <ColorOption>
                                  <ColorTitle>
                                    <ChosenColor
                                      onClick={() => {
                                        setDisplayFontColorPickerYT(!displayFontColorPickerYT);
                                        setDisplayBGColorPickerYT(false);
                                      }}
                                      playerColor={fontColor}
                                    >
                                      <Roboto>
                                        {getLabelValue('feed_modal_font_color', labels)}
                                      </Roboto>
                                    </ChosenColor>
                                    {displayFontColorPickerYT && (
                                      <CloseColorPicker>
                                        <Icon
                                          type="icon-close"
                                          size={13}
                                          onClick={e =>
                                            handleOnCloseFeedColorPicker(e, 'youtube')
                                          }
                                          color="#4f9eff"
                                        />
                                      </CloseColorPicker>
                                    )}
                                  </ColorTitle>
                                  {displayFontColorPickerYT && (
                                    <SketchPickerWrapper>
                                      <SketchPicker
                                        color={fontColor}
                                        onChangeComplete={color =>
                                          setFontColor(color.hex)
                                        }
                                        presetColors={[]}
                                        disableAlpha
                                      />
                                    </SketchPickerWrapper>
                                  )}
                                </ColorOption>
                              </FeedColorsInputsContainer>
                            </HeightWidthContainer>
                          </EnableFeedWrapper>
                        </FeedContainer>
                        <OriginsContainer>
                          <FormInput
                            label={getLabelValue('feed_modal_input_origin', labels)}
                            value={originText}
                            required={newUserForm?.origin?.required}
                            onChange={newValue => handleOnChangeOrigins(newValue)}
                            onKeyPress={e => handleOnKeyPressOrigins(e)}
                            missingField={isMissingField('origin')}
                          />
                          {originList.length > 0 && (
                            <OriginsListContainer>
                              {originList.map((kw, i) => (
                                <OriginLabel key={`origin-${i}`}>
                                  {kw}
                                  <Icon
                                    type="icon-close"
                                    size={10}
                                    color={theme.colors.custom.lightText}
                                    onClick={() => handleOnClickDeleteOrigin(kw)}
                                  />
                                </OriginLabel>
                              ))}
                            </OriginsListContainer>
                          )}
                        </OriginsContainer>
                      </Col>
                    )}

                    {/* FTP OPTIONS */}
                    {selectedPlatform === platforms.YT && (
                      <Col sm={12} md={6} lg={6} className="pl-0 mt-2">
                        <TitleColumn>
                          {getLabelValue('modal_upload_server_options', labels)}
                        </TitleColumn>
                        <Row>
                          <Col sm={12} md={12} lg={12} className="mb-3">
                            <FormInput
                              label={getLabelValue(
                                'select_upload_method_label',
                                labels
                              )}
                              selected={newUserForm?.upload_method?.value}
                              type="select"
                              options={uploadMethodOptions}
                              onChange={newValue =>
                                handleOnChange(
                                  newUserForm?.upload_method?.field,
                                  newValue
                                )
                              }
                            />
                          </Col>

                          <Col sm={9} md={9} lg={9}>
                            <FormInput
                              label={getLabelValue(
                                'input_server_opt_host_label',
                                labels
                              )}
                              onChange={newValue =>
                                handleOnChange(newUserForm.host.field, newValue)
                              }
                              value={newUserForm.host.value}
                              valid={newUserForm.host.valid}
                              required={newUserForm.host.required}
                              type="modal-input"
                              placeholder={sftpOptions?.host}
                              missingField={isMissingField('host')}
                            />
                          </Col>
                          <Col sm={3} md={3} lg={3} className="pl-0">
                            <FormInput
                              label={getLabelValue(
                                'input_server_opt_port_label',
                                labels
                              )}
                              onChange={newValue =>
                                handleOnChange(newUserForm.port.field, newValue)
                              }
                              value={newUserForm.port.value}
                              valid={newUserForm.port.valid}
                              required={newUserForm.port.required}
                              type="modal-input"
                              placeholder={sftpOptions?.port}
                              missingField={isMissingField('port')}
                            />
                          </Col>
                        </Row>
                        <FormInput
                          label={getLabelValue(
                            'input_server_opt_username_label',
                            labels
                          )}
                          onChange={newValue =>
                            handleOnChange(newUserForm.username.field, newValue)
                          }
                          value={newUserForm.username.value}
                          valid={newUserForm.username.valid}
                          required={newUserForm.username.required}
                          type="modal-input"
                          placeholder={sftpOptions?.username}
                          missingField={isMissingField('username')}
                        />
                        <FormInput
                          label={getLabelValue(
                            'input_server_opt_password_label',
                            labels
                          )}
                          onChange={newValue =>
                            handleOnChange(newUserForm.password.field, newValue)
                          }
                          value={newUserForm.password.value}
                          valid={newUserForm.password.valid}
                          required={newUserForm.password.required}
                          type="modal-input"
                          placeholder={sftpOptions?.password}
                          missingField={isMissingField('password')}
                        />
                        <FormInput
                          label={getLabelValue(
                            'input_server_opt_virtual_remote_path_label',
                            labels
                          )}
                          onChange={newValue =>
                            handleOnChange(
                              newUserForm.virtual_remote_path.field,
                              newValue
                            )
                          }
                          value={newUserForm.virtual_remote_path.value}
                          valid={newUserForm.virtual_remote_path.valid}
                          required={newUserForm.virtual_remote_path.required}
                          type="modal-input"
                          placeholder={sftpOptions?.virtual_remote_path}
                          missingField={isMissingField('virtual_remote_path')}
                        />
                      </Col>
                    )}

                    {/* DAILYMOTION */}
                    {selectedPlatform === platforms.DM && (
                      <>
                        <Col sm={12} md={6} lg={6} className="pl-0 mb-3">
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_channel_id_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.dailymotion.field,
                                newValue
                              )
                            }
                            value={newUserForm.dailymotion.value}
                            valid={newUserForm.dailymotion.valid}
                            required={newUserForm.dailymotion.required}
                            type="modal-input"
                            placeholder={publisher.dailymotionChannelId}
                            missingField={isMissingField('dailymotion')}
                          />
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_client_id_DM_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.client_id_DM.field,
                                newValue
                              )
                            }
                            value={newUserForm.client_id_DM.value}
                            valid={newUserForm.client_id_DM.valid}
                            required={newUserForm.client_id_DM.required}
                            type="modal-input"
                            placeholder={publisher?.dailymotion?.client_id}
                            missingField={isMissingField('client_id_DM')}
                          />
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_client_secret_DM_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.client_secret_DM.field,
                                newValue
                              )
                            }
                            value={newUserForm.client_secret_DM.value}
                            valid={newUserForm.client_secret_DM.valid}
                            required={newUserForm.client_secret_DM.required}
                            type="modal-input"
                            placeholder={publisher?.dailymotion?.client_secret}
                            missingField={isMissingField('client_secret_DM')}
                          />
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_username_DM_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.username_DM.field,
                                newValue
                              )
                            }
                            value={newUserForm.username_DM.value}
                            valid={newUserForm.username_DM.valid}
                            required={newUserForm.username_DM.required}
                            type="modal-input"
                            placeholder={publisher?.dailymotion?.username}
                            missingField={isMissingField('username_DM')}
                          />
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_password_DM_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.password_DM.field,
                                newValue
                              )
                            }
                            value={newUserForm.password_DM.value}
                            valid={newUserForm.password_DM.valid}
                            required={newUserForm.password_DM.required}
                            type="form-password"
                            placeholder={publisher?.dailymotion?.password}
                            missingField={isMissingField('password_DM')}
                          />
                          <FeedContainer>
                            <EnableFeedWrapper checked={feedEnabledDM}>
                              <EnableFeedHeader>
                                <Roboto>
                                  {getLabelValue('feed_modal_title_section', labels)}
                                </Roboto>
                                <EnableFeedSwitchWrapper>
                                  <Label
                                    className="platform-enable--label dm"
                                    type="modal-input"
                                    checked={feedEnabled}
                                  >
                                    <Roboto
                                      configuration={{
                                        fontWeight: 500,
                                        fontSize: 12
                                      }}
                                    >
                                      {getLabelValue(
                                        'feed_modal_toggle',
                                        labels
                                      )}
                                    </Roboto>
                                  </Label>
                                  <Switch
                                    className="platform-enable--switch"
                                    onChange={onChangeFeedEnabledDM}
                                    checked={feedEnabledDM}
                                    disabled={feedSwitchDisabled}
                                  />
                                </EnableFeedSwitchWrapper>
                              </EnableFeedHeader>
                              <HeightWidthContainer>
                                <FeedHeightWidthInputsContainer>
                                  <FormInput
                                    label={getLabelValue(
                                      'feed_modal_input_height',
                                      labels
                                    )}
                                    onChange={newValue =>
                                      handleOnChange(
                                        newUserForm.feed_height_DM.field,
                                        newValue
                                      )
                                    }
                                    value={newUserForm.feed_height_DM.value}
                                    valid={newUserForm.feed_height_DM.valid}
                                    required={newUserForm.feed_height_DM.required}
                                    type="modal-input"
                                    widthHeightCheck={
                                      newUserForm.feed_height_DM.value < 150 &&
                                      newUserForm.feed_height_DM.value !== ''
                                    }
                                    missingField={isMissingField('feed_height_DM')}
                                  />
                                  <FormInput
                                    label={getLabelValue('feed_modal_input_width', labels)}
                                    onChange={newValue =>
                                      handleOnChange(
                                        newUserForm.feed_width_DM.field,
                                        newValue
                                      )
                                    }
                                    value={newUserForm.feed_width_DM.value}
                                    valid={newUserForm.feed_width_DM.valid}
                                    required={newUserForm.feed_width_DM.required}
                                    type="modal-input"
                                    widthHeightCheck={
                                      newUserForm.feed_width_DM.value < 150 &&
                                      newUserForm.feed_width_DM.value !== ''
                                    }
                                    missingField={isMissingField('feed_width_DM')}
                                  />
                                  <FormInput
                                    label={getLabelValue('feed_modal_input_total_video', labels)}
                                    onChange={newValue =>
                                      handleOnChange(
                                        newUserForm.feed_total_video_DM.field,
                                        newValue
                                      )
                                    }
                                    value={newUserForm.feed_total_video_DM.value}
                                    valid={newUserForm.feed_total_video_DM.valid}
                                    required={newUserForm.feed_total_video_DM.required}
                                    type="modal-input"
                                    missingField={isMissingField('feed_total_video_DM')}
                                  />
                                </FeedHeightWidthInputsContainer>
                              </HeightWidthContainer>
                              <HeightWidthContainer>
                                <FeedColorsInputsContainer>
                                  <ColorOption>
                                    <ColorTitle>
                                      <ChosenColor
                                        onClick={() => {
                                          setDisplayBGColorPickerDM(!displayBGColorPickerDM);
                                          setDisplayFontColorPickerDM(false);
                                        }}
                                        playerColor={backgroundColor}
                                      >
                                        <Roboto>
                                          {getLabelValue('feed_modal_background_color', labels)}
                                        </Roboto>
                                      </ChosenColor>
                                      {displayBGColorPickerDM && (
                                        <CloseColorPicker>
                                          <Icon
                                            type="icon-close"
                                            size={13}
                                            onClick={e =>
                                              handleOnCloseFeedColorPicker(e, 'dailymotion')
                                            }
                                            color="#4f9eff"
                                          />
                                        </CloseColorPicker>
                                      )}
                                    </ColorTitle>
                                    {displayBGColorPickerDM && (
                                      <SketchPickerWrapper>
                                        <SketchPicker
                                          color={backgroundColor}
                                          onChangeComplete={color =>
                                            setBackgroundColor(color.hex)
                                          }
                                          presetColors={[]}
                                          disableAlpha
                                        />
                                      </SketchPickerWrapper>
                                    )}
                                  </ColorOption>
                                  <ColorOption>
                                    <ColorTitle>
                                      <ChosenColor
                                        onClick={() => {
                                          setDisplayFontColorPickerDM(!displayFontColorPickerDM);
                                          setDisplayBGColorPickerDM(false);
                                        }}
                                        playerColor={fontColor}
                                      >
                                        <Roboto>
                                          {getLabelValue('feed_modal_font_color', labels)}
                                        </Roboto>
                                      </ChosenColor>
                                      {displayFontColorPickerDM && (
                                        <CloseColorPicker>
                                          <Icon
                                            type="icon-close"
                                            size={13}
                                            onClick={e =>
                                              handleOnCloseFeedColorPicker(e, 'dailymotion')
                                            }
                                            color="#4f9eff"
                                          />
                                        </CloseColorPicker>
                                      )}
                                    </ColorTitle>
                                    {displayFontColorPickerDM && (
                                      <SketchPickerWrapper>
                                        <SketchPicker
                                          color={fontColor}
                                          onChangeComplete={color =>
                                            setFontColor(color.hex)
                                          }
                                          presetColors={[]}
                                          disableAlpha
                                        />
                                      </SketchPickerWrapper>
                                    )}
                                  </ColorOption>
                                </FeedColorsInputsContainer>
                              </HeightWidthContainer>
                              <OriginsContainer>
                                <FormInput
                                  label={getLabelValue('feed_modal_input_origin', labels)}
                                  value={originText}
                                  required={newUserForm?.origin_DM?.required}
                                  onChange={newValue => handleOnChangeOrigins(newValue)}
                                  onKeyPress={e => handleOnKeyPressOrigins(e)}
                                  missingField={isMissingField('origin_DM')}
                                />
                                {originList.length > 0 && (
                                  <OriginsListContainer>
                                    {originList.map((kw, i) => (
                                      <OriginLabel key={`origin-${i}`}>
                                        {kw}
                                        <Icon
                                          type="icon-close"
                                          size={10}
                                          color={theme.colors.custom.lightText}
                                          onClick={() => handleOnClickDeleteOrigin(kw)}
                                        />
                                      </OriginLabel>
                                    ))}
                                  </OriginsListContainer>
                                )}
                              </OriginsContainer>
                            </EnableFeedWrapper>
                          </FeedContainer>
                        </Col>
                        <Col sm={12} md={6} lg={6} className="pl-0 mb-3">
                          <FormInput
                            label={getLabelValue(
                              'input_publisher_adv_code_label',
                              labels
                            )}
                            onChange={newValue =>
                              handleOnChange(
                                newUserForm.adv_code_DM.field,
                                newValue
                              )
                            }
                            value={newUserForm.adv_code_DM.value}
                            valid={newUserForm.adv_code_DM.valid}
                            required={newUserForm.adv_code_DM.required}
                            type="modal-input"
                            placeholder={publisher?.dailymotion?.channelId}
                            missingField={isMissingField('adv_code_DM')}
                          />
                          <Row>
                            <Col sm={12} md={6} lg={6} className="mb-3 mt-2">
                              <Label type="modal-input">
                                <Roboto>
                                  {getLabelValue(
                                    'toggle_enable_advertisment',
                                    labels
                                  )}
                                </Roboto>
                              </Label>
                              <Switch
                                onChange={onChangeEnadsDM}
                                checked={enadsDM}
                              />
                            </Col>
                            <Col sm={12} md={6} lg={6} className="mb-3 mt-2">
                              <Label type="modal-input">
                                <Roboto>
                                  {getLabelValue('toggle_pip', labels)}
                                </Roboto>
                              </Label>
                              <Switch
                                onChange={onChangePipDM}
                                checked={pipDM}
                              />
                            </Col>
                          </Row>
                          <PipCustContainer>
                            <Roboto>
                              {getLabelValue(
                                'input_publisher_pipcust_title_label',
                                labels
                              )}
                            </Roboto>
                            <Row className="mt-3">
                              <Col sm={12} md={12} lg={6} className="mb-3">
                                <FormInput
                                  label={getLabelValue(
                                    'select_pip_customization_desktop_label',
                                    labels
                                  )}
                                  selected={
                                    newUserForm?.pip_cust_desktop?.value
                                  }
                                  type="select"
                                  options={pipCustomizationDesktopOptions(
                                    language
                                  )}
                                  disabled={!pipDM}
                                  onChange={newValue =>
                                    handleOnChange(
                                      newUserForm?.pip_cust_desktop?.field,
                                      newValue
                                    )
                                  }
                                />
                              </Col>
                              <Col sm={12} md={12} lg={6} className="mb-3">
                                <FormInput
                                  label={getLabelValue(
                                    'select_pip_customization_mobile_label',
                                    labels
                                  )}
                                  selected={newUserForm?.pip_cust_mobile?.value}
                                  type="select"
                                  options={pipCustomizationMobileOptions(
                                    language
                                  )}
                                  disabled={!pipDM}
                                  onChange={newValue =>
                                    handleOnChange(
                                      newUserForm?.pip_cust_mobile?.field,
                                      newValue
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </PipCustContainer>
                          <HeightWidthContainer>
                            <InputsContainer>
                              <FormInput
                                label={getLabelValue(
                                  'input_height_label',
                                  labels
                                )}
                                onChange={newValue =>
                                  handleOnChange(
                                    newUserForm.height_DM.field,
                                    newValue
                                  )
                                }
                                value={newUserForm.height_DM.value}
                                valid={newUserForm.height_DM.valid}
                                type="modal-input"
                                widthHeightCheck={
                                  newUserForm.height_DM.value < 150 &&
                                  newUserForm.height_DM.value !== ''
                                }
                              />
                              <FormInput
                                label={getLabelValue(
                                  'input_width_label',
                                  labels
                                )}
                                onChange={newValue =>
                                  handleOnChange(
                                    newUserForm.width_DM.field,
                                    newValue
                                  )
                                }
                                value={newUserForm.width_DM.value}
                                valid={newUserForm.width_DM.valid}
                                type="modal-input"
                                widthHeightCheck={
                                  newUserForm.width_DM.value < 150 &&
                                  newUserForm.width_DM.value !== ''
                                }
                              />
                            </InputsContainer>
                            <Roboto boto type="widthHeightMessage">
                              {getLabelValue(
                                'input_widthheight_message_label',
                                labels
                              )}
                            </Roboto>
                          </HeightWidthContainer>
                          <Row>
                            <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                              <Label type="modal-input">
                                <Roboto>
                                  {getLabelValue('toggle_controls', labels)}
                                </Roboto>
                              </Label>
                              <Switch
                                onChange={onChangeControlsDM}
                                checked={controlsDM}
                              />
                            </Col>
                            <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                              <Label type="modal-input">
                                <Roboto>
                                  {getLabelValue('toggle_autoplay', labels)}
                                </Roboto>
                              </Label>
                              <Switch
                                onChange={onChangeAutoplayDM}
                                checked={autoplayDM}
                              />
                            </Col>
                            <Col sm={12} md={4} lg={4} className="mb-3 mt-2">
                              <Label type="modal-input">
                                <Roboto>
                                  {getLabelValue('toggle_mute', labels)}
                                </Roboto>
                              </Label>
                              <Switch
                                onChange={onChangeMuteDM}
                                checked={muteDM}
                              />
                            </Col>
                          </Row>
                          <ColorOption>
                            <ColorTitle>
                              <ChosenColor
                                onClick={() =>
                                  setDisplayThemeColorPickerDM(
                                    !displayThemeColorPickerDM
                                  )
                                }
                                playerColor={themeColorDM}
                              >
                                <Roboto>
                                  {getLabelValue('theme_color', labels)}
                                </Roboto>
                              </ChosenColor>
                              {displayThemeColorPickerDM && (
                                <CloseColorPicker>
                                  <Icon
                                    type="icon-close"
                                    size={13}
                                    onClick={e =>
                                      handleOnCloseColorPicker(e, 'dm')
                                    }
                                    color="#4f9eff"
                                  />
                                </CloseColorPicker>
                              )}
                            </ColorTitle>
                            {displayThemeColorPickerDM && (
                              <SketchPicker
                                color={themeColorDM}
                                onChangeComplete={color =>
                                  setThemeColorDM(color.hex)
                                }
                                presetColors={[]}
                                disableAlpha
                              />
                            )}
                          </ColorOption>
                        </Col>
                      </>
                    )}
                    <Col sm={12} md={12} lg={12} className="pl-0 mt-2">
                      <ButtonWrapper>
                        <Button
                          btnText={getLabelValue('btn_save', labels)}
                          width="50%"
                          btnType={Button.TYPE.PRIMARY}
                          onClick={() => {
                            checkSubmitDisabled()
                              ? showMissingFields()
                              : handleEditPublisher();
                          }}
                        />
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Container>
              </FormContainer>
            </ModalBody>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

EditPublisherModal.propTypes = {
  publisher: PropTypes.object,
  selectedPlatform: PropTypes.string,
  language: PropTypes.string,
  // HOC (connect, dispatch)
  editPublisher: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

EditPublisherModal.id = modalId;

export default connect(
  state => ({
    publishersList: state.admin.userList,
    defaultTemplateByPublisher: state.admin.defaultTemplateByPublisher,
    defaultTemplateFeed: state.feed.defaultTemplateFeed,
    userType: state.auth.user_data.type,
    publisher: state.modal.payload?.publisher,
    selectedPlatform: state.modal.payload?.selectedPlatform,
    sftpOptions: state.admin.sftpOptionsByPublisher,
    labels: state.app.selectedLabel,
    language: state.app.selectedLanguage,
    availablePlatformList: state.app.availablePlatformList
  }),
  dispatch => ({
    editPublisher: body => dispatch({ type: PUBLISHER_EDIT._REQUEST, body }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(EditPublisherModal);
