import app from './app';
import auth from './auth';
import modal from './modal';
import admin from './admin';
import search from './search';
import upload from './upload';
import feed from './feed';

export default {
  app,
  auth,
  modal,
  admin,
  search,
  upload,
  feed
};
