import React from 'react';
import styled from 'styled-components';

const VersionContainer = styled.div`
  position: fixed;
  bottom: 6px;
  right: 30px;
  opacity: 0.5;
`;

const Version = () => <VersionContainer>v1.2.1</VersionContainer>;

export default Version;
