import styled from 'styled-components';
import theme from '../../ui/theme';

export const VideoSettingsContainer = styled.div`
  height: inherit;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  margin-top: 30px;

  @media ${theme.device.mobileL} {
    margin-bottom: 40px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 30px;
  .react-switch-bg {
    margin-bottom: 20px !important;
  }
`;

export const Label = styled.span`
  margin-right: 3px;
`;

export const Value = styled.span``;

export const SelectWrapper = styled.div`
  display: flex;
  margin: 10px 0;

  > div:first-child {
    margin-right: 13px;
  }
`;

export const AddPublisherWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const BtnSave = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: #f2f2f2;
  background-color: ${theme.colors.custom.blue};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const PublishersList = styled.div``;

export const PublisherRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  @media ${theme.device.mobileL} {
    margin-top: 16px;
  }
`;

export const NamePublisher = styled.div`
  width: 34%;
`;
export const RolePublisher = styled.div`
  width: 33%;
  color: ${theme.colors.custom.blue};
  display: flex;
  justify-content: center;
`;

export const EditRoleWrapper = styled.div`
  margin-top: 32px;
`;

export const EditRoleTitle = styled.span`
  font-style: italic;
  color: ${theme.colors.custom.blue};
`;

export const ActionPublisher = styled.div`
  width: 33%;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    justify-content: flex-end;
  }
`;

export const BtnCancel = styled.div`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.custom.darkGray};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const BtnDelete = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.primary.red};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const ChannelLogo = styled.img``;
export const DeletePublisherAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const SwitchOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const RowSwitchOption = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SingleSwitchWrapper = styled.div`
  margin-right: 30px;
  width: 50%;

  @media ${theme.device.tablet} {
    margin: 0;
  }
`;

export const HeightWidthContainer = styled.div`
  margin-bottom: 25px;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  > div {
    position: relative;
    width: calc(50% - 10px);
    margin-bottom: 25px;

    @media ${theme.device.mobileL} {
      width: calc(50% - 2px);
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonPreviewWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  width: 170px;

  @media ${theme.device.tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;

    > button {
      border-radius: 0;
    }
  }
`;

export const ButtonFinishWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 25px;
  width: 170px;

  @media ${theme.device.tablet} {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50%;

    > button {
      border-radius: 0;
    }
  }
`;

export const PreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(68 68 68 / 72%);
  width: 100%;
  height: 100vh;
  z-index: ${theme.zIndex.modal};
`;

export const VideoPreviewContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;

  @media ${theme.device.mobileL} {
    max-width: 90%;
    max-height: 90%;
  }
`;

export const MaskPreview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(65,65,65,0.21);
  z-index: 9999;
`;

export const ColorOption = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${theme.device.tabletL} {
    justify-content: normal;
  }
  @media ${theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const ColorTitle = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media ${theme.device.tabletL} {
    margin-right: 30px;
  }
  @media ${theme.device.mobileL} {
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

export const ChosenColor = styled.div`
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    display: inline-block;
    background-color: ${props => props.playerColor};
    border: 1px solid #b3b3b3;
    height: 21px;
    width: 20px;
    margin-left: 10px;
    margin-top: -3px;
    border-radius: 50%;
  }
`;

export const CloseColorPicker = styled.div`
  cursor: pointer;
  background: #fff;
  padding: 3px 5px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  margin-left: 12px;
`;
