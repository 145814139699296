import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AdminDetailContainer } from './style';
import Arial from '../../ui/typography/arial';
import { getRandomNumber } from '../../utils/common';
import Roboto from '../../ui/typography/roboto';
import theme from '../../ui/theme';
import { AdminCta } from '..';
import { withMediaQueries } from '../../hoc/withMediaQueries';

const AdminUserDetail = ({
  label,
  value,
  isPublisher = false,
  type = 'labelCardUser',
  pubCount,
  role,
  isInfoRole,
  onClick,
  isClickable,
  mediaIsLaptop,
  mediaIsDesktop
}) => (
  <AdminDetailContainer
    isPublisher={isPublisher}
    isClickable={(mediaIsLaptop || mediaIsDesktop) && isClickable}
    onClick={() => {
      (mediaIsLaptop || mediaIsDesktop) && onClick();
    }}
  >
    <Roboto type={type} configuration={{ fontSize: 12 }}>
      {label}
    </Roboto>
    <Roboto
      type="medium"
      configuration={{
        fontSize: 12,
        color: label === 'Username' ? theme.colors.custom.blue : 'inherit'
      }}
    >
      {value}{' '}
      {role !== 'super_admin' && role !== 'admin' && isInfoRole && (
        <Roboto
          htmlAttribute="span"
          type="medium"
          configuration={{
            fontSize: 12,
            color: label === 'Username' ? theme.colors.custom.blue : 'inherit'
          }}
        >
          (<span style={{ color: '#4f9eff' }}>{pubCount} pub.</span>)
        </Roboto>
      )}
    </Roboto>
    {isClickable && !(mediaIsLaptop || mediaIsDesktop) && (
      <AdminCta
        btnType="edit"
        width="40px"
        height="35"
        size={15}
        onClick={onClick}
      />
    )}
  </AdminDetailContainer>
);

AdminUserDetail.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
  isPublisher: PropTypes.bool,
  // HOC (mediaQueries)
  mediaIsLaptop: PropTypes.bool,
  mediaIsDesktop: PropTypes.bool
};

export default withMediaQueries(AdminUserDetail);
