// samples
export const FontBase = 14;
export const NavBarHeight = 70;
export const NavBarHeightMobile = 50;
export const NavBarHeightTablet = 50;
export const FooterHeight = 0;
export const FooterHeightMobile = 0;

// new (to keep & maintain)
export const MenuDesktopWidth = 70;
export const MenuTabletWidth = 100;
export const MenuMobileWidth = 70;

// Recommendation Menu
export const MenuDesktopRecommendationWidth = 311;
export const MenuTabletRecommendationWidth = 101;
export const MenuMobileRecommendationWidth = 240;

export const SearchListHeightDesktop = 240;
export const SearchListHeightTablet = 315;
export const SearchListHeightMobile = 185;

export const HeaderInteractiveDesktopHeight = 40;
export const HeaderInteractiveDesktopTopSpace = 60;
export const HeaderInteractiveTabletTopSpace = 60;
export const HeaderInteractiveMobileHeight = 40;

export const PageContentDesktopTopSpace = 70;
export const PageContentTabletTopSpace = 70;

export const StatisticsMenuContainerDesktopHeight = 83;

// eslint-disable-next-line no-unused-vars
const APP_DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV
  ? process.env.REACT_APP_DEPLOY_ENV.trim()
  : process.env.REACT_APP_DEPLOY_ENV;
