import styled from 'styled-components';
import theme from '../../ui/theme';

export const AdminContainer = styled.div`
  height: inherit;
`;

export const TabsContainer = styled.div`
  width: 200px;
`;

export const UsersWrapper = styled.div`
  display: flex;
`;
export const CtaWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
  
  @media ${theme.device.tabletL} {
    flex-direction: column;
    height: 100px;
    align-items: flex-start;
  }
`;

export const PlatformTabsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;
