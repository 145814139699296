import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Loader, Menu
} from '../index';
import { PageContainer, PageContent } from './styles';
import WarningModal from '../Modals/WarningModal';
import { AUTH_LOGOUT } from '../../redux/actions/auth';
import { withMediaQueries } from '../../hoc/withMediaQueries';

const GlobalLayout = ({
  children
}) => {
  const [vh, setVh] = useState(`${window.innerHeight * 0.01}px`);

  window.addEventListener('resize', () => {
    const newVh = `${window.innerHeight * 0.01}px`;
    setVh(newVh);
  });

  return (
    <>
      <Loader />
      <Menu />
      <PageContainer
        vh={vh}
      >
        <PageContent
          className="page-content"
        >
          {children}
        </PageContent>
      </PageContainer>
      <WarningModal />
    </>
  );
};

GlobalLayout.propTypes = {
  children: PropTypes.node
};

export default connect(
  state => {
  },
  dispatch => ({
    logout: () => dispatch({ type: AUTH_LOGOUT._REQUEST })
  })
)(withMediaQueries(GlobalLayout));
