/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import App from './App';

const IdleTimer = () => {
  const [isInactive, setIsInactive] = useState(false);
  const handleOnIdle = () => {
    setIsInactive(true);
  };

  const handleOnActive = () => {
    setIsInactive(false);
  };

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 5000
  });

  return (
    <>
      <App isInactive={isInactive} />
    </>
  );
};

export default IdleTimer;
