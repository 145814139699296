import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { SketchPicker } from 'react-color';
import Switch from 'react-switch';
import Roboto from '../../ui/typography/roboto';
import {
  ButtonFinishWrapper,
  ButtonPreviewWrapper,
  ButtonsWrapper,
  ChosenColor,
  ColorOption,
  FormContainer,
  RowSwitchOption,
  SingleSwitchWrapper,
  SwitchOptionsWrapper,
  VideoContainer,
  VideoSettingsContainer,
  MaskPreview,
  CloseColorPicker,
  ColorTitle,
  HeightWidthContainer,
  InputsContainer
} from './style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import Button from '../../atoms/Button';
import { FormInput, GoBack, Icon } from '../../atoms';
import {
  GET_HTML_BUILD_DM,
  GET_HTML_BUILD_YT,
  GET_YOUTUBE_SEARCH_RESULTS
} from '../../redux/actions/search';
import { GET_DEFAULT_TEMPLATE_BY_PUBLISHER } from '../../redux/actions/admin';
import { Col, Container, Row } from '../../ui/gridSystem';
import { isValidForm, newForm, newFormField } from '../../utils/form';
import { RegexpType, validator } from '../../utils/validator';
import { Label } from '../../atoms/FormInput/style';
import { MODAL_OPEN } from '../../redux/actions';
import HtmlBuildModal from '../../components/Modals/HtmlBuildModal';
import {
  getLabelValue,
  pipCustLabelSwitch,
  pipCustomizationDesktopOptions,
  pipCustomizationMobileOptions
} from '../../utils/common';
import theme from '../../ui/theme';
import { PipCustContainer } from '../../components/Modals/EditPublisherModal/style';
import { NoResultMessage, NoResultWrapper } from '../SearchVideo/style';

const initialForm = newForm([
  newFormField({
    field: 'enads',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'pip',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'pip_cust_desktop',
    required: true,
    type: RegexpType.STRING,
    value: { label: 'Default', value: 'default' },
    valid: true
  }),
  newFormField({
    field: 'pip_cust_mobile',
    required: true,
    type: RegexpType.STRING,
    value: { label: 'Default', value: 'default' },
    valid: true
  }),
  newFormField({
    field: 'controls',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'autoplay',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({
    field: 'mute',
    required: false,
    type: RegexpType.NUMBER
  }),
  newFormField({ field: 'heightYB', required: false, type: RegexpType.NUMBER }),
  newFormField({ field: 'widthYB', required: false, type: RegexpType.NUMBER }),
  newFormField({ field: 'heightDM', required: true, type: RegexpType.NUMBER }),
  newFormField({ field: 'widthDM', required: true, type: RegexpType.NUMBER })
]);

const error = message => (
  <span style={{ background: 'red', color: 'white', padding: '5px 10px' }}>
    {message}
  </span>
);

const VideoSettings = ({
  videoId,
  ybListResults,
  getDefaultTemplateByPublisher,
  selectedPublisher,
  defaultTemplateByPublisher,
  dmListResults,
  platform,
  getHtmlBuildDM,
  openHtmlBuildModal,
  getHtmlBuildYT,
  htmlBuildYT,
  htmlBuildDM,
  labels,
  language
}) => {
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [newSettingsForm, setNewSettingsForm] = useState(initialForm);
  // eslint-disable-next-line no-undef
  const [themeColor, setThemeColor] = useState(
    platform === 'yt'
      ? theme.colors.custom.youtube
      : theme.colors.custom.dailymotion
  );
  const [displayThemeColorPicker, setDisplayThemeColorPicker] = useState(false);
  const [videoSelected, setVideoSelected] = useState({});
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const list = platform === 'yt' ? ybListResults : dmListResults;
    const video = list.filter(vid => vid.videoId === videoId)[0];
    if (video) {
      localStorage.setItem('videoSelected', JSON.stringify(video));
      setVideoSelected(JSON.parse(localStorage.getItem('videoSelected')));
    } else {
      setVideoSelected(JSON.parse(localStorage.getItem('videoSelected')));
    }
  }, [videoId, ybListResults, dmListResults]);

  useEffect(() => {
    setDefaultTemplate(defaultTemplateByPublisher);
  }, [defaultTemplateByPublisher, selectedPublisher]);

  useEffect(() => {
    const defaultThemeColor =
      platform === 'yt'
        ? defaultTemplate?.youtube?.videoConfigs?.embedConfig?.primaryThemeColor
        : `#${defaultTemplate?.dailymotion?.params?.['ui-highlight']}`;

    setThemeColor(defaultThemeColor);
  }, [defaultTemplate]);

  useEffect(() => {
    if (selectedPublisher.name)
      getDefaultTemplateByPublisher(selectedPublisher.name);
  }, [getDefaultTemplateByPublisher, selectedPublisher.name]);

  useEffect(() => {
    setNewSettingsForm(
      newForm([
        newFormField({
          field: 'enads',
          required: false,
          type: RegexpType.STRING,
          valid: true,
          value:
            platform === 'yt'
              ? defaultTemplate?.youtube?.enads
              : defaultTemplate?.dailymotion?.enads
        }),
        newFormField({
          field: 'pip',
          required: false,
          type: RegexpType.STRING,
          valid: true,
          value:
            platform === 'yt'
              ? defaultTemplate?.youtube?.pip
              : defaultTemplate?.dailymotion?.pip
        }),
        newFormField({
          field: 'pip_cust_desktop',
          required: true,
          type: RegexpType.STRING,
          value: {
            label: pipCustLabelSwitch(
              language,
              'desktop',
              defaultTemplateByPublisher?.dailymotion?.pip_customization
                ?.desktop || 'Default'
            ),
            value:
              defaultTemplateByPublisher?.dailymotion?.pip_customization
                ?.desktop || 'default'
          },
          valid: true
        }),
        newFormField({
          field: 'pip_cust_mobile',
          required: true,
          type: RegexpType.STRING,
          value: {
            label: pipCustLabelSwitch(
              language,
              'mobile',
              defaultTemplateByPublisher?.dailymotion?.pip_customization
                ?.mobile || 'Default'
            ),
            value:
              defaultTemplateByPublisher?.dailymotion?.pip_customization
                ?.mobile || 'default'
          },
          valid: true
        }),
        newFormField({
          field: 'controls',
          required: false,
          type: RegexpType.NUMBER,
          valid: true,
          value:
            platform === 'yt'
              ? defaultTemplate?.youtube?.videoConfigs?.playerVars?.controls
              : defaultTemplate?.dailymotion?.params?.controls
        }),
        newFormField({
          field: 'autoplay',
          required: false,
          type: RegexpType.STRING,
          valid: true,
          value:
            platform === 'yt'
              ? defaultTemplate?.youtube?.videoConfigs?.playerVars?.autoplay
              : defaultTemplate?.dailymotion?.params?.autoplay
        }),
        newFormField({
          field: 'mute',
          required: false,
          type: RegexpType.NUMBER,
          valid: true,
          value:
            platform === 'yt'
              ? defaultTemplate?.youtube?.videoConfigs?.playerVars?.mute
              : defaultTemplate?.dailymotion?.params?.mute
        }),
        newFormField({
          field: 'heightYB',
          required: true,
          type: RegexpType.NUMBER,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.videoConfigs?.height,
            true
          ),
          value: defaultTemplate?.youtube?.videoConfigs?.height
        }),
        newFormField({
          field: 'widthYB',
          required: true,
          type: RegexpType.NUMBER,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.videoConfigs?.width,
            true
          ),
          value: defaultTemplate?.youtube?.videoConfigs?.width
        }),
        newFormField({
          field: 'heightDM',
          required: false,
          type: RegexpType.NUMBER,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.videoConfigs?.height,
            true
          ),
          value:
            defaultTemplate?.dailymotion?.height === 0
              ? ''
              : defaultTemplate?.dailymotion?.height
        }),
        newFormField({
          field: 'widthDM',
          required: false,
          type: RegexpType.NUMBER,
          valid: validator(
            RegexpType.NUMBER,
            defaultTemplate?.youtube?.videoConfigs?.width,
            true
          ),
          value:
            defaultTemplate?.dailymotion?.width === 0
              ? ''
              : defaultTemplate?.dailymotion?.width
        })
      ])
    );
  }, [defaultTemplate]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newSettingsForm[field];

    setNewSettingsForm({
      ...newSettingsForm,
      [field]: {
        ...newSettingsForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  const [mute, setMute] = useState(
    platform === 'yt'
      ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.mute)
      : Boolean(defaultTemplate?.dailymotion?.params?.mute)
  );
  const onChangeMute = newValue => {
    handleOnChange('mute', newValue ? 1 : 0);
    setMute(newValue);
  };

  const [enads, setEnads] = useState(
    platform === 'yt'
      ? Boolean(defaultTemplate?.youtube?.enads)
      : Boolean(defaultTemplate?.dailymotion?.enads)
  );
  const onChangeEnads = newValue => {
    handleOnChange('enads', newValue);
    setEnads(newValue);
  };

  const [pip, setPip] = useState(
    platform === 'yt'
      ? Boolean(defaultTemplate?.youtube?.pip)
      : Boolean(defaultTemplate?.dailymotion?.pip)
  );
  const onChangePip = newValue => {
    handleOnChange('pip', newValue);
    setPip(newValue);
  };

  const [controls, setControls] = useState(
    platform === 'yt'
      ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.controls)
      : Boolean(defaultTemplate?.dailymotion?.params?.controls)
  );
  const onChangeControls = newValue => {
    handleOnChange('controls', newValue ? 1 : 0);
    setControls(newValue);
  };

  const [autoplay, setAutoplay] = useState(
    platform === 'yt'
      ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.autoplay)
      : Boolean(defaultTemplate?.dailymotion?.params?.autoplay)
  );
  const onChangeAutplay = newValue => {
    handleOnChange('autoplay', newValue);
    setAutoplay(newValue);
  };

  useEffect(() => {
    setMute(
      platform === 'yt'
        ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.mute)
        : Boolean(defaultTemplate?.dailymotion?.params?.mute)
    );
    setEnads(
      platform === 'yt'
        ? Boolean(defaultTemplate?.youtube?.enads)
        : Boolean(defaultTemplate?.dailymotion?.enads)
    );
    setPip(
      platform === 'yt'
        ? Boolean(defaultTemplate?.youtube?.pip)
        : Boolean(defaultTemplate?.dailymotion?.pip)
    );
    setControls(
      platform === 'yt'
        ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.controls)
        : Boolean(defaultTemplate?.dailymotion?.params?.controls)
    );
    setAutoplay(
      platform === 'yt'
        ? Boolean(defaultTemplate?.youtube?.videoConfigs?.playerVars?.autoplay)
        : Boolean(defaultTemplate?.dailymotion?.params?.autoplay)
    );
  }, [defaultTemplate]);

  const checkSubmitDisabled = () => {
    let checkValue = false;
    Object.keys(newSettingsForm).forEach(key => {
      if (newSettingsForm[key]?.value) {
        checkValue = true;
      }
    });

    if (!newSettingsForm.widthDM.value || !newSettingsForm.heightDM.value) {
      checkValue = false;
    }

    if (!newSettingsForm.widthDM.value && !newSettingsForm.heightDM.value) {
      checkValue = true;
    }

    if (
      (Number(newSettingsForm.widthDM.value) < 150 &&
        Number(newSettingsForm.widthDM.value) !== 0) ||
      (Number(newSettingsForm.heightDM.value) < 150 &&
        Number(newSettingsForm.heightDM.value) !== 0)
    ) {
      checkValue = false;
    }

    if (
      (Number(newSettingsForm.widthYB.value) < 150 &&
        Number(newSettingsForm.widthYB.value) !== 0) ||
      (Number(newSettingsForm.heightYB.value) < 150 &&
        Number(newSettingsForm.heightYB.value) !== 0)
    ) {
      checkValue = false;
    }

    return !(
      (
        isValidForm(newSettingsForm) && checkValue
      ) /*&& Boolean(channelTypeSelected)*/
    );
  };

  const handleClickFinish = () => {
    let body = {};
    if (platform === 'yt') {
      body = {
        preview: false,
        publisher: selectedPublisher?.name,
        enads: newSettingsForm.enads.value,
        pip: newSettingsForm.pip.value,
        videoConfigs: {
          height: Number(newSettingsForm.heightYB.value),
          width: Number(newSettingsForm.widthYB.value),
          playerVars: {
            controls: Number(newSettingsForm.controls.value),
            autoplay: Boolean(newSettingsForm.autoplay.value),
            mute: Number(newSettingsForm.mute.value)
          },
          embedConfig: {
            primaryThemeColor: themeColor
          }
        }
      };
      getHtmlBuildYT({ videoId, body });
    } else {
      const themeColorDMreplace = themeColor.replace('#', '');
      body = {
        publisher: selectedPublisher?.name,
        preview: false,
        enads: newSettingsForm.enads.value,
        pip: newSettingsForm.pip.value,
        pip_customization: {
          desktop: newSettingsForm.pip_cust_desktop.value.value,
          mobile: newSettingsForm.pip_cust_mobile.value.value
        },
        height: Number(newSettingsForm.heightDM.value),
        width: Number(newSettingsForm.widthDM.value),
        params: {
          autoplay: Boolean(newSettingsForm.autoplay.value),
          mute: Boolean(newSettingsForm.mute.value),
          controls: Number(newSettingsForm.controls.value),
          'ui-highlight': themeColorDMreplace
        }
      };
      getHtmlBuildDM({ videoId, body });
    }
    openHtmlBuildModal();
  };

  const handleOnClosePreview = e => {
    e.preventDefault();
    setShowPreview(false);
    //window.location.reload(); // reload
  };

  const handleOnCloseColorPicker = e => {
    e.preventDefault();
    setDisplayThemeColorPicker(false);
    //window.location.reload(); // reload
  };

  const handleOpenPreview = () => {
    let body = {};
    if (platform === 'yt') {
      body = {
        preview: true,
        publisher: selectedPublisher?.name,
        enads: newSettingsForm.enads.value,
        pip: newSettingsForm.pip.value,
        videoConfigs: {
          height: Number(newSettingsForm.heightYB.value),
          width: Number(newSettingsForm.widthYB.value),
          playerVars: {
            controls: newSettingsForm.controls.value,
            autoplay: newSettingsForm.autoplay.value,
            mute: newSettingsForm.mute.value
          },
          embedConfig: {
            primaryThemeColor: themeColor
          }
        }
      };
      getHtmlBuildYT({ videoId, body });
    } else {
      const themeColorDMreplace = themeColor.replace('#', '');
      body = {
        publisher: selectedPublisher?.name,
        preview: true,
        enads: newSettingsForm.enads.value,
        pip: newSettingsForm.pip.value,
        pip_customization: {
          desktop: newSettingsForm.pip_cust_desktop.value.value,
          mobile: newSettingsForm.pip_cust_mobile.value.value
        },
        height: Number(newSettingsForm.heightDM.value),
        width: Number(newSettingsForm.widthDM.value),
        params: {
          autoplay: Boolean(newSettingsForm.autoplay.value),
          mute: Boolean(newSettingsForm.mute.value),
          controls: Number(newSettingsForm.controls.value),
          'ui-highlight': themeColorDMreplace
        }
      };

      getHtmlBuildDM({ videoId, body });
    }
    setShowPreview(true);
  };

  const setInnerHTML = (ele, html) => {
    const frag = document.createRange().createContextualFragment(html);
    ele.innerHTML = '';
    ele.appendChild(frag);
    const codes = ele?.getElementsByTagName('script');
    for (let i = 0; i < codes?.length; i += 1) {
      // eslint-disable-next-line no-eval
      eval(codes[i]?.text);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    const playerPreview = document.querySelector('.player_preview');
    const buildDM = JSON.stringify(htmlBuildDM);
    const htmlBuild = platform === 'yt' ? htmlBuildYT : buildDM;
    if (showPreview && htmlBuild) {
      body.classList.add('posRelative');
      setInnerHTML(playerPreview, `${htmlBuild}`);
    } else {
      body.classList.remove('posRelative');
      playerPreview.innerHTML = '';
    }
  }, [showPreview, htmlBuildYT, htmlBuildDM]);

  useEffect(() => {
    if (!pip) {
      const defaultOpt = {
        label: 'Default',
        value: 'default'
      };
      setNewSettingsForm({
        ...newSettingsForm,
        pip_cust_desktop: {
          ...newSettingsForm.pip_cust_desktop,
          value: defaultOpt
        },
        pip_cust_mobile: {
          ...newSettingsForm.pip_cust_mobile,
          value: defaultOpt
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pip]);

  useEffect(() => {
    //update selected customPip option when language changes
    const currentValueDesktop = newSettingsForm.pip_cust_desktop?.value?.value;
    const currentValueMobile = newSettingsForm.pip_cust_mobile?.value?.value;
    const newValueDesktop = pipCustomizationDesktopOptions(language).find(
      opt => opt?.value === currentValueDesktop
    );
    const newValueMobile = pipCustomizationMobileOptions(language).find(
      opt => opt?.value === currentValueMobile
    );
    const translatedNewSettingsForm = { ...newSettingsForm };
    translatedNewSettingsForm.pip_cust_desktop.value = newValueDesktop;
    translatedNewSettingsForm.pip_cust_mobile.value = newValueMobile;
    setNewSettingsForm(translatedNewSettingsForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <>
      {selectedPublisher &&
      selectedPublisher[platform === 'yt' ? 'youtube' : 'dailymotion']
        ?.platform_enabled ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <VideoSettingsContainer>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={12} className="pl-0">
                  <GoBack text={videoSelected?.title} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6} className="pl-0">
                  <VideoContainer>
                    <ReactPlayer
                      url={
                        platform === 'dm'
                          ? `https://www.dailymotion.com/video/${videoId}`
                          : `https://www.youtube.com/watch?v=${videoId}`
                      }
                      width="100%"
                      height="100%"
                      controls
                    />
                  </VideoContainer>
                </Col>
                <Col sm={12} md={12} lg={5} className="pl-0 ml-auto">
                  <FormContainer>
                    <SwitchOptionsWrapper>
                      <RowSwitchOption>
                        <SingleSwitchWrapper>
                          <Label type="modal-input">
                            <Roboto>
                              {getLabelValue(
                                'toggle_enable_advertisment',
                                labels
                              )}
                            </Roboto>
                          </Label>
                          <Switch onChange={onChangeEnads} checked={enads} />
                        </SingleSwitchWrapper>
                        <SingleSwitchWrapper>
                          <Label type="modal-input">
                            <Roboto>
                              {getLabelValue('toggle_pip', labels)}
                            </Roboto>
                          </Label>
                          <Switch onChange={onChangePip} checked={pip} />
                        </SingleSwitchWrapper>
                      </RowSwitchOption>
                      {platform === 'dm' && (
                        <PipCustContainer>
                          <Roboto>
                            {getLabelValue(
                              'input_publisher_pipcust_title_label',
                              labels
                            )}
                          </Roboto>
                          <Row className="mt-3">
                            <Col sm={12} md={12} lg={6} className="mb-3">
                              <FormInput
                                label={getLabelValue(
                                  'select_pip_customization_desktop_label',
                                  labels
                                )}
                                selected={
                                  newSettingsForm?.pip_cust_desktop?.value
                                }
                                type="select"
                                options={pipCustomizationDesktopOptions(
                                  language
                                )}
                                disabled={!pip}
                                onChange={newValue =>
                                  handleOnChange(
                                    newSettingsForm?.pip_cust_desktop?.field,
                                    newValue
                                  )
                                }
                              />
                            </Col>
                            <Col sm={12} md={12} lg={6} className="mb-3">
                              <FormInput
                                label={getLabelValue(
                                  'select_pip_customization_mobile_label',
                                  labels
                                )}
                                selected={
                                  newSettingsForm?.pip_cust_mobile?.value
                                }
                                type="select"
                                options={pipCustomizationMobileOptions(
                                  language
                                )}
                                disabled={!pip}
                                onChange={newValue =>
                                  handleOnChange(
                                    newSettingsForm?.pip_cust_mobile?.field,
                                    newValue
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </PipCustContainer>
                      )}
                      <RowSwitchOption>
                        <SingleSwitchWrapper>
                          <Label type="modal-input">
                            <Roboto>
                              {getLabelValue('toggle_controls', labels)}
                            </Roboto>
                          </Label>
                          <Switch
                            onChange={onChangeControls}
                            checked={controls}
                          />
                        </SingleSwitchWrapper>
                        <SingleSwitchWrapper>
                          <Label type="modal-input">
                            <Roboto>
                              {getLabelValue('toggle_autoplay', labels)}
                            </Roboto>
                          </Label>
                          <Switch
                            onChange={onChangeAutplay}
                            checked={autoplay}
                          />
                        </SingleSwitchWrapper>
                        <SingleSwitchWrapper>
                          <Label type="modal-input">
                            <Roboto>
                              {getLabelValue('toggle_mute', labels)}
                            </Roboto>
                          </Label>
                          <Switch onChange={onChangeMute} checked={mute} />
                        </SingleSwitchWrapper>
                      </RowSwitchOption>
                    </SwitchOptionsWrapper>
                    <HeightWidthContainer>
                      <InputsContainer>
                        <FormInput
                          label={getLabelValue('input_height_label', labels)}
                          onChange={newValue =>
                            handleOnChange(
                              platform === 'yt'
                                ? newSettingsForm.heightYB.field
                                : newSettingsForm.heightDM.field,
                              newValue
                            )
                          }
                          value={
                            platform === 'yt'
                              ? newSettingsForm.heightYB.value
                              : newSettingsForm.heightDM.value
                          }
                          valid={
                            platform === 'yt'
                              ? newSettingsForm.heightYB.valid
                              : newSettingsForm.heightDM.valid
                          }
                          type="modal-input"
                          widthHeightCheck={
                            (platform === 'yt' &&
                              newSettingsForm.heightYB.value < 150 &&
                              newSettingsForm.heightYB.value !== '') ||
                            (platform === 'dm' &&
                              newSettingsForm.heightDM.value < 150 &&
                              newSettingsForm.heightDM.value !== '')
                          }
                        />
                        <FormInput
                          label={getLabelValue('input_width_label', labels)}
                          onChange={newValue =>
                            handleOnChange(
                              platform === 'yt'
                                ? newSettingsForm.widthYB.field
                                : newSettingsForm.widthDM.field,
                              newValue
                            )
                          }
                          value={
                            platform === 'yt'
                              ? newSettingsForm.widthYB.value
                              : newSettingsForm.widthDM.value
                          }
                          valid={
                            platform === 'yt'
                              ? newSettingsForm.widthYB.valid
                              : newSettingsForm.widthDM.valid
                          }
                          type="modal-input"
                          widthHeightCheck={
                            (platform === 'yt' &&
                              newSettingsForm.widthYB.value < 150 &&
                              newSettingsForm.widthYB.value !== '') ||
                            (platform === 'dm' &&
                              newSettingsForm.widthDM.value < 150 &&
                              newSettingsForm.widthDM.value !== '')
                          }
                        />
                      </InputsContainer>
                      {platform === 'dm' && (
                        <Roboto boto type="widthHeightMessage">
                          {getLabelValue(
                            'input_widthheight_message_label',
                            labels
                          )}
                        </Roboto>
                      )}
                    </HeightWidthContainer>
                    <ColorOption>
                      <ColorTitle>
                        <ChosenColor
                          onClick={() =>
                            setDisplayThemeColorPicker(!displayThemeColorPicker)
                          }
                          playerColor={themeColor}
                        >
                          <Roboto>
                            {getLabelValue('theme_color', labels)}
                          </Roboto>
                        </ChosenColor>
                        {displayThemeColorPicker && (
                          <CloseColorPicker>
                            <Icon
                              type="icon-close"
                              size={13}
                              onClick={handleOnCloseColorPicker}
                              color="#4f9eff"
                            />
                          </CloseColorPicker>
                        )}
                      </ColorTitle>
                      {displayThemeColorPicker && (
                        <SketchPicker
                          color={themeColor}
                          onChangeComplete={color => setThemeColor(color.hex)}
                          presetColors={[]}
                        />
                      )}
                    </ColorOption>
                  </FormContainer>
                </Col>
              </Row>
              <ButtonsWrapper>
                <ButtonPreviewWrapper>
                  <Button
                    btnText={getLabelValue('btn_preview', labels)}
                    width="100%"
                    btnType={Button.TYPE.SECONDARY}
                    disabled={checkSubmitDisabled()}
                    onClick={handleOpenPreview}
                  />
                </ButtonPreviewWrapper>
                <ButtonFinishWrapper>
                  <Button
                    btnText={getLabelValue('btn_create', labels)}
                    width="100%"
                    btnType={Button.TYPE.PRIMARY}
                    disabled={checkSubmitDisabled()}
                    onClick={handleClickFinish}
                  />
                </ButtonFinishWrapper>
              </ButtonsWrapper>
            </Container>
          </VideoSettingsContainer>
          {showPreview && <MaskPreview onClick={handleOnClosePreview} />}
          <HtmlBuildModal platform={platform} />
        </>
      ) : (
        <NoResultWrapper>
          <NoResultMessage>
            {getLabelValue('message_platform_disabled', labels)}
          </NoResultMessage>
        </NoResultWrapper>
      )}
    </>
  );
};

VideoSettings.propTypes = {
  language: PropTypes.string
};

export default connect(
  state => ({
    ybListResults: state.search.youtubeSearchResults,
    dmListResults: state.search.dailymotionSearchResults,
    selectedPublisher: state.app.selectedPublisher,
    htmlBuildYT: state.search.htmlBuildYT,
    htmlBuildDM: state.search.htmlBuildDM,
    defaultTemplateByPublisher: state.admin.defaultTemplateByPublisher,
    labels: state.app.selectedLabel,
    language: state.app.selectedLanguage
  }),
  dispatch => ({
    getDefaultTemplateByPublisher: publisher =>
      dispatch({ type: GET_DEFAULT_TEMPLATE_BY_PUBLISHER._REQUEST, publisher }),
    openHtmlBuildModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'HtmlBuildModal' }),
    getHtmlBuildYT: payload =>
      dispatch({ type: GET_HTML_BUILD_YT._REQUEST, payload }),
    getHtmlBuildDM: payload =>
      dispatch({ type: GET_HTML_BUILD_DM._REQUEST, payload })
  })
)(withMediaQueries(VideoSettings));
