import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  UploadVideoContainer,
  FormContainer,
  FormItem,
  KeywordsContainer,
  KeywordLabel,
  CalendarWrapper,
  PlatformContainer
} from './style';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import Roboto from '../../ui/typography/roboto';
import {
  ButtonContainer,
  ButtonWrapper,
  NoResultMessage,
  NoResultWrapper
} from '../SearchVideo/style';
import Youtube from '../../ui/assets/img/youtube-logo.png';
import Dailymotion from '../../ui/assets/img/dailymotion-logo.svg';
import AllPlatforms from '../../ui/assets/img/all-platforms.png';
import Button from '../../atoms/Button';
import FormInput from '../../atoms/FormInput';
import { isValidForm } from '../../utils/form';
import { RegexpType, validator } from '../../utils/validator';
import { Label } from '../../atoms/FormInput/style';
import Checkbox from '../../atoms/Checkbox';
import {
  GET_YT_PLAYLIST_PUBLISHER,
  UPLOAD_VIDEO
} from '../../redux/actions/upload';
import theme from '../../ui/theme';
import { Icon } from '../../atoms';
import { Col, Container, Row } from '../../ui/gridSystem';
import { getLabelValue } from '../../utils/common';

const UploadVideo = ({
  uploadForm,
  selectedPublisher,
  uploadVideo,
  labels,
  getYtPlaylistPublisher,
  ytPlaylist
}) => {
  const [youtubeForm, setYoutubeForm] = useState(uploadForm);
  const [platform, setPlatform] = useState(
    selectedPublisher?.youtube?.platform_enabled &&
      selectedPublisher?.dailymotion?.platform_enabled
      ? 'youtube_dailymotion'
      : selectedPublisher?.youtube?.platform_enabled
      ? 'youtube'
      : 'dailymotion'
  );
  const [publisherSelected, setPublisherSelected] = useState([]);
  const [notifySubscriber, setNotifySubscriber] = useState(false);

  useEffect(() => {
    setPlatform(
      selectedPublisher?.youtube?.platform_enabled &&
        selectedPublisher?.dailymotion?.platform_enabled
        ? 'youtube_dailymotion'
        : selectedPublisher?.youtube?.platform_enabled
        ? 'youtube'
        : 'dailymotion'
    );
  }, [selectedPublisher]);

  const [disableStarttime, setDisableStarttime] = useState(true);
  // PLAYLIST YOUTUBE
  const [youtubePlaylist, setYoutubePlaylist] = useState([]);

  // KEYWORDS
  const [keywordText, setKeywordText] = useState('');
  const [keywordList, setKeywordList] = useState([]);
  const [keywordListForm, setKeywordListForm] = useState('');

  // MISSING FIELDS
  const [missingFields, setMissingFields] = useState([]);

  useEffect(() => {
    setYoutubeForm(youtubeForm);
  }, [uploadForm, youtubeForm]);

  useEffect(() => {
    if (
      youtubeForm.privacy.value.value === 'private' &&
      platform === 'dailymotion'
    ) {
      setYoutubeForm({
        ...youtubeForm,
        end_time: {
          field: 'end_time',
          required: false,
          touched: true,
          type: 'string',
          valid: true,
          value: ''
        },
        start_time: {
          field: 'start_time',
          required: false,
          touched: true,
          type: 'string',
          valid: true,
          value: ''
        }
      });
    } else {
      setYoutubeForm({
        ...youtubeForm,
        end_time: {
          field: 'end_time',
          required: true,
          type: RegexpType.STRING,
          value: youtubeForm?.end_time?.value
        },
        start_time: {
          field: 'start_time',
          required: true,
          valid: true,
          value: moment(new Date()).format(),
          type: RegexpType.STRING
        }
      });
    }
  }, [youtubeForm.privacy.value.value]);

  useEffect(() => {
    setKeywordList([]);
    setKeywordListForm('');
    setKeywordText('');
    const defaultForm = { ...uploadForm };
    if (platform !== 'dailymotion') {
      defaultForm.publisher.value = selectedPublisher.name;
      defaultForm.publisher.valid = true;
      setYoutubeForm(defaultForm);
    } else {
      defaultForm.publisher.value = selectedPublisher.name;
      defaultForm.publisher.valid = true;
      delete defaultForm.name;
      delete defaultForm.add_asset_labels;
      delete defaultForm.playlist_id;
      delete defaultForm.notify_subscribers;
      setYoutubeForm(defaultForm);
    }
    setMissingFields([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    const playlist = ytPlaylist.reduce(
      (acc, pl) => [...acc, { label: pl.title, value: pl.playlistId }],
      []
    );
    setYoutubePlaylist(playlist);
  }, [ytPlaylist]);

  const handleOnChangeYoutubeForm = (field = '', newValue) => {
    const { type, required } = youtubeForm[field];
    setYoutubeForm({
      ...youtubeForm,
      [field]: {
        ...youtubeForm[field],
        value: newValue,
        valid: validator(type, newValue, required)
      }
    });
  };

  useEffect(() => {
    if (publisherSelected?.length > 0 && publisherSelected !== undefined) {
      getYtPlaylistPublisher(publisherSelected);
    }
    //handleOnChangeYoutubeForm('playlist_id', publisherSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherSelected]);

  useEffect(() => {
    setPublisherSelected(selectedPublisher.name);
  }, [selectedPublisher]);

  useEffect(() => {
    handleOnChangeYoutubeForm('publisher', publisherSelected);
    setMissingFields([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherSelected]);

  useEffect(() => {
    handleOnChangeYoutubeForm('keywords', keywordListForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordListForm, keywordList]);

  const checkSubmitYoutubeFormDisabled = () => {
    let checkValue = false;
    Object.keys(youtubeForm).forEach(key => {
      if (youtubeForm[key]?.value) checkValue = true;
    });

    return !(isValidForm(youtubeForm) && checkValue);
  };

  const showMissingFields = () => {
    const missing = Object.values(youtubeForm).filter(
      field => field.required && !field.valid
    );
    const missingFieldsLabels = missing.map(f => f.field);
    setMissingFields(missingFieldsLabels);
  };

  const privacyOptions = [
    { label: 'Private', value: 'private' },
    { label: 'Public', value: 'public' },
    { label: 'Unlisted', value: 'unlisted' }
  ];

  /* const adTypesOptions = [
    { label: 'In stream true view', value: 'instream_trueview' },
    { label: 'In stream standard', value: 'instream_standard' }
  ]; */

  const [endTimeOptions, setEndTimeOptions] = useState();
  useEffect(() => {
    setEndTimeOptions([
      {
        value: youtubeForm.start_time.value
          ? moment(youtubeForm.start_time.value)
              .add(31, 'day')
              .utcOffset(0, true)
              .format()
          : moment(new Date())
              .add(31, 'day')
              .utcOffset(0, true)
              .format(),
        label: 'now+31days'
      },
      {
        value: youtubeForm.start_time.value
          ? moment(youtubeForm.start_time.value)
              .add(365, 'day')
              .utcOffset(0, true)
              .format()
          : moment(new Date())
              .add(365, 'day')
              .utcOffset(0, true)
              .format(),
        label: 'now+365days'
      },
      { value: 'never', label: 'never' }
    ]);
  }, [youtubeForm]);

  const onChangeCheckboxStartTime = ({ checked }) => {
    if (checked) {
      setDisableStarttime(true);
      handleOnChangeYoutubeForm('start_time', moment(new Date()).format());
    } else {
      setDisableStarttime(false);
      handleOnChangeYoutubeForm('start_time', '');
    }
  };

  const onChangeNotifySubscriber = newValue => {
    handleOnChangeYoutubeForm('notify_subscribers', newValue ? 'yes' : 'no');
    setNotifySubscriber(newValue);
  };

  const onSubmitUpload = () => {
    const payload = Object.keys(youtubeForm).reduce(
      (acc, key) => ({
        [key]: youtubeForm[key]?.value?.value || youtubeForm[key]?.value,
        ...acc
      }),
      {}
    );

    let body = { ...payload, platform };

    if (platform === 'dailymotion') {
      body = {
        ...body,
        name: true,
        add_asset_labels: true,
        playlist_id: true,
        notify_subscribers: true,
        ad_types: true,
        ad_break_times: true
      };
    }
    uploadVideo(body);

    // Keywords Reset
    setKeywordList([]);
    setKeywordListForm('');
    setKeywordText('');

    setDisableStarttime(true);
    setNotifySubscriber(false);

    setYoutubeForm({
      ...youtubeForm,
      publisher: {
        value: selectedPublisher?.name,
        valid: true
      }
    });
    setMissingFields([]);
  };

  const handleOnChangeKeywords = value => {
    setKeywordText(value);
  };

  const handleOnKeyPressKeywords = e => {
    if (
      keywordText.length &&
      (e.key === 'Enter' || e.key === ',') &&
      !keywordList.includes(keywordText)
    ) {
      const newList = [...keywordList, keywordText];
      setKeywordList(newList);
      const list = newList.toString().replaceAll(',', '|');
      setKeywordListForm(list);
      setKeywordText('');
    }
  };

  const handleOnClickDeleteKeyword = kw => {
    const newList = keywordList.filter(el => el !== kw);
    setKeywordList(newList);
    const list = newList.toString().replaceAll(',', '|');
    setKeywordListForm(list);
  };

  const isMissingField = field => {
    return !youtubeForm?.[field]?.value && missingFields.includes(field);
  };

  return (
    <UploadVideoContainer>
      {/* <Roboto type="medium">Platform</Roboto> */}
      <PlatformContainer>
        <ButtonWrapper className="upload-video">
          {selectedPublisher?.youtube?.platform_enabled && (
            <ButtonContainer>
              <Button
                selected={platform === 'youtube'}
                height="70"
                btnType="platform"
                logo={Youtube}
                onClick={() => setPlatform('youtube')}
              />
            </ButtonContainer>
          )}
          {selectedPublisher?.youtube?.platform_enabled &&
            selectedPublisher?.dailymotion?.platform_enabled && (
              <ButtonContainer>
                <Button
                  selected={platform === 'youtube_dailymotion'}
                  height="70"
                  btnType="platform"
                  onClick={() => setPlatform('youtube_dailymotion')}
                  logo={AllPlatforms}
                />
              </ButtonContainer>
            )}
          {selectedPublisher?.dailymotion?.platform_enabled && (
            <ButtonContainer className="dm-upload">
              <Button
                selected={platform === 'dailymotion'}
                height="70"
                btnType="platform"
                logo={Dailymotion}
                onClick={() => setPlatform('dailymotion')}
              />
            </ButtonContainer>
          )}
        </ButtonWrapper>
        {/*<ButtonWrapper>*/}
        {/*  <ButtonContainer>*/}
        {/*    <Button selected={platform === 'dailymotion'} height="70" btnType="platform" logo={Youtube} onClick={() => setPlatform('dailymotion')} />*/}
        {/*  </ButtonContainer>*/}
        {/*</ButtonWrapper>*/}
      </PlatformContainer>
      {(selectedPublisher?.youtube?.platform_enabled ||
        selectedPublisher?.dailymotion?.platform_enabled) && (
        <FormContainer platform={platform}>
          <Container>
            <Row>
              <Col sm={12} md={12} lg={6} className="pl-0">
                <FormItem>
                  <FormInput
                    label={getLabelValue('input_file_label', labels)}
                    accept=".mp4, .avi"
                    value={youtubeForm?.video?.value}
                    required={youtubeForm?.video?.required}
                    onChange={newValue =>
                      handleOnChangeYoutubeForm('video', newValue)
                    }
                    type="upload-file"
                    labels={labels}
                    missingField={isMissingField('video')}
                  />
                </FormItem>
                {platform !== 'dailymotion' && (
                  <FormItem>
                    <FormInput
                      label={getLabelValue('input_filename_label', labels)}
                      required={youtubeForm?.name?.required}
                      value={youtubeForm?.name?.value}
                      onChange={newValue =>
                        handleOnChangeYoutubeForm('name', newValue)
                      }
                      missingField={isMissingField('name')}
                    />
                  </FormItem>
                )}
                {/* <FormItem>
              <FormInput label="Publisher" type="select" options={publishers} onChange={newValue => handleOnChangeYoutubeForm('publisher', newValue.value)} />
            </FormItem> */}
                <FormItem>
                  <FormInput
                    label={getLabelValue('input_title_label', labels)}
                    value={youtubeForm?.title?.value}
                    required={youtubeForm?.title?.required}
                    onChange={newValue =>
                      handleOnChangeYoutubeForm('title', newValue)
                    }
                    missingField={isMissingField('title')}
                  />
                </FormItem>
                <FormItem>
                  <FormInput
                    label={getLabelValue('select_privacy_label', labels)}
                    type="select"
                    selected={youtubeForm?.privacy?.value}
                    required={youtubeForm?.privacy?.required}
                    options={privacyOptions}
                    onChange={newValue =>
                      handleOnChangeYoutubeForm('privacy', newValue)
                    }
                    missingField={isMissingField('privacy')}
                  />
                </FormItem>
                {platform !== 'dailymotion' && (
                  <FormItem>
                    <FormInput
                      label={getLabelValue('input_asset_label', labels)}
                      value={youtubeForm?.add_asset_labels?.value}
                      required={youtubeForm?.add_asset_labels?.required}
                      onChange={newValue =>
                        handleOnChangeYoutubeForm('add_asset_labels', newValue)
                      }
                      missingField={isMissingField('add_asset_labels')}
                    />
                  </FormItem>
                )}
                <FormItem>
                  <FormInput
                    label={getLabelValue('input_description_label', labels)}
                    value={youtubeForm?.description?.value}
                    required={youtubeForm?.description?.required}
                    onChange={newValue =>
                      handleOnChangeYoutubeForm('description', newValue)
                    }
                    missingField={isMissingField('description')}
                  />
                </FormItem>
                <FormItem>
                  <FormInput
                    label={getLabelValue('input_keywords_label', labels)}
                    value={keywordText}
                    required={youtubeForm?.keywords?.required}
                    onChange={newValue => handleOnChangeKeywords(newValue)}
                    onKeyPress={e => handleOnKeyPressKeywords(e)}
                    missingField={isMissingField('keywords')}
                  />
                  {keywordList.length > 0 && (
                    <KeywordsContainer>
                      {keywordList.map((kw, i) => (
                        <KeywordLabel key={`keyword-${i}`}>
                          {kw}
                          <Icon
                            type="icon-close"
                            size={10}
                            color={theme.colors.custom.lightText}
                            onClick={() => handleOnClickDeleteKeyword(kw)}
                          />
                        </KeywordLabel>
                      ))}
                    </KeywordsContainer>
                  )}
                </FormItem>
              </Col>
              <Col sm={12} md={12} lg={6} className="pl-0">
                <FormItem>
                  <FormInput
                    label={getLabelValue('input_thumb_label', labels)}
                    accept=".jpg"
                    value={youtubeForm?.thumbnail?.value}
                    required={youtubeForm?.thumbnail?.required}
                    onChange={newValue =>
                      handleOnChangeYoutubeForm('thumbnail', newValue)
                    }
                    type="upload-file"
                    labels={labels}
                  />
                </FormItem>
                {platform !== 'dailymotion' && (
                  <>
                    <FormItem>
                      <FormInput
                        isClearable
                        label={getLabelValue(
                          'select_upload_add_playlist',
                          labels
                        )}
                        type="select"
                        selected={youtubeForm?.playlist_id?.value}
                        required={youtubeForm?.playlist_id?.required}
                        options={youtubePlaylist}
                        onChange={newValue =>
                          handleOnChangeYoutubeForm(
                            'playlist_id',
                            newValue || ''
                          )
                        }
                      />
                    </FormItem>
                    <FormItem>
                      <Label>
                        <Roboto>
                          {getLabelValue('toggle_notify_subscribers', labels)}
                        </Roboto>
                      </Label>
                      <Switch
                        onChange={onChangeNotifySubscriber}
                        checked={notifySubscriber}
                      />
                    </FormItem>
                  </>
                )}
                {!(
                  youtubeForm?.privacy?.value?.value === 'private' &&
                  platform === 'dailymotion'
                ) && (
                  <>
                    <FormItem>
                      <Label>
                        <Roboto>
                          {getLabelValue('input_start_time_label', labels)}
                        </Roboto>
                      </Label>
                      <Checkbox
                        onChange={newValue =>
                          onChangeCheckboxStartTime(newValue)
                        }
                        value="now"
                        label={getLabelValue('checkbox_now_label', labels)}
                        checked={disableStarttime}
                      />
                      <CalendarWrapper>
                        <Icon
                          type="icon-calendar"
                          size={25}
                          color={theme.colors.custom.blue}
                        />
                        <FormInput
                          type="date"
                          value={
                            youtubeForm?.start_time?.value
                              ? new Date(youtubeForm?.start_time?.value)
                              : new Date()
                          }
                          showTimeSelect
                          onChange={newValue =>
                            handleOnChangeYoutubeForm(
                              'start_time',
                              moment(newValue).format()
                            )
                          }
                          disabled={disableStarttime}
                          minDate={new Date()}
                        />
                      </CalendarWrapper>
                    </FormItem>
                    <FormItem>
                      <FormInput
                        label={getLabelValue('select_end_time_label', labels)}
                        selected={youtubeForm?.end_time?.value}
                        required={youtubeForm?.end_time?.required}
                        type="select"
                        options={endTimeOptions}
                        onChange={newValue =>
                          handleOnChangeYoutubeForm('end_time', newValue)
                        }
                        missingField={isMissingField('end_time')}
                      />
                    </FormItem>
                  </>
                )}
                {platform !== 'dailymotion' && (
                  <>
                    <FormItem>
                      <FormInput
                        label={getLabelValue('input_ad_types_label', labels)}
                        value={youtubeForm.ad_types.value}
                        required={youtubeForm?.ad_types?.required}
                        onChange={newValue =>
                          handleOnChangeYoutubeForm('ad_types', newValue)
                        }
                        missingField={isMissingField('ad_types')}
                      />
                    </FormItem>
                    <FormItem>
                      <FormInput
                        label={getLabelValue(
                          'input_ad_break_times_label',
                          labels
                        )}
                        value={youtubeForm.ad_break_times.value}
                        required={youtubeForm?.ad_break_times?.required}
                        onChange={newValue =>
                          handleOnChangeYoutubeForm('ad_break_times', newValue)
                        }
                        missingField={isMissingField('ad_break_times')}
                      />
                    </FormItem>
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <Button
            btnText={getLabelValue('btn_upload_video', labels)}
            width="220px"
            onClick={() => {
              checkSubmitYoutubeFormDisabled()
                ? showMissingFields()
                : onSubmitUpload();
            }}
          />
        </FormContainer>
      )}
      {!selectedPublisher?.youtube?.platform_enabled &&
        !selectedPublisher?.dailymotion?.platform_enabled && (
          <NoResultWrapper>
            <NoResultMessage>
              {getLabelValue('message_no_platform_enabled', labels)}
            </NoResultMessage>
          </NoResultWrapper>
        )}
    </UploadVideoContainer>
  );
};

export default connect(
  state => ({
    uploadForm: state.upload.uploadForm,
    selectedPublisher: state.app.selectedPublisher,
    labels: state.app.selectedLabel,
    ytPlaylist: state.upload.ytPlaylistPublisher
  }),
  dispatch => ({
    uploadVideo: payload => dispatch({ type: UPLOAD_VIDEO._REQUEST, payload }),
    getYtPlaylistPublisher: publisher =>
      dispatch({ type: GET_YT_PLAYLIST_PUBLISHER._REQUEST, publisher })
  })
)(withMediaQueries(UploadVideo));
