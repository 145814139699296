import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  LOADER_CLOSE,
  LOADER_OPEN,
} from '../actions';
import {
  GET_DEFAULT_TEMPLATE_FEED, GET_HTML_FEED_DM, GET_HTML_FEED_YT, UPDATE_PREVIEW,
} from '../actions/feed';

import feed from '../../api/feed';

function* getDefaultTemplateFeed({ publisher: name }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data: { result } = {} } = {}
    } = yield call(feed.getDefaultTemplateFeed, { name });

    yield put({ type: GET_DEFAULT_TEMPLATE_FEED._SUCCESS, result });
  } catch (err) {
    // eslint-disable-next-line no-console
    const message =
      err?.response?.data?.data === 'MAXIMUM_LOOKUP_RESULTS_ERROR'
        ? err?.response?.data?.data
        : 'Si è verificato un errore';
    yield put({
      type: GET_DEFAULT_TEMPLATE_FEED._ERROR,
      err: { message }
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getDefaultTemplateFeedWatch() {
  yield takeLatest(
    GET_DEFAULT_TEMPLATE_FEED._REQUEST,
    getDefaultTemplateFeed
  );
}

function* getHtmlFeedYT({ payload, updatePreview }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = {} } = {} } = yield call(feed.getHtmlFeedYT, payload);
    yield put({ type: GET_HTML_FEED_YT._SUCCESS, data });
    if (updatePreview) yield put({ type: UPDATE_PREVIEW });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > feed > getHtmlFeedYT', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getHtmlFeedYTWatch() {
  yield takeLatest(GET_HTML_FEED_YT._REQUEST, getHtmlFeedYT);
}

function* getHtmlFeedDM({ payload, updatePreview }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = {} } = {} } = yield call(feed.getHtmlFeedDM, payload);
    yield put({ type: GET_HTML_FEED_DM._SUCCESS, data });
    if (updatePreview) yield put({ type: UPDATE_PREVIEW });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > feed > getHtmlFeedDM', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getHtmlFeedDMWatch() {
  yield takeLatest(GET_HTML_FEED_DM._REQUEST, getHtmlFeedDM);
}

export default [
  getDefaultTemplateFeedWatch(),
  getHtmlFeedYTWatch(),
  getHtmlFeedDMWatch()
];
