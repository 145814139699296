import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Youtube from '../../ui/assets/img/youtube-logo.png';


import Roboto from '../../ui/typography/roboto';
import {
  Container, Name
} from './style';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import theme from '../../ui/theme';

const NoPublishers = ({ user }) => (
  <Container>
    <Roboto type="h1">Ciao <Name>{user.name} {user.surname}</Name>,</Roboto>
    <Roboto type="h1">ancora non hai publisher assegnati.</Roboto>
  </Container>
);


NoPublishers.propTypes = {
};

export default connect(
  state => {
    const user = state.auth.user_data;

    return { user };
  },
  dispatch => ({
  })
)(withMediaQueries(NoPublishers));
