import styled from 'styled-components';

export const Container = styled.div``;

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  height: 100vh;
  width: 100vw;
`;

export const AuthWrapper = styled.div`    
  margin: 0 auto;
  min-width: 287px;
  max-width: 287px;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 70px;
  text-align: center;
`;

export const FormInputWrapper = styled.div`
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
  > button {
    margin-top: 15px;
  }

  // example media q
  @media ${props => props.theme.device.mobileM} {
    max-width: 100%;
    margin: 0;
  }
`;
