import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Youtube from '../../ui/assets/img/youtube-logo.png';
import DailyMotion from '../../ui/assets/img/dailymotion-logo.svg';
import NoResultImagePlacheholder from '../../ui/assets/img/search-empty-placeholder.svg';
import {
  SearchVideoContainer,
  PlatformContainer,
  ButtonWrapper,
  ButtonContainer,
  SearchBar,
  ResultsContainer,
  NoResultWrapper,
  NoResultMessage,
  FilterContainer,
  NoResultImageWrapper,
  NoResultImage,
  CalendarWrapper,
  IconWrapper,
  LeftContainer
} from './style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import Button from '../../atoms/Button';
import { CustomInput, Icon } from '../../atoms';
import theme from '../../ui/theme';
import { CardVideo } from '../../components';
import {
  GET_DAILYMOTION_SEARCH_RESULTS,
  GET_YOUTUBE_SEARCH_RESULTS,
  RESET_DAILYMOTION_SEARCH_RESULTS,
  RESET_YOUTUBE_SEARCH_RESULTS
} from '../../redux/actions/search';
import FormInput from '../../atoms/FormInput';
import Row from '../../ui/gridSystem/Row';
import Col from '../../ui/gridSystem/Col';
import { getLabelValue } from '../../utils/common';
import routes from '../../routes';
import { push } from 'redux-first-history';

const ls = localStorage;

const videoDurationOptions = [
  { label: 'All', value: '' },
  { label: 'short (0-4 min)', value: 'short' },
  { label: 'medium (4-20 min)', value: 'medium' },
  { label: 'long (20+ min)', value: 'long' }
];

const maxResultOptions = [
  { label: '12', value: 12 },
  { label: '24', value: 24 },
  { label: '36', value: 36 },
  { label: '48', value: 48 },
  { label: '60', value: 60 }
];

const SearchVideo = ({
  getYoutubeSearchResults,
  ybListResults,
  selectedPublisher,
  mediaIsPhone,
  mediaIsTablet,
  labels,
  getDailymotionSearchResults,
  dmListResults,
  resetYoutubeSearchResults,
  resetDailymotionSearchResults,
  pushUrl
}) => {
  const [platform, setPlatform] = useState('');
  const [search, setSearchValue] = useState('');
  const [publishedAfter, setPublishedAfter] = useState(
    ls.getItem('publishedAfter') ? ls.getItem('publishedBefore') : null
  );

  const [publishedBefore, setPublishedBefore] = useState(
    ls.getItem('publishedBefore') ? ls.getItem('publishedBefore') : null
  );

  const [videoDurationValue, setVideoDuration] = useState(
    ls.getItem('videoDurationValue') &&
      ls.getItem('videoDurationValue') !== 'undefined'
      ? {
          label: ls.getItem('videoDurationValue'),
          value: ls.getItem('videoDurationValue')
        }
      : videoDurationOptions[0]
  );

  const [maxResultsValue, setMaxResults] = useState(
    ls.getItem('maxResultsValue')
      ? {
          label: ls.getItem('maxResultsValue'),
          value: ls.getItem('maxResultsValue')
        }
      : { label: '36', value: 36 }
  );

  const [youtubeResults, setYoutubeResults] = useState([]);
  const [dailymotionResults, setDailymotionResults] = useState([]);

  // FEED ENABLED
  const [feedEnabled, setFeedEnabled] = useState(false);

  useEffect(() => {
    
    const hasFeedEnabled = platform === 'youtube' 
      ? selectedPublisher?.youtube?.feed_enabled
      : selectedPublisher?.dailymotion?.feed_enabled
      
    setFeedEnabled(hasFeedEnabled);
  }, [platform, selectedPublisher])

  const getLatestVideos = (plat, publisher) => {
    let body = {};
    if (plat === 'youtube') {
      body = {
        search: '',
        publisher,
        maxResults: 36
      };
      getYoutubeSearchResults(body);
    } else {
      body = {
        search: '',
        publisher,
        maxResults: 36
      };
      getDailymotionSearchResults(body);
    }
  };

  useEffect(() => {
    ls.removeItem('publishedAfter');
    ls.removeItem('publishedBefore');
    ls.removeItem('videoDurationValue');
    ls.removeItem('maxResultsValue');
    resetDailymotionSearchResults();
    resetYoutubeSearchResults();

    setPublishedAfter(null);
    setPublishedBefore(null);

    getLatestVideos(platform, selectedPublisher.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher, platform]);

  useEffect(() => {
    if (
      selectedPublisher?.youtube?.platform_enabled &&
      selectedPublisher?.dailymotion?.platform_enabled
    ) {
      setPlatform(
        selectedPublisher?.defaultPlatform === 'youtube'
          ? 'youtube'
          : 'dailymotion'
      );
    } else {
      setPlatform(
        selectedPublisher?.youtube?.platform_enabled
          ? 'youtube'
          : selectedPublisher?.dailymotion?.platform_enabled
          ? 'dailymotion'
          : ''
      );
    }
  }, [selectedPublisher]);

  useEffect(() => {
    setYoutubeResults(ybListResults);
  }, [ybListResults]);

  useEffect(() => {
    setDailymotionResults(dmListResults);
  }, [dmListResults]);

  const handleOnChange = value => {
    setSearchValue(value);
  };

  const handleOnKeyPress = e => {
    const publishedAfterLastMonth = moment(new Date())
      .subtract(30, 'days')
      .format();
    const publishedBeforeLastMonth = moment(new Date()).format();
    const afterBeforeSetCheck = Boolean(
      ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
    );

    if (!afterBeforeSetCheck) {
      setPublishedAfter(publishedAfterLastMonth);
      setPublishedBefore(publishedBeforeLastMonth);
    }

    if (e.key === 'Enter') {
      const videoDuration = videoDurationValue?.value;
      const maxResults = maxResultsValue?.value;
      const createdAfter = Number(moment(publishedAfter).format('X'));
      const createdBefore = Number(moment(publishedBefore).format('X'));
      const publisher = selectedPublisher.name;

      const params = {
        yt: {
          search,
          publishedAfter:
            ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
              ? publishedAfter
              : publishedAfterLastMonth,
          publishedBefore:
            ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
              ? publishedBefore
              : publishedBeforeLastMonth,
          videoDuration,
          maxResults,
          publisher
        },
        dm: {
          search,
          createdAfter:
            ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
              ? createdAfter
              : Number(moment(publishedAfterLastMonth).format('X')),
          createdBefore:
            ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
              ? createdBefore
              : Number(moment(publishedBeforeLastMonth).format('X')),
          videoDuration,
          maxResults,
          publisher
        }
      };

      if (platform === 'youtube') {
        getYoutubeSearchResults(params.yt);
      } else {
        getDailymotionSearchResults(params.dm);
      }
    }
  };

  const handleOnClickIcon = () => {
    const publishedAfterLastMonth = moment(new Date())
      .subtract(30, 'days')
      .format();
    const publishedBeforeLastMonth = moment(new Date()).format();
    const afterBeforeSetCheck = Boolean(
      ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
    );

    if (!afterBeforeSetCheck) {
      setPublishedAfter(publishedAfterLastMonth);
      setPublishedBefore(publishedBeforeLastMonth);
    }

    const videoDuration = videoDurationValue?.value;
    const maxResults = maxResultsValue?.value;
    const createdAfter = Number(moment(publishedAfter).format('X'));
    const createdBefore = Number(moment(publishedBefore).format('X'));
    const publisher = selectedPublisher.name;

    const paramsYT = {
      search,
      publishedAfter:
        ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
          ? publishedAfter
          : publishedAfterLastMonth,
      publishedBefore:
        ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
          ? publishedBefore
          : publishedBeforeLastMonth,
      videoDuration,
      maxResults,
      publisher
    };

    const paramsDM = {
      search,
      createdAfter:
        ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
          ? createdAfter
          : Number(moment(publishedAfterLastMonth).format('X')),
      createdBefore:
        ls.getItem('publishedAfter') || ls.getItem('publishedBefore')
          ? createdBefore
          : Number(moment(publishedBeforeLastMonth).format('X')),
      videoDuration,
      maxResults,
      publisher
    };

    if (platform === 'youtube') {
      getYoutubeSearchResults(paramsYT);
    } else {
      getDailymotionSearchResults(paramsDM);
    }
  };

  const resetFilter = () => {
    setPublishedAfter(null);
    setPublishedBefore(null);
    setVideoDuration(videoDurationOptions[0]);
    setSearchValue('');
    setMaxResults({ label: '36', value: 36 });
    getLatestVideos(platform, selectedPublisher.name);
  };

  const handleClickPlatform = logo => {
    logo === Youtube ? setPlatform('youtube') : setPlatform('dailymotion');
  };

  const onChangePublishedAfter = val => {
    setPublishedAfter(moment(new Date(val)).format());
    ls.setItem('publishedAfter', moment(new Date(val)).format());
  };

  const onChangePublishedBefore = val => {
    setPublishedBefore(moment(new Date(val)).format());
    ls.setItem('publishedBefore', moment(new Date(val)).format());
  };

  const onChangeVideoDuration = val => {
    setVideoDuration(val);
    ls.setItem('videoDurationValue', val?.value);
  };

  const onChangeMaxResult = val => {
    setMaxResults(val);
    ls.setItem('maxResultsValue', val?.value);
  };

  const resetPublishedAfter = type => {
    if (type === 'after') {
      setPublishedAfter(null);
      ls.removeItem('publishedAfter');
    } else {
      setPublishedBefore(null);
      ls.removeItem('publishedBefore');
    }
  };

  return (
    <SearchVideoContainer>
      {/* <Roboto type="medium">Platform</Roboto> */}
      <PlatformContainer>
        <LeftContainer>
          <ButtonWrapper>
            {selectedPublisher?.youtube?.platform_enabled && (
              <ButtonContainer>
                <Button
                  selected={platform === 'youtube'}
                  height="70"
                  btnType="platform"
                  logo={Youtube}
                  onClick={() => handleClickPlatform(Youtube)}
                />
              </ButtonContainer>
            )}

            {/* DAILYMOTION BUTTON */}
            {selectedPublisher?.dailymotion?.platform_enabled && (
              <ButtonContainer className="dm">
                <Button
                  selected={platform === 'dailymotion'}
                  height="70"
                  btnType="platform"
                  logo={DailyMotion}
                  onClick={() => handleClickPlatform(DailyMotion)}
                />
              </ButtonContainer>
            )}
          </ButtonWrapper>
          {(selectedPublisher?.youtube?.platform_enabled ||
            selectedPublisher?.dailymotion?.platform_enabled) && (
            <Button
              btnText={getLabelValue('btn_create_feed', labels)}
              className="btn-create-feed"
              width="100%"
              height="22px"
              disabled={!feedEnabled}
              onClick={() => pushUrl(`${routes.createFeed.path}/${platform}`)}
            />
          )}
        </LeftContainer>
        {(selectedPublisher?.youtube?.platform_enabled ||
          selectedPublisher?.dailymotion?.platform_enabled) && (
          <SearchBar>
            <CustomInput
              placeholder={getLabelValue('search_input_placeholder', labels)}
              icon="icon-search"
              value={search}
              onChange={newValue => handleOnChange(newValue)}
              onKeyPress={e => handleOnKeyPress(e)}
              onClickIcon={handleOnClickIcon}
              disabled={platform === ''}
            />
          </SearchBar>
        )}
      </PlatformContainer>
      {(selectedPublisher?.youtube?.platform_enabled ||
        selectedPublisher?.dailymotion?.platform_enabled) && (
        <FilterContainer>
          <>
            <Row>
              <Col
                sm={12}
                md={6}
                lg={3}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'mt-2 mb-2'
                    : 'mt-2 mb-2 pl-0'
                }
              >
                <CalendarWrapper>
                  <Icon
                    type="icon-calendar"
                    size={25}
                    color={theme.colors.custom.blue}
                  />
                  {publishedAfter && (
                    <Icon
                      onClick={() => resetPublishedAfter('after')}
                      type="icon-close"
                      size={15}
                      color={theme.colors.primary.gray}
                    />
                  )}
                  <FormInput
                    label={getLabelValue(
                      'search_published_after_label',
                      labels
                    )}
                    type="date"
                    showTimeSelect
                    value={publishedAfter ? new Date(publishedAfter) : null}
                    onChange={newValue => onChangePublishedAfter(newValue)}
                  />
                </CalendarWrapper>
              </Col>
              <Col
                sm={12}
                md={6}
                lg={3}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'mt-2 mb-2'
                    : 'mt-2 mb-2 pl-0'
                }
              >
                <CalendarWrapper>
                  <Icon
                    type="icon-calendar"
                    size={25}
                    color={theme.colors.custom.blue}
                  />
                  {publishedBefore && (
                    <Icon
                      onClick={() => resetPublishedAfter('before')}
                      type="icon-close"
                      size={15}
                      color={theme.colors.primary.gray}
                    />
                  )}
                  <FormInput
                    label={getLabelValue(
                      'search_published_before_label',
                      labels
                    )}
                    type="date"
                    showTimeSelect
                    value={publishedBefore ? new Date(publishedBefore) : null}
                    onChange={newValue => onChangePublishedBefore(newValue)}
                  />
                </CalendarWrapper>
              </Col>
              <Col
                sm={12}
                md={4}
                lg={2}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'mt-2 mb-2'
                    : 'mt-2 mb-2 pl-0'
                }
              >
                <FormInput
                  label={getLabelValue('search_video_duration_label', labels)}
                  type="select"
                  selected={videoDurationValue}
                  options={videoDurationOptions}
                  onChange={newValue => onChangeVideoDuration(newValue)}
                />
              </Col>
              <Col
                sm={12}
                md={4}
                lg={1}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'mt-2 mb-2 max-results'
                    : 'mt-2 mb-2 max-results pl-0'
                }
              >
                <FormInput
                  label={getLabelValue('search_max_results_label', labels)}
                  type="select"
                  selected={maxResultsValue}
                  options={maxResultOptions}
                  onChange={newValue => onChangeMaxResult(newValue)}
                />
              </Col>
              <Col
                sm={6}
                md={1}
                lg={1}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'switch mt-auto mb-2 ml-auto'
                    : 'switch mt-3 mb-2 ml-auto ml-1'
                }
              >
                <IconWrapper>
                  <Icon
                    type="icon-switch"
                    size={25}
                    color={theme.colors.custom.lightText}
                    disabled={
                      !(
                        publishedAfter ||
                        publishedBefore ||
                        videoDurationValue ||
                        maxResultsValue
                      )
                    }
                    onClick={() => resetFilter()}
                  />
                </IconWrapper>
              </Col>
              <Col
                sm={6}
                md={3}
                lg={2}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'buttonSearch mt-auto mb-2 '
                    : 'buttonSearch mt-3 mb-2 pl-0'
                }
              >
                <Button
                  btnText={getLabelValue('btn_search', labels)}
                  width="70px"
                  height="22px"
                  disabled={
                    !(
                      publishedAfter ||
                      publishedBefore ||
                      videoDurationValue ||
                      maxResultsValue
                    )
                  }
                  onClick={handleOnClickIcon}
                />
              </Col>
              {/* <Col
                sm={6}
                md={3}
                lg={2}
                className={
                  !mediaIsPhone || !mediaIsTablet
                    ? 'buttonSearch mt-auto mb-2 '
                    : 'buttonSearch mt-3 mb-2 pl-0'
                }
              >
                <Button
                  btnText={getLabelValue('btn_create_feed', labels)}
                  className="btn-create-feed"
                  width="fit-content"
                  height="22px"
                  disabled={!feedEnabled}
                  onClick={handleOnClickIcon}
                />
              </Col> */}
            </Row>
          </>
        </FilterContainer>
      )}
      {(selectedPublisher?.youtube?.platform_enabled ||
        selectedPublisher?.dailymotion?.platform_enabled) && (
        <ResultsContainer color={platform}>
          {platform === 'youtube' ? (
            <>
              {youtubeResults?.length > 0 ? (
                <>
                  {youtubeResults?.map((vid, i) => (
                    <CardVideo
                      key={`video-${i}`}
                      platform="yt"
                      idVideo={vid?.videoId}
                      videoName={vid?.title}
                      videoDate={vid?.publishedAt}
                    />
                  ))}
                </>
              ) : (
                <NoResultWrapper>
                  <NoResultImageWrapper>
                    <NoResultImage src={NoResultImagePlacheholder} />
                  </NoResultImageWrapper>
                  <NoResultMessage>
                    {getLabelValue('search_something', labels)}
                  </NoResultMessage>
                </NoResultWrapper>
              )}
            </>
          ) : (
            <>
              {dmListResults?.length > 0 ? (
                <>
                  {dmListResults?.map(vid => (
                    <CardVideo
                      key={vid?.videoId}
                      platform="dm"
                      idVideo={vid?.videoId}
                      videoName={vid?.title}
                      videoDate={vid?.createdAt}
                    />
                  ))}
                </>
              ) : (
                <NoResultWrapper>
                  <NoResultImageWrapper>
                    <NoResultImage src={NoResultImagePlacheholder} />
                  </NoResultImageWrapper>
                  <NoResultMessage>
                    {getLabelValue('search_something', labels)}
                  </NoResultMessage>
                </NoResultWrapper>
              )}
            </>
          )}
        </ResultsContainer>
      )}
      {!selectedPublisher?.youtube?.platform_enabled &&
        !selectedPublisher?.dailymotion?.platform_enabled && (
          <NoResultWrapper>
            <NoResultMessage>
              {getLabelValue('message_no_platform_enabled', labels)}
            </NoResultMessage>
          </NoResultWrapper>
        )}
    </SearchVideoContainer>
  );
};

SearchVideo.propTypes = {};

export default connect(
  state => ({
    ybListResults: state.search.youtubeSearchResults,
    dmListResults: state.search.dailymotionSearchResults,
    selectedPublisher: state.app.selectedPublisher,
    labels: state.app.selectedLabel
  }),
  dispatch => ({
    getYoutubeSearchResults: params =>
      dispatch({ type: GET_YOUTUBE_SEARCH_RESULTS._REQUEST, params }),
    resetYoutubeSearchResults: () =>
      dispatch({ type: RESET_YOUTUBE_SEARCH_RESULTS }),
    getDailymotionSearchResults: params =>
      dispatch({ type: GET_DAILYMOTION_SEARCH_RESULTS._REQUEST, params }),
    resetDailymotionSearchResults: () =>
      dispatch({ type: RESET_DAILYMOTION_SEARCH_RESULTS }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(SearchVideo));
