import styled from 'styled-components';
import theme from '../../../ui/theme';

const FormContainer = styled.div`
  margin-top: 20px;
  .react-switch-bg {
    margin-bottom: 20px !important;
  }
`;

const Label = styled.span`
  margin-right: 3px;

  &.dm {
    > div {
      margin-bottom: 6px;
    }
  }
`;

const Value = styled.span``;

const SelectWrapper = styled.div`
  display: flex;
  margin: 10px 0;

  > div:first-child {
    margin-right: 13px;
  }
`;

const AddPublisherWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    flex-direction: column;
  }
`;

const BtnSave = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: #f2f2f2;
  background-color: ${theme.colors.custom.blue};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const PublishersList = styled.div``;

export const PublisherRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  @media ${theme.device.mobileL} {
    margin-top: 16px;
  }
`;

export const NamePublisher = styled.div`
  width: 34%;
`;
export const RolePublisher = styled.div`
  width: 33%;
  color: ${theme.colors.custom.blue};
  display: flex;
  justify-content: center;
`;

export const EditRoleWrapper = styled.div`
  margin-top: 32px;
`;

export const EditRoleTitle = styled.span`
  font-style: italic;
  color: ${theme.colors.custom.blue};
`;

export const ActionPublisher = styled.div`
  width: 33%;
  display: flex;
  justify-content: space-between;

  @media ${theme.device.mobileL} {
    justify-content: flex-end;
  }
`;

export const BtnCancel = styled.div`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.custom.darkGray};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const BtnDelete = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 50px;
  color: ${theme.colors.custom.lightText};
  background-color: ${theme.colors.primary.red};
  cursor: pointer;
  width: 90px;
  display: flex;
  justify-content: center;
`;

export const ChannelLogoYT = styled.img``;

export const ChannelLogoDM = styled.img``;
export const DeletePublisherAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const TitleColumn = styled.div`
  height: 57px;
  font-size: 18px;
  font-weight: 500;
`;

export const PlatformLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 32px;
`;

export const EnablePlatformWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  .platform-enable {
    &--label {
      margin-bottom: 0;
      margin-right: 10px;
      color: ${theme.colors.custom.blue};
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    &--switch {
      height: 28px;

      > .react-switch-bg {
        background: ${props =>
          props.checked
            ? ` 
          ${theme.colors.custom.blue}!important`
            : 'rgb(136, 136, 136)!important'};
      }
    }
  }
`;

export const ErrorMsg = styled.div`
  color: ${theme.colors.primary.red};
  position: absolute;
  top: 30px;
  width: 100%;
`;

export const ColorOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ColorTitle = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SketchPickerWrapper = styled.div`
  z-index: 1;
`;

export const ChosenColor = styled.div`
  display: flex;

  &::after {
    content: '';
    display: inline-block;
    background-color: ${props => props.playerColor};
    border: 1px solid #b3b3b3;
    height: 21px;
    width: 20px;
    margin-left: 10px;
    margin-top: -3px;
    border-radius: 50%;
  }
`;

export const CloseColorPicker = styled.div`
  cursor: pointer;
  background: #fff;
  padding: 3px 5px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  margin-left: 12px;
`;

export const HeightWidthContainer = styled.div`
  margin-bottom: 25px;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: calc(50% - 10px);
    margin-bottom: 25px;
  }
`;

export {
  FormContainer,
  Label,
  Value,
  SelectWrapper,
  AddPublisherWrapper,
  BtnSave
};
