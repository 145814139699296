import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';
import theme from '../../../ui/theme';
import { ModalGlobalContainer, ModalWrapper } from '../Modal.style';

import Gotham from '../../../ui/typography/roboto';
import Arial from '../../../ui/typography/arial';
import Icon from '../../../atoms/Icon';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Head,
  IconClose,
  SubHead
} from './style';

import { MODAL_CLOSE } from '../../../redux/actions';
import { AUTH_LOGOUT } from '../../../redux/actions/auth';

const modalId = 'ExpiredSessionModal';

const ExpiredSessionModal = ({ logout, onClose, language }) => {
  const CloseAndChangeLocation = () => {
    logout();
    onClose();
  };

  return (
    <Modal id={modalId} onClose={CloseAndChangeLocation}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <IconClose
                className="icon-close"
                onClick={CloseAndChangeLocation}
              />
              <Icon
                type="icon-errore"
                size={60}
                color={theme.colors.primary.red}
              />
              <Title>
                <Gotham type="modalTitle">
                  {language === 'en' ? 'Error' : 'Errore'}
                </Gotham>
              </Title>
              <Subtitle>
                <Arial type="modalSubtitle">
                  <Head>
                    {language === 'en'
                      ? 'Attention, session expired!'
                      : 'Attenzione, sessione scaduta!'}
                  </Head>
                  <SubHead>
                    {language === 'en'
                      ? 'Please, authenticate again.'
                      : "Per favore, ripetere l'autenticazione."}
                  </SubHead>
                </Arial>
              </Subtitle>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ExpiredSessionModal.propTypes = {
  // HOC (connect, state)
  language: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

ExpiredSessionModal.defaultProps = {
  language: 'it'
};

ExpiredSessionModal.id = modalId;

export default connect(
  state => ({
    language: state.app?.selectedLanguage
  }),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    logout: () => dispatch({ type: AUTH_LOGOUT._REQUEST })
  })
)(ExpiredSessionModal);

/* TODO: aggiungere nelle sagas dove necessario
  
    const fakeError = undefined.token;

    if (err?.message?.includes('token'))
      yield put({
        type: MODAL_OPEN,
        id: ExpiredSessionModal.id
      });


*/
