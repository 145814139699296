const colors = {
  primary: {
    white: '#ffffff',
    black: '#000000',
    gray: '#C4C4C4',
    lightgray: '#EBEBEB',
    red: '#EB5757',
    green: '#6FCF97'
  },
  custom: {
    darkText: '#585858',
    lightText: '#F2F2F2',
    background: '#ffffff',
    blue: '#4F9EFF',
    youtube: '#FF0000',
    dailymotion: '#14C4F8',
    overlay: 'rgba(255, 255, 255, 0.5)',
    transparent: 'transparent',
    middleGray: '#dadada',
    darkGray: '#828282',
    disabled: '#E5E5E5'
  }
};

export default colors;
