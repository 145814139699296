import './ui/scss/app.scss';
import './ui/scss/bootstrap/bootstrap-grid.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import theme from './ui/theme';
import { history } from './redux/store';

import { GlobalLayout, Loader, Toast } from './components';
import routes from './routes';

/* Pages */
import {
  Auth,
  Sample,
  SearchVideo,
  UploadVideo,
  Admin,
  NoPublishers,
  VideoSettings,
  CreateFeed
} from './pages';
import ModalsPortal from './components/Modals/ModalsPortal';
import ErrorModal from './components/Modals/ErrorModal';
import {
  GET_AVAILABLE_PLATFORM,
  GET_LABELS,
  GET_PUBLISHERS,
  MODAL_OPEN
} from './redux/actions';
import { Version } from './atoms';
import ExpiredSessionModal from './components/Modals/ExpiredSessionModal';

const App = ({
  getPublishers,
  isLogged,
  userType,
  role,
  publishersList,
  getAvailablePlatform,
  getLabels,
  isSpinning,
  isInactive,
  openExpiredSessionModal
}) => {
  useEffect(() => {
    if (userType !== undefined) {
      getPublishers();
      getAvailablePlatform();
    }
  }, [getPublishers, userType, getAvailablePlatform]);

  useEffect(() => {
    getLabels();
  }, [getLabels]);

  useEffect(() => {
    if (isInactive && isLogged) openExpiredSessionModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInactive, isLogged]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Loader />
        {isLogged ? (
          <GlobalLayout>
            {publishersList?.length > 0 || userType !== 'member' ? (
              <Switch>
                <>
                  <Route
                    path={`${routes.videoSettings.path}/:platform/:videoId`}
                    exact
                    render={({
                      match: {
                        params: { videoId, platform }
                      }
                    }) => (
                      <VideoSettings videoId={videoId} platform={platform} />
                    )}
                  />
                  <Route
                    path={`${routes.createFeed.path}/:platform`}
                    exact
                    render={({
                      match: {
                        params: { platform }
                      }
                    }) => (
                      <CreateFeed platform={platform} />
                    )}
                  />
                  <Route path={routes.sample.path} render={() => <Sample />} />
                  <Route
                    path={routes.searchVideo.path}
                    render={() => <SearchVideo />}
                  />
                  {((userType === 'member' && role === 'creator') ||
                    userType === 'super_admin' ||
                    userType === 'admin') && (
                    <Route
                      path={routes.uploadVideo.path}
                      render={() => <UploadVideo />}
                    />
                  )}
                  {(userType === 'super_admin' || userType === 'admin') && (
                    <Route path={routes.admin.path} render={() => <Admin />} />
                  )}
                  <Redirect
                    to={
                      localStorage.getItem('location') &&
                      localStorage.getItem('location') !==
                        routes.noPublishersFound.path
                        ? localStorage.getItem('location')
                        : routes.searchVideo.path
                    }
                  />
                </>
              </Switch>
            ) : (
              <>
                <Switch>
                  <Route
                    path={routes.noPublishersFound.path}
                    render={() => <NoPublishers />}
                  />
                  <Redirect to={routes.noPublishersFound.path} />
                </Switch>
              </>
            )}
          </GlobalLayout>
        ) : (
          <Switch>
            <Route path={routes.auth.path} render={() => <Auth />} />
            <Redirect to={routes.auth.path} />
          </Switch>
        )}
      </Router>
      <Toast />
      <ModalsPortal.Target />
      <ErrorModal />
      <ExpiredSessionModal />
      <Version />
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, state)
  isLogged: PropTypes.bool.isRequired,
  isInactive: PropTypes.bool,
  getPublishers: PropTypes.func,
  openExpiredSessionModal: PropTypes.func
};

export default connect(
  state => {
    const {
      isLogged,
      user_data: { type: userType }
    } = state.auth;
    const {
      selectedPublisher: { role = '' } = {},
      publishersList,
      loader: { isSpinning }
    } = state.app;

    return {
      isLogged,
      userType,
      role,
      publishersList,
      isSpinning
    };
  },
  dispatch => ({
    getLabels: () => dispatch({ type: GET_LABELS._REQUEST }),
    getPublishers: () => dispatch({ type: GET_PUBLISHERS._REQUEST }),
    getAvailablePlatform: () =>
      dispatch({ type: GET_AVAILABLE_PLATFORM._REQUEST }),
    openExpiredSessionModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'ExpiredSessionModal' })
  })
)(App);
