/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row, Col } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';

import {
  ButtonWrapper,
  ModalBody,
  ModalGlobalContainer,
  ModalTitle,
  ModalWrapper
} from '../Modal.style';

import { EditRoleWrapper, FormContainer } from './style';
import { MODAL_CLOSE } from '../../../redux/actions';
import { Button, CustomSelect, FormInput, Icon } from '../../../atoms';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import theme from '../../../ui/theme';
import Roboto from '../../../ui/typography/roboto';
import {
  customStylesSelectLangModal,
  customStylesSelectPrimary,
  defaultUserTypeSelect,
  getLabelValue
} from '../../../utils/common';
import { DefaultLangWrapper, RowRoleLang } from '../AddUserModal/style';
import flagit from '../../../ui/assets/img/flag_italy.svg';
import flagen from '../../../ui/assets/img/flag_uk.svg';
import storage from '../../../utils/storage';
import { USER_EDIT } from '../../../redux/actions/admin';

const modalId = 'EditUserModal';

const flags = {
  it: { label: 'Italian', image: flagit },
  en: { label: 'English', image: flagen }
};
const selectedLabelStorage = 'selectedLabel';

const renderFlag = language => (
  <div style={{ width: '20px', height: '20px' }}>
    <img
      style={{ width: '100%', height: 'auto' }}
      src={flags[language].image}
      alt=""
    />
  </div>
);

const EditUserModal = ({
  user,
  editUser,
  onClose,
  userLoggedRole,
  labels,
  labelsList
}) => {
  const initialForm = newForm([
    newFormField({ field: 'surname', required: true, type: RegexpType.STRING }),
    newFormField({ field: 'name', required: true, type: RegexpType.STRING }),
    newFormField({
      field: 'password',
      required: false,
      type: RegexpType.PASSWORD
    }),
    newFormField({
      field: 'repeat_password',
      required: false,
      type: RegexpType.PASSWORD
    }),
    newFormField({ field: 'email', required: false, type: RegexpType.EMAIL })
  ]);

  const [newUserForm, setNewUserForm] = useState(initialForm);
  const [isTouched, setIsTouched] = useState(false);
  const [typeSelected, setTypeSelected] = useState('');

  const [listLabels, setListLabels] = useState([]);
  const [langSelected, setLangSelected] = useState();

  useEffect(() => {
    setTypeSelected({ label: user.type, value: user.type });
    setLangSelected(
      [...listLabels].find(item => item.value === user.defaultLanguage)
    );
  }, [user, listLabels]);

  useEffect(() => {
    setNewUserForm(
      newForm([
        newFormField({
          field: 'surname',
          required: true,
          type: RegexpType.STRING,
          value: user.surname,
          valid: validator(RegexpType.STRING, user.surname, true)
        }),
        newFormField({
          field: 'name',
          required: true,
          type: RegexpType.STRING,
          value: user.name,
          valid: validator(RegexpType.STRING, user.name, true)
        }),
        newFormField({
          field: 'password',
          required: false,
          type: RegexpType.PASSWORD
        }),
        newFormField({
          field: 'repeat_password',
          required: false,
          type: RegexpType.PASSWORD
        }),
        newFormField({
          field: 'email',
          required: false,
          type: RegexpType.EMAIL,
          value: user.mail,
          valid: validator(RegexpType.EMAIL, user.mail, false)
        })
      ])
    );

    // setDefaultLanguage(user.default);
  }, [user]);

  useEffect(() => {
    const listLab = Object.keys(labelsList).reduce(
      (acc, item) => [...acc, { label: renderFlag(item), value: item }],
      []
    );
    const selectedFlag = storage.read(selectedLabelStorage)?.value || 'en';
    const selected = listLab.find(item => item.value === selectedFlag);
    setLangSelected(selected || '');
    setListLabels(listLab);
  }, [labelsList]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = newUserForm[field];

    setNewUserForm({
      ...newUserForm,
      [field]: {
        ...newUserForm[field],
        value: newValue,
        valid:
          validator(type, newValue, required) &&
          (field === 'repeat_password'
            ? newUserForm.password.value === newValue
            : true)
      }
    });
    setIsTouched(true);
  };

  const checkSubmitDisabled = () => {
    let checkValue = false;
    Object.keys(newUserForm).forEach(key => {
      if (key === 'email') {
        if (newUserForm[key]?.value) checkValue = true;
        if (checkValue) return checkValue;
      }
    });

    return !(isValidForm(newUserForm) && checkValue) || !isTouched;
  };

  const handleOnChangeType = newRole => {
    setTypeSelected(newRole);
    setIsTouched(true);
  };

  const handleOnClose = () => {
    setNewUserForm(initialForm);
    setTypeSelected('');
    onClose();
  };

  const handleEditUser = () => {
    const name = newUserForm.name.value;
    const surname = newUserForm.surname.value;
    const mail = newUserForm.email.value;
    const password = newUserForm.password.value;
    let update_params = {};
    if (password) {
      update_params = {
        name,
        surname,
        mail,
        defaultLanguage: langSelected.value,
        password,
        accountType: typeSelected.value
      };
    } else {
      update_params = {
        name,
        surname,
        mail,
        defaultLanguage: langSelected.value,
        accountType: typeSelected.value
      };
    }

    const body = {
      username: user.username,
      update_params
    };

    editUser(body);
    handleOnClose();
  };

  const handleOnchangeLang = value => {
    const selectedLang = listLabels.filter(el => el.value === value.value)[0];
    setLangSelected(selectedLang);
    if (value.value !== user.defaultLanguage) {
      setIsTouched(true);
    } else {
      setIsTouched(false);
    }
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            height="90%"
          >
            <ModalTitle>
              <Roboto htmlAttribute="span" type="modalTitle">
                {getLabelValue('modal_edit_user', labels)}
              </Roboto>
              <Icon
                type="icon-close"
                color={theme.colors.custom.darkText}
                size={20}
                onClick={handleOnClose}
              />
            </ModalTitle>

            <ModalBody>
              <FormContainer>
                <Container>
                  <Row>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_name_label', labels)}
                        onChange={newValue =>
                          handleOnChange(newUserForm.name.field, newValue)
                        }
                        value={newUserForm.name.value}
                        valid={newUserForm.name.valid}
                        type="modal-input"
                        placeholder={user?.name}
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_surname_label', labels)}
                        onChange={newValue =>
                          handleOnChange(newUserForm.surname.field, newValue)
                        }
                        value={newUserForm.surname.value}
                        valid={newUserForm.surname.valid}
                        type="modal-input"
                        placeholder={user?.surname}
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="pl-0">
                      <FormInput
                        label={getLabelValue('input_email_label', labels)}
                        onChange={newValue =>
                          handleOnChange(newUserForm.email.field, newValue)
                        }
                        value={newUserForm.email.value}
                        valid={newUserForm.email.valid}
                        type="modal-input"
                        placeholder={user?.mail}
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="pl-0 pb-0">
                      <FormInput
                        label={getLabelValue('input_password_label', labels)}
                        onChange={newValue =>
                          handleOnChange(newUserForm.password.field, newValue)
                        }
                        value={newUserForm.password.value}
                        valid={newUserForm.password.valid}
                        type="form-password"
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="pl-0 pb-0">
                      <FormInput
                        label={getLabelValue(
                          'input_repeat_password_label',
                          labels
                        )}
                        onChange={newValue =>
                          handleOnChange(
                            newUserForm.repeat_password.field,
                            newValue
                          )
                        }
                        value={newUserForm.repeat_password.value}
                        valid={newUserForm.repeat_password.valid}
                        type="form-password"
                      />
                    </Col>
                    {user.type !== 'super_admin' &&
                      userLoggedRole === 'super_admin' && (
                        <Col sm={12} md={12} lg={12} className="pl-0 mt-0">
                          <RowRoleLang>
                            <EditRoleWrapper>
                              <Roboto>
                                {getLabelValue('info_user_type', labels)}
                              </Roboto>
                              <CustomSelect
                                styles={customStylesSelectPrimary}
                                options={defaultUserTypeSelect}
                                value={typeSelected}
                                onChange={handleOnChangeType}
                              />
                            </EditRoleWrapper>
                            <DefaultLangWrapper>
                              <Roboto>
                                {getLabelValue(
                                  'select_user_language_label',
                                  labels
                                )}
                              </Roboto>
                              <CustomSelect
                                styles={customStylesSelectLangModal}
                                options={listLabels}
                                value={langSelected}
                                onChange={handleOnchangeLang}
                              />
                            </DefaultLangWrapper>
                          </RowRoleLang>
                        </Col>
                      )}
                  </Row>
                </Container>
              </FormContainer>
              <ButtonWrapper>
                <Button
                  btnText={getLabelValue('btn_save', labels)}
                  width="30%"
                  btnType={Button.TYPE.PRIMARY}
                  disabled={checkSubmitDisabled()}
                  onClick={handleEditUser}
                />
              </ButtonWrapper>
            </ModalBody>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

EditUserModal.propTypes = {
  // HOC (connect, dispatch)
  editUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

EditUserModal.id = modalId;

export default connect(
  state => ({
    userList: state.admin.userList,
    userLoggedRole: state.auth.user_data.type,
    user: state.modal.payload,
    labels: state.app.selectedLabel,
    labelsList: state.app.labels
  }),
  dispatch => ({
    editUser: body => dispatch({ type: USER_EDIT._REQUEST, body }),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(EditUserModal);
