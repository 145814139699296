import styled from 'styled-components';

export const Detail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

export const Label = styled.span`
  width: 100%;
  margin-bottom: 5px;
`;
export const Value = styled.span`
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
`;
