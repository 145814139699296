import { createAsyncActionType } from './index';

export const GET_DEFAULT_TEMPLATE_FEED = createAsyncActionType(
  'feed',
  'GET_DEFAULT_TEMPLATE_FEED'
);
export const GET_HTML_FEED_YT = createAsyncActionType(
  'feed',
  'GET_HTML_FEED_YT'
);
export const GET_HTML_FEED_DM = createAsyncActionType(
  'feed',
  'GET_HTML_FEED_DM'
);
export const CLEAR_HTML_FEEDS = '@@feed/CLEAR_HTML_FEEDS';
export const UPDATE_PREVIEW = '@@feed/UPDATE_PREVIEW';