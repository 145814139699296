import styled from 'styled-components';
import theme from '../../../ui/theme';

export const NameData = styled.span`
  font-style: italic;
  color: ${theme.colors.custom.blue};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
